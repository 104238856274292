import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Col } from 'react-bootstrap';

import { submitCollection } from '../../_services/submit.service';
import { submitSets } from '../UiComponents/SubmitSets';
import { alertService } from '../../_services/alert.service';
//import { cversion } from '../../_services/common.service';

import { logoutAction } from '../../actions/auth/login_action';

//import i18n from "i18next";
import { withTranslation } from "react-i18next";
import "../../_translations/i18n";

import NccpLogo from "../../assets/img/nccp-logo-white.png";
import loadingImg from "../../assets/img/loading.gif";
import './signin.css';

class SignInComponent extends React.Component{
    _isMounted = false;

    constructor(props){
        super(props);
        this.state = {
            uname:"", pass:"",
            lobj:{}, lval:{},
            isLoading:false
        }

        this.signinBtnElement = React.createRef();
    }
      
    componentDidMount(){
        this._isMounted = true;
        document.body.classList.add("s-page");
        if(this._isMounted){
            //this.props.handleSignObj(null);
            this.props.setResetState("logout");
        }
    }

    componentWillUnmount(){
        this._isMounted = false;
        document.body.classList.remove("s-page");
    }

    handleValChange = (event,type) =>{
        if(type==="uname"){
            this.setState({uname:event.target.value});
        }
        else if(type==="pass"){
            this.setState({pass:event.target.value});
        }
    }

    handleSignin = () => {
        if(this.state.uname === "" || this.state.pass===""){
            alertService.error("Enter both Username & Password!");
        }
        else{
            this.setState({isLoading:true});
            let sobj = {userName:this.state.uname, password: this.state.pass};
            submitSets(submitCollection.signin,sobj).then(res=> {
            this.setState({isLoading:false});
            if(res.status===true){
                this.props.handleSignObj({signinDetails:true, username:res.extra.userName, userId:res.extra.userId, token:res.extra.jwtToken});
            }
            else{
                alertService.error(res.extra===""?"Error Occured!":res.extra);
            }
        });
        }
    }

    render(){
        //const isRTL = (i18n.dir((this.props.langobj?this.props.langobj.languageDetails.code:"en")) === 'rtl');
        
        return (<Col className="signin-container">
            <img src={NccpLogo} className="main-logo" alt="nccp logo" />
            <Col className="wrapper fadeInDown">
                <Col id="formContent">
                    <Col className="header fadeIn first">
                        <h5> Signin to continue</h5>
                        {/* <h6>Fact Sheets Generator</h6> */}
                    </Col>

                    <Col>
                        <input type="text" id="uname" value={this.state.uname} onChange={(e)=>this.handleValChange(e,"uname" )} className="fadeIn second" name="login" placeholder="Username"/>
                        <input type="password" id="password" value={this.state.pass} onChange={(e)=>this.handleValChange(e,"pass" )} onKeyDown={e => e.which === 13?this.handleSignin():null} className="fadeIn third" name="login" placeholder="Password"/>
                        
                        
                        <input type="submit" className="fadeIn fourth" onClick={ ()=>this.handleSignin() } value="Log In"/>
                           
                        <Col className={this.state.isLoading===true?"":"d-none"} xs={12} style={{paddingBottom:"15px"}}><img alt="loading" src={loadingImg}  /></Col>

                       
                    </Col>


                </Col>
            </Col>
        </Col>);
    }
}

const mapDispatchToProps = dispatch => ({
    setResetState: (payload) => dispatch(logoutAction(payload)),
});

export default withTranslation()(withRouter(connect(null,mapDispatchToProps)(SignInComponent)));