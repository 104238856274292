import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Col, Dropdown, Navbar } from 'react-bootstrap';
import { GearIcon, SignOutIcon } from '@primer/octicons-react';

import Alerts from './Alerts';
import { logoutAction } from '../../actions/auth/login_action';

class NavbarTop extends React.Component {
    _isMounted = false;
    constructor(props){
        super(props);
        this.state = {

        };
    }

    componentDidMount(){
      this._isMounted = true;

      if(this._isMounted){
        if(!this.props.signedobj || Object.keys(this.props.signedobj).length === 0 || !this.props.signedobj.signinDetails || Object.keys(this.props.signedobj.signinDetails).length === 0){
          this.props.history.push("/");
        } else{
          this.props.history.push("/factsheets");
        }
      }
    }

    handleRedirect = (cpath) => {
      this.props.history.push(cpath);
    }

    componentWillUnmount(){
      this._isMounted = false;
    }

    handleLogout = () => {
      //this.props.setLogoutState("logout");
      this.props.handleSignObj({signinDetails:false,username:"", userId:0, token:""});
      this.props.history.push("/");
    }

    render() {
      //var cuserdetails = (this.props.signedobj!==null&&this.props.signedobj.signinDetails?this.props.signedobj.signinDetails:null);
      return (
        <><Alerts/>
        {this.props.signedobj!==null&&this.props.signedobj.signinDetails.signinDetails===true?
          <Col className="navbar-main">
            <Navbar bg={this.props.dmode?"dark":"light"} variant={this.props.dmode?"dark":"light"}>
              <Col xs={2} style={{textAlign:"left"}}>
                <Navbar.Brand><span className="d-none d-sm-block"></span></Navbar.Brand>{/* */}
              </Col>
              <Col xs={10} style={{textAlign:"left"}}>
                
                <ul className="list-inline" style={{position: "absolute", top: "0px", right: "0px"}}>
                  <li className="list-inline-item" style={{padding:"0px"}}>Welcome! <b>{this.props.signedobj.signinDetails.username}</b></li>
                  <li className="list-inline-item usernav-link" style={{padding:"0px"}}>
                    <Dropdown>
                      <Dropdown.Toggle variant="" style={{padding:"18px 14px",marginTop:"-3px"}}>
                        <GearIcon size={16} />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item onClick={e => this.handleRedirect("/resetPassword")}>Change Password</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                  <li className="list-inline-item usernav-link" style={{padding:"18px 13px"}} onClick={this.handleLogout}><SignOutIcon size={16} /></li>
                </ul>
              </Col>
            </Navbar>   
          </Col>
        :<></>}</>
      );
    }
}

const mapDispatchToProps = dispatch => ({
  setLogoutState: (payload) => dispatch(logoutAction(payload)),
});

export default withRouter(connect(null,mapDispatchToProps)(NavbarTop));