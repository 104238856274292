import React from 'react';
import {Row,  Col , Button, Table, Modal} from 'react-bootstrap';

import { submitCollection } from '../../_services/submit.service';
import { submitSets } from '../UiComponents/SubmitSets';
import { RefreshCw } from 'react-feather';
import { alertService } from '../../_services/alert.service';
import './excelUpload.css';

class ExcelUpload extends React.Component{
    constructor(props){
        super(props);
        this.state = {
           orgUnitList:[], yearsList:[],
           mobj:{year:"0", gender:"0", dataType:"0", organizationUnit:"0", files:null}, showData:true,
           isLoading:false, showModal:false, 
           respObj:{
            "status": false,
            "code": "",
            "extra": {
                "cancerSiteErrors": [
                    
                ],
                "mainStatusOfUpload": ""
            },
            "count": 0
        }
        }
        
    }

    componentDidMount(){
        this.getMAsterData();
        this.getOrgUnits();
    }

    getMAsterData = () =>{
        submitSets(submitCollection.getMAsterData,null).then(res=> {
            if(res.status===true){
                let cyear = res.extra.currentYear; let yearlist = [];
                for (let i = 2005; i <= cyear; i++) {
                    yearlist.push(i)
                }
                this.setState({yearsList:yearlist});
            }
        });
    }
    getOrgUnits = () =>{
        submitSets(submitCollection.getAllOrganizationUnits,null).then(res=> {
            if(res.status===true){
              this.setState({orgUnitList:res.extra});
            }
        });
    }

    changeVals = (e,type) =>{
        let sobj = this.state.mobj;
        if(type==="year"){
            sobj.year = e;
        }
        else if(type==="gender"){
            sobj.gender = e;
        }
        else if(type==="dtype"){
            sobj.dataType = e;
        }
        else if(type==="ounit"){
            sobj.organizationUnit = e;
        }
        else if(type==="file"){
            sobj.files = e;
        }
        //console.log(e);
        this.setState({mobj:sobj});
    }

    upload = () =>{
        let sobj = this.state.mobj;
        
        if(sobj.year==="0" || sobj.gender==="0" || sobj.dataType==="0" || sobj.organizationUnit==="0"){
            alertService.warn("Enter All Data");
        }
        else if(sobj.files===null){
            alertService.warn("Select a File");
        }
        else{
            let formData = new FormData();
            formData.append("year", sobj.year);
            formData.append("gender", sobj.gender);
            formData.append("dataType", sobj.dataType);
            formData.append("organizationUnit", sobj.organizationUnit);
            formData.append("files", sobj.files);

            //get org ubit name
            let ounitname = "";
            for (let i = 0; i < this.state.orgUnitList.length; i++) {
               if(this.state.orgUnitList[i].disId === sobj.organizationUnit){
                    ounitname = this.state.orgUnitList[i].disEngName;
               }
            }
            this.setState({isLoading:true});
            if(window.confirm("Are you sure to continue with the year "+sobj.year+"?")){
                if(window.confirm("Are you sure to continue with the Organization Unit "+ounitname+"?")){

                    submitSets(submitCollection.uploadReadAndSaveExelFileData,formData).then(res=> {
                        if(res.status===true){
                            //alertService.success("Successfully Uploaded");
                            this.reset()
                        }
                        else{
                            let csobj = this.state.mobj;
                            csobj.files = null;
                            this.setState({mobj:csobj,showData:false }, ()=>{
                                this.setState({showData:true});
                            });
                            //alertService.error(res.extra.mainStatusOfUpload && res.extra.mainStatusOfUpload!==""? res.extra.mainStatusOfUpload: "Error Occurred");
                        }
                        this.setState({isLoading:false, respObj:res});
                        this.setModalShow(true);
                    });
                }
            }
        }
    }

    reset (){
        this.setState({mobj:{year:"0", gender:"0", dataType:"0", organizationUnit:"0", files:null},showData:false }, ()=>{
            this.setState({showData:true});
        });
        
    }

    setModalShow = (type) =>{
        this.setState({showModal:type});
    }

    render(){
        return(
            this.state.showData===true ?
            <>
                <Col xs={5} className="main-container">
                    <h5 className="main-heading">Excel Upload</h5>
                    <Col xs={12} className="sub-container">
                        <Table style={{marginTop:"0px"}}>
                            <tbody>
                                <tr>
                                    <td width="30%"><label>Year</label></td>
                                    <td>
                                        <select disabled={this.state.isLoading===true ? "disabled" : ""} className="form-control" value={this.state.mobj.year} onChange={(event)=>this.changeVals(event.target.value,"year")}>
                                            <option value="0">-</option>
                                            {
                                                this.state.yearsList.map((item, i) =>{
                                                    return (
                                                        <option key={i} value={item}>{item}</option>
                                                    )
                                                }) 
                                            }
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td><label>Gender</label></td>
                                    <td>
                                        <select disabled={this.state.isLoading===true ? "disabled" : ""} className="form-control" value={this.state.mobj.gender} onChange={(event)=>this.changeVals(event.target.value,"gender")}>
                                            <option value="0">-</option>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                            <option value="Both">Both</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td><label>Data Type</label></td>
                                    <td>
                                        <select disabled={this.state.isLoading===true ? "disabled" : ""} className="form-control" value={this.state.mobj.dataType} onChange={(event)=>this.changeVals(event.target.value,"dtype")}>
                                            <option value="0">-</option>
                                            <option value="AgeSpecificCount">Age Specific Count</option>
                                            <option value="AgeSpecificRate">Age Specific Rate</option>
                                            <option value="FactSheetData">Fact Sheet Data</option>
                                            <option value="PaediatricData">Paediatric Data</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td><label>Unit</label></td>
                                    <td>
                                        <select disabled={this.state.isLoading===true ? "disabled" : ""} className="form-control" value={this.state.mobj.organizationUnit} onChange={(event)=>this.changeVals(event.target.value,"ounit")}>
                                            <option value="0">-</option>
                                            {
                                                this.state.orgUnitList.map((item, i) =>{
                                                    return (
                                                        <option key={i} value={item.disId}>{item.disEngName}</option>
                                                    )
                                                }) 
                                            }
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td><label>File</label></td>
                                    <td>
                                        <input
                                            disabled={this.state.isLoading===true ? "disabled" : ""}
                                            className="form-control"
                                            style={{fontSize:"13px",padding:"7px"}}
                                            type="file"
                                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                            onChange={(e) => this.changeVals(e.target.files[0],"file")}
                                            />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        
                                    </td>
                                    <td style={{textAlign:"right"}}>
                                        {
                                            this.state.isLoading===false?
                                            <>
                                                <Button variant="secondary" size="sm" onClick={()=> this.reset()}>Reset</Button>{ " " }
                                                <Button className="primary-button" size="sm" onClick={()=> this.upload()}>Upload</Button>
                                            </>
                                            :
                                            <div className={"uploading-stat"}>
                                                <RefreshCw className="spinner" size="15" /> Uploading
                                             </div>
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Col>


                <Modal backdrop="static" animation={false} size="lg" show={this.state.showModal} onHide={() => this.setModalShow(false)}>
                    <Modal.Header style={this.state.respObj.status===true?{background:"#138D75"}:{background:"#CB4335"}}>
                        <Modal.Title>{this.state.respObj.extra.mainStatusOfUpload}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            this.state.respObj.status===true?
                            <Col className="dhis-summary-main">
                                <h6>DHIS Summary</h6>
                                <Table id="dhisSummaryTbl">
                                    <tbody>
                                        <tr>
                                            <td style={{width:"50px"}}><label>Response Type</label></td>
                                            <td style={{width:"350px"}}>{this.state.respObj.extra.dhisUploadSummary ? this.state.respObj.extra.dhisUploadSummary.responseType:""}</td>
                                        </tr>
                                        <tr>
                                            <td style={{width:"50px"}}><label>Description</label></td>
                                            <td style={{width:"350px"}}>{this.state.respObj.extra.dhisUploadSummary ? this.state.respObj.extra.dhisUploadSummary.description:""}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2">
                                                <Row>
                                                    <Col xs={2} className="count-main"><div className="count-sec">Imported <span className="count-val">{this.state.respObj.extra.dhisUploadSummary ? this.state.respObj.extra.dhisUploadSummary.imported:""}</span></div></Col>
                                                    <Col xs={2} className="count-main"><div className="count-sec">Updated  <span className="count-val">{this.state.respObj.extra.dhisUploadSummary ? this.state.respObj.extra.dhisUploadSummary.updated:""}</span></div></Col>
                                                    <Col xs={2} className="count-main"><div className="count-sec">Conflicts  <span className="count-val">{this.state.respObj.extra.dhisUploadSummary ? this.state.respObj.extra.dhisUploadSummary.conflictsCount:""}</span></div></Col>
                                                    <Col xs={2} className="count-main"><div className="count-sec">Ignored  <span className="count-val">{this.state.respObj.extra.dhisUploadSummary ? this.state.respObj.extra.dhisUploadSummary.ignored:""}</span></div></Col>
                                                    {/* <Col xs={2} className="count-main"><div className="count-sec">Deleted  <span className="count-val">{this.state.respObj.extra.dhisUploadSummary ? this.state.respObj.extra.dhisUploadSummary.deleted:""}</span></div></Col> */}
                                                </Row>
                                            </td>
                                        </tr>

                                    </tbody>
                                </Table>
                            </Col>:<></>

                        }
                        
                        {
                            this.state.respObj.extra.cancerSiteErrors.length>0?
                            <Col className="cserr-main">
                                <h6>Cancer Site Errors</h6>
                                <Table bordered id="cserrTbl">
                                    <thead>
                                        <tr>
                                            <th style={{width:"150px"}}>Cancer Site</th>
                                            <th style={{width:"350px"}}>Error</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        this.state.respObj.extra.cancerSiteErrors.map((item, i) =>{
                                            return(
                                                <tr key={i}>
                                                    <td>{item.cancerSiteName}</td>
                                                    <td>{item.cancerSiteError}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                    
                                    </tbody>
                                </Table>
                            </Col>:<></>
                        }
                        
                    </Modal.Body>
                    <Modal.Footer style={{padding:"2px"}}>
                        <Button size="sm" variant="secondary" onClick={() => this.setModalShow(false)}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </>


            :<></>
        )
    }
}

export default ExcelUpload;
