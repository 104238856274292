import { alertService } from './alert.service';
import { basePath, chartBasePath } from './common.service';
import { persistService } from './persist.service';
import { validateSets } from '../components/UiComponents/ValidateSets';

const submitCollection = {
    //app calls
    signin:{ ptype: "POST", url: basePath+"service/system/login", queryparam: false, data: true },
    changePass:{ ptype: "POST", url: basePath+"service/master/ChangePassword", queryparam: false, data: true },

    //fact sheets
    getAllFactSheets:{ptype: "GET", url: basePath+"service/master/GetAllFactSheets", queryparam: false, data: false },

    //cancer sites
    getAllCancerSites:{ptype: "GET", url: basePath+"service/master/GetCancerSiteDetails", queryparam: false, data: false },
    updateCancerSiteDetails:{ptype: "POST", url: basePath+"service/master/UpdateCancerSiteDetails", queryparam: false, data: true },

    //master data UpdateMasterSettings
    getMAsterData:{ptype: "GET", url: basePath+"service/master/GetMasterData", queryparam: false, data: false },
    UpdateMasterSettings:{ptype: "POST", url: basePath+"service/master/UpdateMasterSettings", queryparam: false, data: true },

    //roadmap
    getAllRoadmap:{ptype: "GET", url: basePath+"service/master/GetAllRoadmapDetails", queryparam: false, data: false },
    updateRoadmap:{ptype: "POST", url: basePath+"service/master/UpdateRoadMapDetails", queryparam: false, data: true },
    deleteRoadmap:{ptype: "POST", url: basePath+"service/master/DeleteRoadMapDetail", queryparam: false, data: true },

    //body parts
    getAllBodyParts:{ptype: "GET", url: basePath+"service/master/GetBodyPartDetais", queryparam: false, data: false },
    UpdateBodyPart:{ptype: "POST", url: basePath+"service/master/UpdateBodyPart", queryparam: false, data: true },
    deleteBodyPart:{ptype: "POST", url: basePath+"service/master/DeleteBodyPart", queryparam: false, data: true },

    //chart CRUD parts
    getAllCharts:{ptype: "GET", url: basePath+"service/master/GetChartDescriptions", queryparam: false, data: false },
    UpdateChart:{ptype: "POST", url: basePath+"service/master/UpdateChartDescription", queryparam: false, data: true },

    //search keywords
    getSearchKeywords:{ptype: "GET", url: basePath+"service/master/GetSearchKeywords", queryparam: false, data: false },
    UpdateSearchKeyword:{ptype: "POST", url: basePath+"service/master/UpdateSearchKeyword", queryparam: false, data: true },
    
    //charts calls 
    getCommonCounts:{ ptype: "POST", url: basePath+"service/factsheets/getCancerSiteWiseCommonData", queryparam: false, data: true },

    getProprtionOfLeadingCancers:{ ptype: "POST", url: basePath+"service/latest/getProprtionOfLeadingCancers", queryparam: false, data: true },
    getOverallAgeSpecificIncidenceRateOfMaleAndFemale:{ ptype: "POST", url: basePath+"service/latest/getOverallAgeSpecificIncidenceRateOfMaleAndFemale", queryparam: false, data: true },
    getAgeStandardizedIncidenceRates:{ ptype: "POST", url: basePath+"service/trends/getAgeStandardizedIncidenceRates", queryparam: false, data: true },

    getOUWiseCRAndMortalityRatesMaleAndFemaleOverYears:{ ptype: "POST", url: basePath+"service/factsheets/getOUWiseCRAndMortalityRatesMaleAndFemaleOverYears", queryparam: false, data: true },
    getNCRAndPBCROverYears:{ ptype: "POST", url: basePath+"service/factsheets/getNCRAndPBCROverYears", queryparam: false, data: true },
    getNCRAndPBCRCancerSiteWise:{ ptype: "POST", url: basePath+"service/factsheets/getNCRAndPBCRCancerSiteWise", queryparam: false, data: true },

    getCrudeCancerIncidenceRates:{ ptype: "POST", url: basePath+"service/trends/getCrudeCancerIncidenceRates", queryparam: false, data: true },

    getOverallAgeSpecificIncidenceRate:{ ptype: "POST", url: basePath+"service/latest/getOverallAgeSpecificIncidenceRate", queryparam: false, data: true },
    getAgeSpecificIncidenceProportionOfGroupedAgeRange:{ ptype: "POST", url: basePath+"service/latest/getAgeSpecificIncidenceProportionOfGroupedAgeRange", queryparam: false, data: true },

    getASRData:{ ptype: "POST", url: basePath+"service/latest/getASRData", queryparam: false, data: true },
    getTopTenCancersGenderWiseASRData:{ ptype: "POST", url: basePath+"service/latest/getTopTenCancersGenderWiseASRData", queryparam: false, data: true },


    //ped
    getGroupedAgeRangeGenderWiseCounts:{ ptype: "POST", url: basePath+"service/factsheets/getGroupedAgeRangeGenderWiseCounts", queryparam: false, data: true },
    getGroupedAgeRangeGenderWiseCrudeRates:{ ptype: "POST", url: basePath+"service/factsheets/getGroupedAgeRangeGenderWiseCrudeRates", queryparam: false, data: true },
    getGroupedAgeRangeGenderWiseAgeStandardizedIncidenceRates:{ ptype: "POST", url: basePath+"service/factsheets/getGroupedAgeRangeGenderWiseAgeStandardizedIncidenceRates", queryparam: false, data: true },
    getAgeRangeGenderWiseAgeSpecificRates:{ ptype: "POST", url: basePath+"service/factsheets/getAgeRangeGenderWiseAgeSpecificRates", queryparam: false, data: true },
    GetDistributionOfChildhoodCancers:{ ptype: "POST", url: basePath+"service/factsheets/GetDistributionOfChildhoodCancers", queryparam: false, data: true },
    getPedreacticASRSummary:{ ptype: "POST", url: basePath+"service/factsheets/getCancerSiteWiseCommonData", queryparam: false, data: true },

    getAllOrganizationUnits:{ ptype: "POST", url: basePath+"service/upload/getAllOrganizationUnits", queryparam: false, data: false },
    uploadReadAndSaveExelFileData:{ ptype: "POST", url: basePath+"service/upload/uploadReadAndSaveExelFileData", queryparam: false, data: true },
    uploadFactSheetFile:{ ptype: "POST", url: basePath+"service/upload/uploadFactSheetFile", queryparam: false, data: true },
}

const autoClose = true;
const keepAfterRouteChange = false;

function errorHandler(err, showalerts) {
    if (err !== undefined) {
        if(err&&err.message&&err.message.indexOf("401") > -1){
            persistService.persist({},true);
        }
        if (showalerts) {
            alertService.error(err, { autoClose, keepAfterRouteChange });
        }
    } else {
        if (showalerts) {
            alertService.error("Unrecognized error", { autoClose, keepAfterRouteChange });
        }
    }
}

function validateObj(vobj, cobj) {
    var cvarr = (vobj&&Object.keys(vobj).length>0?Object.keys(vobj).map((key) => { return [key, vobj[key]]; }):[]);
    
    if (cvarr !== undefined && cvarr.length > 0) {
        if (cobj !== undefined) {
            for (let i = 0; i < cvarr.length; i++) {
                if(cvarr[i][1] && cvarr[i][1].constructor === String){
                    if(cvarr[i][1] && cvarr[i][1] !== "" && cobj.hasOwnProperty(cvarr[i][0])){
                        var vresp = validateSets(cvarr[i][0], cvarr[i][1], cobj[cvarr[i][0]]);
                        if (vresp.validatestate !== null) {
                            return { status: false, msg: vresp.validatemsg };
                        }    
                    } else{
                        return {status:false,msg:"Required to fill "+cvarr[i][0]};
                    }
                } else if(cvarr[i][1] && cvarr[i][1].constructor === Object){
                    var coarr = (cvarr[i][1]&&Object.keys(cvarr[i][1]).length>0?Object.keys(cvarr[i][1]).map((key) => { return [key, cvarr[i][1][key]]; }):[]);
                    var cdobj = cobj[cvarr[i][0]];
                    for (let j = 0; j < coarr.length; j++) {
                        if(coarr[j][1] && coarr[j][1] !== "" && cdobj.hasOwnProperty(coarr[j][0])){
                            var voresp = validateSets(coarr[j][0], coarr[j][1], cdobj[coarr[j][0]]);
                            if (voresp.validatestate !== null) {
                                return { status: false, msg: voresp.validatemsg };
                            }    
                        } else{
                            return {status:false,msg:"Required to fill "+cvarr[i][0]};
                        }
                    }
                } else if(cvarr[i][1] && cvarr[i][1].constructor === Array){
                    var cvoarr = cvarr[i][1];
                    var cvoobj = (cobj[cvarr[i][0]]?cobj[cvarr[i][0]]:{});
                    
                    for (let l = 0; l < cvoarr.length; l++) {
                        var caoarr = [];
                        if(cvoarr[l]&&Object.keys(cvoarr[l]).length>0){
                            for (let key in cvoarr[l]) {
                                caoarr.push([key, cvoarr[l][key]]);
                            }    
                        }
                        var caoobj = cvoobj[l];
                        for (let j = 0; j < caoarr.length; j++) {
                            if(caoarr[j][1] && caoarr[j][1] !== "" && caoobj.hasOwnProperty(coarr[j][0])){
                                var varesp = validateSets(caoarr[j][0], caoarr[j][1], caoobj[coarr[j][0]]);
                                if (varesp.validatestate !== null) {
                                    return { status: false, msg: varesp.validatemsg };
                                }    
                            } else{
                                return {status:false,msg:"Required to fill "+cvarr[i][0]};
                            }
                        }
                    }
                }
            }
        } else {
            return { status: false, msg: "Data object not found" };
        }
    }
    return { status: true, msg: "" };
}

export { submitCollection, errorHandler, validateObj };