import React from 'react';
import { Row, Col, Table, Modal, Button, Form, ButtonGroup } from 'react-bootstrap';

import { submitCollection } from '../../_services/submit.service';
import { submitSets } from '../UiComponents/SubmitSets';
import { alertService } from '../../_services/alert.service';
import './bodyParts.css';
import LoadingIcon from  '../../assets/img/loading.gif';
import { X } from 'react-feather';
import { pageLength, getPager } from '../../_services/common.service';

class BodyParts extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            currentPage: 1, showPagination:true,
            isLoading:false,showEditModal:false,
            mainList:[], selectedObj:{bodyPartId:"0",bodyPartCode:"",gender:"0",bodyPartNameSin:"", bodyPartNameTam:"", bodyPartName:"", description:"", descriptionSin:"", descriptionTam:"", cancerSites:[]},
            cancerSites:[], cancerSiteId:"0",
            oriList:[], filtertxt:"",
        }
        
    }

    componentDidMount(){
        this.loadAllCancerSites();
        this.loadAllBodyParts();
    }
    
    openNew =()=>{
        this.setState({showEditModal:true,selectedObj:{bodyPartId:"0",bodyPartCode:"",gender:"0",bodyPartName:"",bodyPartNameSin:"", bodyPartNameTam:"", description:"", descriptionSin:"", descriptionTam:"", cancerSites:[]}});
    }

    loadAllCancerSites = () =>{
        submitSets(submitCollection.getAllCancerSites,null).then(res=> {
            if(res.status===true){
                this.setState({cancerSites:res.extra });
            }
            else{
                this.setState({cancerSites:[]});
            }
        });
    }
    
    loadAllBodyParts = () =>{
        this.setState({isLoading:true, mainList:[], showPagination:false})
        submitSets(submitCollection.getAllBodyParts,null).then(res=> {
            if(res.status===true){
                this.setState({mainList:res.extra,oriList:res.extra, isLoading:false});
                this.setPage(this.state.currentPage);
            }
            else{
                this.setState({mainList:[],oriList:[], isLoading:false});
            }
        });
    }

    setModalShow = (type) =>{
        this.setState({showEditModal:type});
    }

    clickBodyPart = (obj) => {
        let sobj = JSON.parse(JSON.stringify(obj))
        this.setState({selectedObj:sobj, cancerSiteId:"0"});
        this.setModalShow(true)
    }

    changeVals = (e,type) =>{
        if(type==="name"){
            let sobj = this.state.selectedObj;
            sobj.bodyPartName = e;
            this.setState({sobj:sobj});
        }
        if(type==="sname"){
            let sobj = this.state.selectedObj;
            sobj.bodyPartNameSin = e;
            this.setState({sobj:sobj});
        }
        if(type==="tname"){
            let sobj = this.state.selectedObj;
            sobj.bodyPartNameTam = e;
            this.setState({sobj:sobj});
        }
        else if(type==="code"){
            let sobj = this.state.selectedObj;
            sobj.bodyPartCode = e;
            this.setState({sobj:sobj});
        }
        else if(type==="des"){
            let sobj = this.state.selectedObj;
            sobj.description = e;
            this.setState({sobj:sobj});
        }
        else if(type==="sdes"){
            let sobj = this.state.selectedObj;
            sobj.descriptionSin = e;
            this.setState({sobj:sobj});
        }
        else if(type==="tdes"){
            let sobj = this.state.selectedObj;
            sobj.descriptionTam = e;
            this.setState({sobj:sobj});
        }
        else if(type==="cs"){
            this.setState({cancerSiteId:e});
        }
        else if(type==="gender"){
            let sobj = this.state.selectedObj;
            sobj.gender = e;
            this.setState({sobj:sobj});
        }
    }

    addCancerSite = () =>{
        if(this.state.cancerSiteId==="0" || this.state.cancerSiteId===0){
            alertService.warn("Select a Body Part!");
        }
        else{
            let sobj = this.state.selectedObj;
            //console.log(this.state.selectedObj);console.log(this.state.cancerSites);console.log(this.state.cancerSiteId);
            let count = 0;
            for (let x = 0; x < sobj.cancerSites.length; x++) {
                if(parseInt(this.state.cancerSiteId)===parseInt(sobj.cancerSites[x].cancerSiteId )){
                    if(sobj.cancerSites[x].isDelete!==true){
                        count = (count +1);
                    }
                }
            }

            if(count===0){
                let bpname = "";
                for (let i = 0; i < this.state.cancerSites.length; i++) {
                    if(parseInt(this.state.cancerSiteId)===parseInt(this.state.cancerSites[i].cancerSiteId)){
                        bpname = this.state.cancerSites[i].cancerSiteNameEng;
                    }
                }
                sobj.cancerSites.push({bodyPartCancerSiteId:0, cancerSiteId:this.state.cancerSiteId, cancerSiteNameEng:bpname});
                this.setState({sobj:sobj, cancerSiteId:"0"});
            }
            else{
                alertService.warn("Already Added!");
            }

        }

    }

    deleteCancerSite = (i) =>{
        let sobj = this.state.selectedObj;
       // console.log(this.state.selectedObj);
        if(sobj.cancerSites[i].bodyPartCancerSiteId===0){
            sobj.cancerSites.splice(i,1);
        }
        else{
            sobj.cancerSites[i].isDelete = true;
        }
        this.setState({sobj:sobj});
    }

    save = () =>{
        let sobj = this.state.selectedObj;
        if(sobj.bodyPartCode==="" || sobj.bodyPartName==="" || sobj.gender==="0" ){
            alertService.warn("Enter required data!");
        }
        else{
            submitSets(submitCollection.UpdateBodyPart,this.state.selectedObj).then(res=> {
                if(res.status===true){
                    this.loadAllBodyParts();
                    this.setModalShow(false);
                    alertService.success("Successfully Saved");
                }
                else{
                    alertService.error("Error Occurred");
                }
            });
        }
    }

    deleteBodyPartItem = (item) =>{
        if(window.confirm("Are you sure to delete this?")){
            submitSets(submitCollection.deleteBodyPart,item).then(res=> {
                if(res.status===true){
                    this.loadAllBodyParts();
                    alertService.success("Successfully Deleted");
                }
                else{
                    alertService.error("Error Occurred");
                }
            });
        }
    }

    handleFilterTxt = e => {
        var tarr = [];
        var mlist = this.state.oriList;
        var filterval = e.target.value.toLowerCase();
        this.setState({ filtertxt: filterval, showPagination:false });

        //console.log(filterval);
        if (filterval !== "") {
            for (var i = 0; i < mlist.length; i++) {
                if (mlist[i].bodyPartCode.toLowerCase().indexOf(filterval) > -1 || mlist[i].bodyPartName.toLowerCase().indexOf(filterval) > -1 ) {
                    tarr.push(mlist[i]);
                }
            }
            this.setState({ mainList: tarr });
        }
        else{
            this.setPage(1);
        }
        
    }

    setPage = (cpage) => {
        var citems = JSON.parse(JSON.stringify(this.state.oriList));
        var pager = getPager(citems.length, cpage, pageLength);
        // check page isn't out of range
        if (cpage < 1 || cpage > pager.totalPages) {
            return;
        }
        var sspage = (pager.currentPage === 1 ? 0 : ((pager.currentPage - 1) * pageLength));
        var sepage = (pager.currentPage * pageLength);
        this.setState({
            mainList: citems.slice(sspage, sepage),
            currentPage: pager.currentPage,
            totalPages: pager.totalPages,
        });

        this.setState({showPagination:(citems.length>0 ? true:false)});
    }

    render(){
        return(
            <>
                <Col xs={7} className="main-container">
                    <h5 className="main-heading">
                        Body Parts
                        <ul className="top-right-content list-inline">
                            {/* <li className="list-inline-item"><Button variant="primary" size="sm" onClick={()=>this.openNew()}>Add New</Button></li> */}
                            <li className="list-inline-item"><input className="form-control find-txt" placeholder="Find" value={this.state.filtertxt} onChange={this.handleFilterTxt}  /></li>
                        </ul>    
                    </h5>

                    <Table bordered hover size="sm" className="main-table">
                        <thead>
                            <tr>
                            <th width="40px">Code</th>
                            <th width="200px">Name</th>
                            <th width="100px">Gender</th>
                            <th width="100px">#Cancer Sites</th>
                            {/* <th width="30px"></th> */}
                            </tr>
                        </thead>
                        <tbody>
                            <tr className={this.state.isLoading===true?"":"d-none"}><td colSpan="10" align="center" style={{backgroundColor:"#FFF"}}><img alt="" src={LoadingIcon} /></td></tr>
                            {
                                this.state.mainList.map((item, i) =>{
                                    return(
                                        <tr key={i}>
                                            <td onClick={()=>this.clickBodyPart(item)}>{item.bodyPartCode}</td>
                                            <td onClick={()=>this.clickBodyPart(item)}>{item.bodyPartName}</td>
                                            <td onClick={()=>this.clickBodyPart(item)} align="center">{item.gender}</td>
                                            <td onClick={()=>this.clickBodyPart(item)} align="center">{item.cancerSites.length}</td>
                                            {/* <td align="center"><span className="delete" onClick={()=>this.deleteBodyPartItem(item)}><X size="14" /></span></td> */}
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                    <Col sm={12} className={this.state.showPagination===true?"div-pagination":"div-pagination d-none" }>
                            <ButtonGroup aria-label="Basic example">
                                <Button onClick={() => { this.setPage(1) }} variant="default" size="sm">First</Button>
                                <Button onClick={() => { this.setPage((this.state.currentPage - 1)) }} variant="default" size="sm">Prev</Button>
                                <Button variant="default" size="sm" disabled id="btncurpageno">Page {this.state.currentPage}</Button>
                                <Button onClick={() => { this.setPage((this.state.currentPage + 1)) }} variant="default" size="sm">Next</Button>
                                <Button onClick={() => { this.setPage(this.state.totalPages) }} variant="default" size="sm">Last</Button>

                            </ButtonGroup>
                    </Col>
                </Col>

                <Modal size="lg" animation={false} show={this.state.showEditModal} onHide={() => this.setModalShow(false)}>
                    <Modal.Header>
                        <Modal.Title>{this.state.selectedObj.bodyPartId>0?"Edit":"Add"} Body Part</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col xs={6}>
                                <Form.Group>
                                    <Form.Label>Code*</Form.Label>
                                    <input className="form-control" type="text" size="sm" value={this.state.selectedObj.bodyPartCode} onChange={(event)=>this.changeVals(event.target.value,"code")} disabled/>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Gender*</Form.Label>
                                    <select className="form-control" size="sm" value={this.state.selectedObj.gender} onChange={(event)=>this.changeVals(event.target.value,"gender")}>
                                        <option value="0">-</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Famale</option>
                                        <option value="Both">Both</option>
                                    </select>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>English Name*</Form.Label>
                                    <input className="form-control" type="text" size="sm" value={this.state.selectedObj.bodyPartName} onChange={(event)=>this.changeVals(event.target.value,"name")} />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Sinhala Name</Form.Label>
                                    <input className="form-control" type="text" size="sm" value={this.state.selectedObj.bodyPartNameSin!==null?this.state.selectedObj.bodyPartNameSin:""} onChange={(event)=>this.changeVals(event.target.value,"sname")} />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Tamil Name</Form.Label>
                                    <input className="form-control" type="text" size="sm" value={this.state.selectedObj.bodyPartNameTam!==null?this.state.selectedObj.bodyPartNameTam:""} onChange={(event)=>this.changeVals(event.target.value,"tname")} />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Cancer Sites</Form.Label>
                                    <ul className="list-inline">
                                        <li className="list-inline-item" style={{width:"80%"}}>
                                            <select className="form-control form-control-sm" value={this.state.cancerSiteId} onChange={(event)=>this.changeVals(event.target.value,"cs")}>
                                                <option value="0">-</option>
                                                {
                                                    this.state.cancerSites.map((item, i) =>{
                                                        return(
                                                            <option key={i} value={item.cancerSiteId}>{item.cancerSiteNameEng}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </li>
                                        <li className="list-inline-item" ><Button size="sm" variant="primary" onClick={()=>this.addCancerSite()}>Add</Button></li>
                                    </ul>
                                </Form.Group>

                                <Col>
                                    {
                                        this.state.selectedObj.cancerSites.map((item, i) =>{
                                            return(
                                                <label key={i} className={item.isDelete===true?"bodypart-item d-none":"bodypart-item"}>
                                                    <span onClick={()=>this.deleteCancerSite(i)}><X size={15}/></span>
                                                    {item.cancerSiteNameEng}
                                                </label>
                                            )
                                        })
                                    }
                                </Col>

                            </Col>
                            <Col xs={6}>
                                <Form.Group>
                                    <Form.Label>English Description</Form.Label>
                                    <textarea className="form-control" rows="3" value={this.state.selectedObj.description!==null?this.state.selectedObj.description:""} onChange={(event)=>this.changeVals(event.target.value,"des")}> </textarea>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Sinhala Description</Form.Label>
                                    <textarea className="form-control" rows="3" value={this.state.selectedObj.descriptionSin!==null?this.state.selectedObj.descriptionSin:""} onChange={(event)=>this.changeVals(event.target.value,"sdes")}> </textarea>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Tamil Description</Form.Label>
                                    <textarea className="form-control" rows="3" value={this.state.selectedObj.descriptionTam!==null?this.state.selectedObj.descriptionTam:""} onChange={(event)=>this.changeVals(event.target.value,"tdes")}> </textarea>
                                </Form.Group>
                            
                            </Col>
                        </Row>
                        

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" size="sm" onClick={() => this.setModalShow(false)}>Close</Button>
                        <Button className="primary-button" size="sm" onClick={() => this.save()}>Save Changes</Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}

export default BodyParts;
