import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Col, Button, Row, ButtonGroup } from 'react-bootstrap';

import DynamicDonut from '../charttypes/donut';
import DynamicBarChart from '../charttypes/barchart';
import DynamicBarChartComparison from '../charttypes/barchart-comparison';
import DynamicLineChart from '../charttypes/linechart';
import html2pdf from 'html2pdf.js';

import { submitCollection } from '../../_services/submit.service';
import { submitSets } from '../UiComponents/SubmitSets';
//import { alertService } from '../../_services/alert.service';
import { numberWithCommas } from '../../_services/common.service';

import { logoutAction } from '../../actions/auth/login_action';

//import i18n from "i18next";
import { withTranslation } from "react-i18next";
import "../../_translations/i18n";
import "./twoPageFactSheetBothSex.css";

import nccplogo from '../../assets/img/logo512.png';
import foldericon from '../../assets/img/slcr-logo.png';
/* import asiaicon from '../../assets/img/maps/colombo.png';
import slicon from '../../assets/img/maps/srilanka.png'; */

class TwoPageFactSheet extends React.Component{
    _isMounted = false;

    constructor(props){
        super(props);
        this.state = {
            samplecolors: ['#2a52be','#de6fa1','#da4040','#486090', '#00e699', '#a035ea','#35ead4', '#FF6600', '#ebc55e', '#409caf'],
            
            //
            mostCommonData:null,
            mostCommonDataColors:[],

            trendASRData:{categories:[],series:[{name:"",data:[]}],colors:[]},
            trendASRDataColors: [], trendASRColors: null,
            
            trendCRData:{categories:[],series:[{name:"",data:[]}],colors:[]},
            trendCRDataColors: [], trendCRColors: null,
            
            ageGroupIncidentsData : {categories:[], series:[{data:[]}]},

            topTenMaleCancersData : {categories:[],series:[{data:[]}]},
            topTenFemaleCancersData : {categories:[],series:[{data:[]}]},

            topTenASRCancersData : {categories:[],series:[{data:[]}]},
            highestValue:0,
           
            isbardataloaded: false,

            minyear1: "-", maxyear1: "-",
            minyear2: "-", maxyear2: "-",
        }

        this.signinBtnElement = React.createRef();
    }
      
    componentDidMount(){
        this.getTrendASR();
        this.getTrendCR();
        this.getAgeSpecIncidents();
        this.getMostCommonCancers();
        //this.getTopTenASRCancers("Male");
        //this.getTopTenASRCancers("Female");
        this.getASRTopTenData()
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    getASRTopTenData=()=>{
        let sobj = {selCancerSites:[{id: 0,name: "All Sites",icdcode: "All Sites"}], gender: "Both", totalNoOfResults:10, maxYear:this.props.fobj.maxYear};
        submitSets(submitCollection.getTopTenCancersGenderWiseASRData,sobj).then(res=> {
            if(res.status===true){
                let cancerSites = res.extra;
                
                let chartData = {categories:[],series:[{data:[]}], colors:[]};
        
                let marr = []; let farr = []; let highestVal = 0;
                for (let i = 0; i < cancerSites.length; i++) {
                    chartData.categories.push(cancerSites[i].cancerSiteName);
                    chartData.colors.push(cancerSites[i].cancerSiteColor);

                    marr.push(-Math.abs(cancerSites[i].maleCount));
                    farr.push(cancerSites[i].femaleCount);

                    if(highestVal<cancerSites[i].maleCount){
                        highestVal = cancerSites[i].maleCount
                    }

                    if(highestVal<cancerSites[i].femaleCount){
                        highestVal = cancerSites[i].femaleCount
                    }
                }
                highestVal = highestVal + 10;
                
                if(chartData.colors.length===0){
                    chartData.colors = this.state.samplecolors;
                }

                chartData.series = [];
                chartData.series.push({name:"Male", data:(marr)});
                chartData.series.push({name:"Female", data:farr});
                this.setState({topTenASRCancersData:chartData , highestValue:highestVal}, () => { this.setState({isbardataloaded: true}); });
            }
        });

        //console.log(chartData);
    }

    getTrendASR = () =>{
        let sobj = {selCancerSites:[{id: 0}], gender: "Both", basedOnType: this.props.fobj.basedOnType, minYear:this.props.fobj.minYear, maxYear:this.props.fobj.maxYear, totalNoOfResults:10};
        submitSets(submitCollection.getAgeStandardizedIncidenceRates,sobj).then(res=> {
            if(res.status===true){
                let data = res.extra;
                let barray = [];
                let lineChartData = {categories:[],series:[{name:"",data:[]}],colors:[]};
                
                let minyear = ""; let maxyear = "";

                for (let i = 0; i < data.length; i++) {
                    if(i === 0){
                        minyear = data[i].year;
                    } else if(i === (data.length - 1)){
                        maxyear = data[i].year;
                    }

                    lineChartData.categories.push(data[i].year);
                    for (let x = 0; x < data[i].values.length; x++) {
                        let yitem = data[i].values[x];
                        if(yitem.gender==="Both"){
                            barray.push(yitem.count);
                        }
                    }
                }

                lineChartData.series = [];
                lineChartData.series.push({name:"Both",data:barray});
                
                
                //console.log(lineChartData);
                this.setState({trendASRData:lineChartData, minyear1:minyear, maxyear1:maxyear  });
            }
        });
        
    }

    getTrendCR = () =>{
        let sobj = {selCancerSites:[{id: 0}], gender: "Both", basedOnType: this.props.fobj.basedOnType, minYear:this.props.fobj.minYear, maxYear:this.props.fobj.maxYear, totalNoOfResults:10};
        submitSets(submitCollection.getCrudeCancerIncidenceRates,sobj).then(res=> {
            if(res.status===true){
                let data = res.extra;
                let barray = [];
                let lineChartData = {categories:[],series:[{name:"",data:[]}],colors:[]};
                
                let minyear = ""; let maxyear = "";

                for (let i = 0; i < data.length; i++) {
                    if(i === 0){
                        minyear = data[i].year;
                    } else if(i === (data.length - 1)){
                        maxyear = data[i].year;
                    }

                    lineChartData.categories.push(data[i].year);
                    for (let x = 0; x < data[i].values.length; x++) {
                        let yitem = data[i].values[x];
                        if(yitem.gender==="Both"){
                            barray.push(yitem.count);
                        }
                    }
                }

                lineChartData.series = [];
                lineChartData.series.push({name:"Both",data:barray});
                
                
                //console.log(lineChartData);
                this.setState({trendCRData:lineChartData, minyear2:minyear, maxyear2:maxyear  });
            }
        });
        
    }

    getMostCommonCancers = () =>{
        
        let sobj = {selCancerSites:[{id: 0,name: "All Sites",icdcode: "All Sites"}], gender: "Both", totalNoOfResults:10, maxYear:this.props.fobj.maxYear};
        submitSets(submitCollection.getProprtionOfLeadingCancers,sobj).then(res=> {
            if(res.status===true){
                //console.log(res);
                let data = res.extra;
                let chartData = {categories:[],series:[],colors:[]};
                let mostcolors = [];
                for (let i = 0; i < data.length; i++) {
                    chartData.categories.push(data[i].cancerSiteName);
                    chartData.series.push(parseFloat(data[i].count));
                    chartData.colors.push(data[i].cancerSiteColor);
                    mostcolors.push({site:data[i].cancerSiteName, color:data[i].cancerSiteColor, count: data[i].count});
                }
                //console.log(mostcolors);
                this.setState({mostCommonData:chartData, mostCommonDataColors: mostcolors });
                
            }
        });
        
    }

    getAgeSpecIncidents = () =>{
        let sobj = {gender:"Both",cancerSite:"AllSitesAgeSpecificRate",  maxYear:this.props.fobj.maxYear};
        submitSets(submitCollection.getOverallAgeSpecificIncidenceRate,sobj).then(res=> {
            if(res.status===true && res.extra.length>0){
                let masdata = res.extra;
                let chartData = {categories:[],series:[{data:[]}]};
                let datalist = [];
                for (let i = 0; i < masdata.length; i++) {
                    chartData.categories.push(masdata[i].ageRangeName);
                    datalist.push(masdata[i].rate);
                }
         
                chartData.series = [];
                chartData.series.push({data:datalist});
               
                this.setState({ageGroupIncidentsData:chartData });
            }
        });
    }

    getTopTenASRCancers = ( gender) =>{
        let sobj = { gender: gender, maxYear:this.props.fobj.maxYear, totalNoOfResults:10,selCancerSites:[{id:"0"}], basedOnType: "CANCER_SITE"};
        submitSets(submitCollection.getASRData,sobj).then(res=> {
            if(res.status===true){
                let masdata = res.extra;
                let chartData = {categories:[],series:[{data:[]}],colors:[]};
                let datalist = [];
                for (let i = 0; i < masdata.length; i++) {
                    chartData.categories.push(masdata[i].cancerSiteName);
                    chartData.colors.push(masdata[i].cancerSiteColor);
                    datalist.push(masdata[i].count);
                }
                chartData.series.push({data:datalist});
               
                if(gender==="Male"){
                    this.setState({topTenMaleCancersData:chartData}, () => { this.setState({isbardataloaded: true}); });
                }
                else if(gender==="Female"){
                    this.setState({topTenFemaleCancersData:chartData}, () => { this.setState({isbardataloaded: true}); });
                }
                
            } else{
                this.setState({isbardataloaded: true});
            }
        });
    }

    export = () =>{
        var element = document.getElementById('generating-section-pop');
        var pagebreak = {mode:"avoid-all"};
        var opt = {
            margin:       0,
            filename:     this.props.fobj.sheetName.replace(/ /g,'')+"_"+this.props.language+'.pdf',
            image:        { type: 'jpeg', quality: 0.98},
            html2canvas:  { scale: 5, logging: true, dpi: 192, letterRendering: true },
            jsPDF:        { unit: 'mm', format: 'a4', orientation: 'portrait', compress: true, precision: 8 },
            pagebreak: pagebreak
        };

        html2pdf().set(opt).from(element).toPdf().get('pdf').then(function (pdf) {
            var totalPages = pdf.internal.getNumberOfPages(); 
            var cdate = new Date();
            //print current pdf width & height to console
            
            for (var i = 1; i <= totalPages; i++) {
              pdf.setPage(i);
              pdf.setFontSize(10);
              pdf.setTextColor("#d04571");
              //divided by 2 to go center
              //var img = new Image()
              //img.src = '../../assets/img/logo512.png'
              //pdf.addImage(img,"png", 5, 1, 10, 10);
              //pdf.text('Page ' + i + ' of ' + totalPages, 10, 290,);
              pdf.text('National Cancer Registry : All rights reserved '+cdate.getFullYear(), 100, 293,'center');
            } 
            }).save();
    }

    shadeColor(color, percent) {

        var R = parseInt(color.substring(1,3),16);
        var G = parseInt(color.substring(3,5),16);
        var B = parseInt(color.substring(5,7),16);
    
        R = parseInt(R * (100 + percent) / 100);
        G = parseInt(G * (100 + percent) / 100);
        B = parseInt(B * (100 + percent) / 100);
    
        R = (R<255)?R:255;  
        G = (G<255)?G:255;  
        B = (B<255)?B:255;  
    
        var RR = ((R.toString(16).length===1)?"0"+R.toString(16):R.toString(16));
        var GG = ((G.toString(16).length===1)?"0"+G.toString(16):G.toString(16));
        var BB = ((B.toString(16).length===1)?"0"+B.toString(16):B.toString(16));
    
        return "#"+RR+GG+BB;
    }

    render(){
        //console.log(this.state);

        return (
        <Col className="main-container-col" style={{width:"815px"}} >
            
            <Col style={{marginBottom:"5px"}}>
                <ButtonGroup aria-label="Basic example" size="sm">
                    <Button onClick={()=>this.props.changeLanguage("en")} variant={this.props.language==="en"?"primary":"secondary"}>English</Button>
                    <Button onClick={()=>this.props.changeLanguage("sin")} variant={this.props.language==="sin"?"primary":"secondary"}>Sinhala</Button>
                    <Button onClick={()=>this.props.changeLanguage("ta")} variant={this.props.language==="ta"?"primary":"secondary"}>Tamil</Button>
                </ButtonGroup>
            
                <Col style={{position:"relative", float:"right"}}>
                    <Button variant="success" size="sm" onClick={()=>this.export()}>Download</Button>{" "}
                    <Button variant="secondary" size="sm" onClick={()=>this.props.changeViewType("mainView")}>Back</Button>
                </Col>
            </Col>
            
            <Col xs={12} style={{width:"830px",border:"1px solid #ccc",padding:"10px"}}>
                <Col xs={12} className="generating-section-pop pop-based overall-based" id="generating-section-pop">
                    {/* <Col className="overlay-bg"></Col> */}
                    <Col className={"gs-title"+(this.props.language!=="en"?" sub-lang":"")}>
                        <Row style={{paddingLeft:"10px"}}>
                            <Col xs={1}>
                                <img alt="" src={foldericon} width="55px" id="mainLogo" style={{marginTop:"-4px"}} />
                            </Col>
                            <Col xs={11}>
                                <h6 className="main-heading-pop">{this.props.t("NCCP_SUB")}</h6>
                                <h5 className="sub-heading-pop">{this.props.t("NCCP")}</h5>
                                <img alt="" src={nccplogo} className="fsheet-logo"/>
                            </Col>    
                        </Row>
                    </Col>

                    <Col className={"chart-title-col"+(this.props.language!=="en"?" sub-lang":"")} style={{background:(this.props.fobj.color ? this.props.fobj.color:"#345fdf")}}>
                    {this.props.language==="en"?this.props.fobj.sheetName:this.props.language==="sin"?this.props.fobj.sinhalaName:this.props.language==="ta"?this.props.fobj.tamilName:""} - {this.props.fobj.maxYear}
                        <div className="year-view"><span>{this.props.t("FACT_SHEET")}</span></div>
                    </Col>

                    <Col xs={12} className={"chart-containts"+(this.props.language!=="en"?" sub-lang":"")}>
                        <Col  className="chart-sub-card" style={{height:"320px"}}>
                            <Row style={{padding:"5px 0px"}}>
                                <h6 className={"sub-title-single"+(this.props.language!=="en"?" sub-lang":"")} style={{color:(this.props.fobj.color ? this.props.fobj.color:"#345fdf"), marginBottom:"-10px"}}>{this.props.t("OVB_TOP10_CAN")} {this.props.fobj.year}</h6>
                                <Col xs={5} style={{padding:"0px",marginLeft:"-10px"}}>
                                    {this.state.mostCommonData?<DynamicDonut data={this.state.mostCommonData} isshowlegend={false} />:<></>}
                                </Col>
                                <Col xs={7} className="twofact-pielegend" style={{paddingLeft:"15px",paddingRight:"0px"}}>
                                    <Row>
                                        {this.state.mostCommonDataColors && this.state.mostCommonDataColors.length > 0?(this.state.mostCommonDataColors.map((xitem, xidx) => {
                                            return <Col key={xidx} xs={6}><div style={{background:(xitem.color?xitem.color:"#666")}}></div> 
                                            {xitem.site} <label style={{color:(xitem.color?this.shadeColor(xitem.color,-40):"#666")}}><small>n=</small>{numberWithCommas(xitem.count)}</label></Col>
                                        })):<></>}
                                    </Row>
                                </Col>   
                                
                            </Row>
                        </Col>

                        <Col className="chart-sub-card">
                            <Row>
                                <Col xs={12}><h6 className={"sub-title-single"+(this.props.language!=="en"?" sub-lang":"")} style={{color:(this.props.fobj.color ? this.props.fobj.color:"#345fdf"),marginBottom:"-10px"}}>{this.props.t("OVB_ASR_PER_SEX_TOP10")} {this.props.fobj.year}</h6></Col>
                                <Col xs={12} className="subchart-content"  style={{paddingLeft:"0px",height:"560px"}}>
                                    <Row style={{paddingLeft:"120px"}}>
                                        <Col xs={6} className="text-center"><div className="maleHeader">{this.props.t("Male")}</div></Col><Col xs={6} className="text-center"><div className="femaleHeader">{this.props.t("Female")}</div></Col>
                                    </Row>
                                    {this.state.isbardataloaded?<DynamicBarChartComparison data={this.state.topTenASRCancersData} x={"ASR"} y={undefined} height={550} isshowlegend={false} showdatalabel={true} angleType="hori" distributed={true} highestValue={this.state.highestValue} />:<></>}      
                                </Col>
                                {/* <Col xs={6} className="subchart-content" style={{paddingLeft:"0px",height:"290px"}}>
                                    <h6 style={{textAlign:"center"}}>{this.props.t("Male")}</h6>
                                    {this.state.isbardataloaded?<DynamicBarChart data={this.state.topTenMaleCancersData} x={"ASR"} y={this.props.t("CANCER_SITES_Y")} height={290} isshowlegend={false} showdatalabel={true} angleType="hori" distributed={true} />:<></>}   
                                    
                                </Col>
                                <Col xs={6} className="subchart-content" style={{paddingLeft:"0px",height:"290px"}}>
                                    <h6 style={{textAlign:"center"}} className="fmale">{this.props.t("Female")}</h6>
                                    {this.state.isbardataloaded?<DynamicBarChart data={this.state.topTenFemaleCancersData} x={"ASR"} y={this.props.t("CANCER_SITES_Y")} height={290} isshowlegend={false} showdatalabel={true} angleType="hori" distributed={true} />:<></>}
                                    
                                </Col> */}
                            </Row> 

                        </Col>

                    </Col>
                    
                    {/* first page end */}
                    {/* second page start */}
                    <div className="html2pdf__page-break"></div>
                    <Col xs={12} style={{height:'40px'}}></Col> 

                    <Col xs={12} className={"secondpage chart-containts"+(this.props.language!=="en"?" sub-lang":"")}>
                        <Col  className="chart-sub-card" style={{height:"280px"}}>
                            <Row style={{padding:"5px 0px"}}>
                                <h6 className="sub-title-single" style={{color:(this.props.fobj.color ? this.props.fobj.color:"#345fdf")}}>{this.props.t("OVB_CAN_INC_AGE")} {this.props.fobj.year}</h6>
                                <Col xs={12} style={{padding:"0px 10px",marginLeft:"-10px"}}>
                                {this.state.ageGroupIncidentsData.series.length>0?<DynamicBarChart data={this.state.ageGroupIncidentsData} x={this.props.t("AGE_GROUP_X")} y={this.props.t("Incidence rate per 100,000")} colors={["#5297E0"]} height={230} isshowlegend={false} showdatalabel={true} iscolumn={true} angleType="ver" distributed={false} />:<></>}
                                </Col>
                            </Row>
                        </Col>
                        
                        <Col  className="chart-sub-card" style={{height:"300px"}}>
                            <Row style={{padding:"5px 0px"}}>
                                <h6 className="sub-title-single" style={{color:(this.props.fobj.color ? this.props.fobj.color:"#345fdf")}}>{this.props.t("OVB_CR_BOTH")} {this.state.minyear2} - {this.state.maxyear2}</h6>
                                <Col xs={12} style={{height:"235px",marginTop:"-20px"}}>
                                    {this.state.trendCRData?<DynamicLineChart data={this.state.trendCRData} x={this.props.t("YEAR_X")} y={"CR"} height={275} isshowlegend={false} linestroke={3} ismarkers={true} isdatalable={true} colors={[this.props.fobj.color ? this.props.fobj.color:"#345fdf"]} />:<></>}
                                </Col>
                            </Row>
                        </Col>
                        <Col  className="chart-sub-card" style={{height:"300px"}}>
                            <Row style={{padding:"5px 0px"}}>
                                <h6 className="sub-title-single" style={{color:(this.props.fobj.color ? this.props.fobj.color:"#345fdf")}}>{this.props.t("OVB_ASR_BOTH")} {this.state.minyear1} - {this.state.maxyear1}</h6>
                                <Col xs={12} style={{height:"235px",marginTop:"-20px"}}>
                                    {this.state.trendASRData?<DynamicLineChart data={this.state.trendASRData} x={this.props.t("YEAR_X")} y={"ASR"} height={275} isshowlegend={false} linestroke={3} ismarkers={true} isdatalable={true} colors={[this.props.fobj.color ? this.props.fobj.color:"#345fdf"]} />:<></>}
                                </Col>
                            </Row>
                        </Col>

                        

                    </Col>

                </Col>    
            </Col>
            
        </Col>
         );
    }
}

const mapDispatchToProps = dispatch => ({
    setResetState: (payload) => dispatch(logoutAction(payload)),
});

export default withTranslation()(withRouter(connect(null,mapDispatchToProps)(TwoPageFactSheet)));
