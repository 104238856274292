import React from 'react';
import { Col , Button, ButtonGroup} from 'react-bootstrap';

import { submitCollection } from '../../_services/submit.service';
import { submitSets } from '../UiComponents/SubmitSets';
import { alertService } from '../../_services/alert.service';
import './masterData.css';

class MasterData extends React.Component{
    constructor(props){
        super(props);
        this.state = {
           sobj:{currentYear:"",isUseColors:false}
        }
        
    }

    componentDidMount(){
        this.getMAsterData();
    }

    getMAsterData = () =>{
        submitSets(submitCollection.getMAsterData,null).then(res=> {
            if(res.status===true){
                this.setState({sobj:res.extra});
            }
        });
    }

    changeVals = (e,type) =>{
        if(type==="year"){
            let sobj = this.state.sobj;
            sobj.currentYear = e;
            this.setState({sobj:sobj});
        }
        else if(type==="color"){
            let sobj = this.state.sobj;
            sobj.isUseColors = e;
            this.setState({sobj:sobj});
        }
    }

    save = () =>{
        submitSets(submitCollection.UpdateMasterSettings,this.state.sobj).then(res=> {
            if(res.status===true){
                this.getMAsterData()
                alertService.success("Successfully Saved");
            }
            else{
                alertService.error("Error Occurred");
            }
        });
    }

    render(){
        return(
            <>
                <Col xs={4} className="main-container">
                    <h5 className="main-heading">Master Data</h5>
                    <Col xs={12} className="sub-container">
                        <table>
                            <tbody>
                                <tr>
                                    <td width="50%"><label>Current Year</label></td>
                                    <td width="50%"><input className="form-control" type="number" value={this.state.sobj.currentYear} onChange={(event)=>this.changeVals(event.target.value,"year")} /></td>
                                </tr>
                                <tr>
                                    <td width="50%" style={{paddingTop:"10px"}}><label>Use Color</label></td>
                                    <td width="50%" style={{paddingTop:"10px"}}>
                                        <ButtonGroup>
                                            <Button style={{width:"85px"}} variant={this.state.sobj.isUseColors===true?"primary":"secondary"} onClick={(event)=>this.changeVals(true,"color")} size="sm">Yes</Button>
                                            <Button style={{width:"85px"}} variant={this.state.sobj.isUseColors===false?"primary":"secondary"} onClick={(event)=>this.changeVals(false,"color")} size="sm">No</Button>
                                        </ButtonGroup>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                        <Button className="primary-button pull-right" style={{float:"right",marginTop:"10px"}} size="sm" onClick={()=>this.save()} >Save Changes</Button>
                    </Col>
                </Col>
            </>
        )
    }
}

export default MasterData;
