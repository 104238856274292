import React from 'react';
import {  Col, Table, Modal, Button, Form, ButtonGroup } from 'react-bootstrap';

import { submitCollection } from '../../_services/submit.service';
import { submitSets } from '../UiComponents/SubmitSets';
import { alertService } from '../../_services/alert.service';
import './roadmap.css';
import LoadingIcon from  '../../assets/img/loading.gif';
import { X } from 'react-feather';
import { pageLength, getPager } from '../../_services/common.service';

class Roadmap extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            currentPage: 1, showPagination:true,
            isLoading:false,showEditModal:false, description:"",descriptionSin:"",descriptionTam:"", descriptionId: 0,
            mainList:[], selectedObj:{roadMapId:0, year:"", descriptions:[]},
            isNewDesItem:false, showDesAddModal:false, desIndex:-1,
            oriList:[], filtertxt:"",
        }
        
    }

    componentDidMount(){
        this.loadALlRoadmap(1);
    }

    loadALlRoadmap = (cpage) =>{
        this.setState({isLoading:true, mainList:[],showPagination:false })
        submitSets(submitCollection.getAllRoadmap,null).then(res=> {
            if(res.status===true){
                this.setState({mainList:res.extra, oriList:res.extra, isLoading:false});
                this.setPage(cpage);
            }
            else{
                this.setState({mainList:[], oriList:[],  isLoading:false});
            }
        });
    }

    setModalShow = (type) =>{
        this.setState({showEditModal:type});
    }

    setDesModalShow = (type) =>{
        this.setState({showDesAddModal:type});
        this.setModalShow(!type)
    }

    showDesItem = () =>{
        this.setState({isNewDesItem:true});
        this.setDesModalShow(true);
        
    }

    openNew =()=>{
        this.setState({showEditModal:true,selectedObj:{roadMapId:0, year:"", descriptions:[]}});
    }

    clickItem = (obj) => {
        let sobj = JSON.parse(JSON.stringify(obj))
        this.setState({selectedObj:sobj, description:""});
        this.setModalShow(true)
    }

    changeVals = (e,type) =>{
        if(type==="year"){
            let sobj = this.state.selectedObj;
            sobj.year = e;
            this.setState({sobj:sobj});
        }
        else if(type==="des"){
            this.setState({description:e});
        }
        else if(type==="des-s"){
            this.setState({descriptionSin:e});
        }
        else if(type==="des-t"){
            this.setState({descriptionTam:e});
        }
    }

    AddDes = () =>{
        if(this.state.description!=="" && this.state.descriptionSin!=="" && this.state.descriptionTam!==""){
            let sobj = this.state.selectedObj;
            sobj.descriptions.push({descriptionId:0, description:this.state.description, descriptionSin:this.state.descriptionSin,descriptionTam:this.state.descriptionTam});
            this.setState({sobj:sobj, description:"",descriptionSin:"",descriptionTam:""});
            this.setDesModalShow(false);
        }
        else{
            alertService.warn("Add All Descriptions!");
        }
    }

    updateDes = () =>{
        if(this.state.description!=="" && this.state.descriptionSin!=="" && this.state.descriptionTam!==""){
            let sobj = this.state.selectedObj;
            sobj.descriptions[this.state.desIndex] = {descriptionId:this.state.descriptionId, description:this.state.description, descriptionSin:this.state.descriptionSin,descriptionTam:this.state.descriptionTam};
            this.setState({sobj:sobj, description:"",descriptionSin:"",descriptionTam:""});
            this.setDesModalShow(false);
        }
        else{
            alertService.warn("Add All Descriptions!");
        }
    }
    handleClickDesItem = (item, index) =>{
        this.setState({desIndex:index, description:item.description, descriptionSin:item.descriptionSin,descriptionTam:item.descriptionTam, descriptionId: item.descriptionId, isNewDesItem:false});
        this.setDesModalShow(true);
    }

    deleteDes = (i) =>{
        let sobj = this.state.selectedObj;
        //sobj.descriptions.splice(i,1)
        sobj.descriptions[i].isDelete = true;
        this.setState({sobj:sobj, description:""});
    }

    
    save = () =>{
        if(this.state.selectedObj.year===""){
            alertService.warn("Enter a Year!");
        }
        else{
            submitSets(submitCollection.updateRoadmap,this.state.selectedObj).then(res=> {
                if(res.status===true){
                    this.loadALlRoadmap(this.state.currentPage);
                    this.setModalShow(false);
                    alertService.success("Successfully Saved");
                }
                else{
                    alertService.error("Error Occurred");
                }
            });
        }
    }

    deleteRoadmapItem = (item) =>{
        if(window.confirm("Are you sure to delete this?")){
            submitSets(submitCollection.deleteRoadmap,item).then(res=> {
                if(res.status===true){
                    this.loadALlRoadmap(1);
                    alertService.success("Successfully Deleted");
                }
                else{
                    alertService.error("Error Occurred");
                }
            });
        }
    }

    handleFilterTxt = e => {
        var tarr = [];
        var mlist = this.state.oriList;
        var filterval = e.target.value.toLowerCase();
        this.setState({ filtertxt: filterval, showPagination:false });

        //console.log(filterval);
        if (filterval !== "") {
            for (var i = 0; i < mlist.length; i++) {
                if (mlist[i].year.toLowerCase().indexOf(filterval) > -1) {
                    tarr.push(mlist[i]);
                }
            }
            this.setState({ mainList: tarr });
        }
        else{
            this.setPage(1);
        }
        
    }


    setPage = (cpage) => {
        var citems = JSON.parse(JSON.stringify(this.state.oriList));
        var pager = getPager(citems.length, cpage, pageLength);
        // check page isn't out of range
        if (cpage < 1 || cpage > pager.totalPages) {
            return;
        }
        var sspage = (pager.currentPage === 1 ? 0 : ((pager.currentPage - 1) * pageLength));
        var sepage = (pager.currentPage * pageLength);
        this.setState({
            mainList: citems.slice(sspage, sepage),
            currentPage: pager.currentPage,
            totalPages: pager.totalPages,
        });

        this.setState({showPagination:(citems.length>0 ? true:false)});
    }
    

    render(){
        return(
            <>
                <Col xs={6} className="main-container">
                    <Col xs={12}>
                        <h5 className="main-heading">
                            Roadmap
                            <ul className="top-right-content list-inline">
                                <li className="list-inline-item"><Button variant="primary" size="sm" onClick={()=>this.openNew()}>Add New</Button></li>
                                <li className="list-inline-item"><input className="form-control find-txt" value={this.state.filtertxt} onChange={this.handleFilterTxt} placeholder="Find" /></li>
                            </ul>
                        </h5>
                        <Table bordered hover size="sm" className="main-table">
                            <thead>
                                <tr>
                                <th width="200px">Year</th>
                                <th width="200px">No. of Descriptions</th>
                                <th width="40px"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className={this.state.isLoading===true?"":"d-none"}><td colSpan="10" align="center" style={{backgroundColor:"#FFF"}}><img alt="" src={LoadingIcon} /></td></tr>
                                {
                                    this.state.mainList.map((item, i) =>{
                                        return(
                                            <tr key={i}>
                                                <td  onClick={()=>this.clickItem(item)}>{item.year}</td>
                                                <td  onClick={()=>this.clickItem(item)} align="center">{item.descriptions.length}</td>
                                                <td align="center"><span className="delete" onClick={()=>this.deleteRoadmapItem(item)}><X size="14" /></span></td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                        <Col sm={12} className={this.state.showPagination===true?"div-pagination":"div-pagination d-none" }>
                            <ButtonGroup aria-label="Basic example">
                                <Button onClick={() => { this.setPage(1) }} variant="default" size="sm">First</Button>
                                <Button onClick={() => { this.setPage((this.state.currentPage - 1)) }} variant="default" size="sm">Prev</Button>
                                <Button variant="default" size="sm" disabled id="btncurpageno">Page {this.state.currentPage}</Button>
                                <Button onClick={() => { this.setPage((this.state.currentPage + 1)) }} variant="default" size="sm">Next</Button>
                                <Button onClick={() => { this.setPage(this.state.totalPages) }} variant="default" size="sm">Last</Button>

                            </ButtonGroup>
                        </Col>
                    </Col>
                    
                    </Col>

                <Modal animation={false} show={this.state.showEditModal} onHide={() => this.setModalShow(false)}>
                    <Modal.Header>
                        <Modal.Title>{this.state.selectedObj.roadMapId>0?"Edit":"Add"} Roadmap Item</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group style={{display:"flex"}}>
                            <Form.Label style={{fontSize:"16px", paddingTop:"5px", width:"70px"}}>Year</Form.Label>
                            <input className="form-control year-txt" type="number" size="sm" value={this.state.selectedObj.year} onChange={(event)=>this.changeVals(event.target.value,"year")} />
                        </Form.Group>

                        <Col className="main-des-col">
                            <h6>Added Descriptions <Button className="float-right" variant="primary" size="sm" onClick={()=>this.showDesItem()}>Add New</Button></h6>
                            {
                                this.state.selectedObj.descriptions.map((item, i) =>{
                                    return(
                                        <Col key={i} className={item.isDelete === true?"des-item d-none":"des-item"}>
                                            <span onClick={()=>this.deleteDes(i)}><X size={13}/></span>
                                            <Col className="desclickitem" onClick={()=>this.handleClickDesItem(item, i)}>
                                                <Col className="lang-item">
                                                    <label>English</label>
                                                    <h5>{item.description!==null && item.description!==""?item.description:"-"}</h5>
                                                </Col>
                                                <Col className="lang-item">
                                                    <label>Sinhala</label>
                                                    <h5>{item.descriptionSin!==null && item.descriptionSin!==""?item.descriptionSin:"-"}</h5>
                                                </Col>
                                                <Col className="lang-item">
                                                    <label>Tamil</label>
                                                    <h5>{item.descriptionTam!==null && item.descriptionTam!==""?item.descriptionTam:"-"}</h5>
                                                </Col>
                                            </Col>
                                        </Col>
                                    )
                                })
                            }
                        </Col>
                        
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" size="sm" onClick={() => this.setModalShow(false)}>Close</Button>
                        <Button className="primary-button" size="sm" onClick={() => this.save()}>Save Changes</Button>
                    </Modal.Footer>
                </Modal>

                <Modal animation={false} show={this.state.showDesAddModal} onHide={() => this.setDesModalShow(false)}>
                    <Modal.Header>
                        <Modal.Title>Add Description</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    
                        <Form.Group>
                            <Form.Label>English Description</Form.Label>
                            <textarea className="textarea1"  value={this.state.description!==null?this.state.description:""} onChange={(event)=>this.changeVals(event.target.value,"des")} ></textarea>
                            
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Sinhala Description</Form.Label>
                            <textarea className="textarea1" value={this.state.descriptionSin!==null?this.state.descriptionSin:""} onChange={(event)=>this.changeVals(event.target.value,"des-s")} ></textarea>
                            
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Tamil Description</Form.Label>
                            <textarea className="textarea1"  value={this.state.descriptionTam!==null?this.state.descriptionTam:""} onChange={(event)=>this.changeVals(event.target.value,"des-t")} ></textarea>
                        </Form.Group>
                        
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" size="sm" onClick={() => this.setDesModalShow(false)}>Close</Button>
                        <Button className={this.state.isNewDesItem===true?"primary-button":"d-none primary-button"} size="sm" onClick={()=>this.AddDes()}>Add</Button>
                        <Button className={this.state.isNewDesItem===false?"primary-button":"d-none primary-button"} size="sm" onClick={()=>this.updateDes()}>Update</Button>
                    </Modal.Footer>
                </Modal>

            </>
        )
    }
}

export default Roadmap;
