import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Col, Button, Row, ButtonGroup } from 'react-bootstrap';

/* import DynamicBarChart from '../charttypes/barchart';
import DynamicPieChart from '../charttypes/piechart'; */
import DynamicLineChart from '../charttypes/linechart';
import DynamicDonutChart from '../charttypes/donut';
import html2pdf from 'html2pdf.js';

import { submitCollection } from '../../_services/submit.service';
import { submitSets } from '../UiComponents/SubmitSets';
//import { alertService } from '../../_services/alert.service';
//import { hexToRGB } from '../../_services/common.service';

import { logoutAction } from '../../actions/auth/login_action';

//import i18n from "i18next";
import { withTranslation } from "react-i18next";
import "../../_translations/i18n";
import "./singlePageFactSheetBothSex.css";

import nccplogo from '../../assets/img/logo512.png';
import maleicon from '../../assets/img/male-icon.png';
import femaleicon from '../../assets/img/female-icon.png';
import foldericon from '../../assets/img/slcr-logo.png';
import worldicon from '../../assets/img/maps/world.png';
import asiaicon from '../../assets/img/maps/asia.png';
import slicon from '../../assets/img/maps/srilanka.png';

class SinglePageFactSheetBothSex extends React.Component{
    _isMounted = false;

    constructor(props){
        super(props);
        this.state = {
            genderType:"Female", 
            selectedchartidx: 0, selectedchartidx2: 0, 
            MaleCommonCancersDonutChartData:null,
            FemaleCommonCancersDonutChartData:null,

            CommonData:{male:null,female:null, both:null},

            trendASRlineChartData:{categories:[],series:[{name:"",data:[]}]},
            ageSpecIncLineChartData:{categories:[],series:[{name:"",data:[]}]},
            
            samplecolors: ['#2a52be','#de6fa1','#da4040','#486090', '#00e699', '#a035ea','#35ead4', '#FF6600', '#ebc55e', '#409caf'],
            minyear: "-", maxyear: "-",
        }

        this.signinBtnElement = React.createRef();
    }
      
    componentDidMount(){
        // if(this.props.signedobj===null || this.props.signedobj.signinDetails.signinDetails===false){
        //     this.props.handleSignObj({signinDetails:false, username:""});
        //     this.props.history.push("/");
        // }

        
        this.getMostCommonCancers("Male");
        this.getMostCommonCancers("Female");
        
        //console.log(this.props.fobj);
        this.getTrendOfASR();
        this.getAgeSpecIncidents();
        this.getCommonCounts();
    }

    componentWillUnmount(){
        this._isMounted = false;
        
    }

    getMostCommonCancers = (gender) =>{
        
        let sobj = {selCancerSites:[{id: 0,name: "All Sites",icdcode: "All Sites"}], gender: gender, totalNoOfResults:10,  maxYear:this.props.fobj.maxYear};
        submitSets(submitCollection.getProprtionOfLeadingCancers,sobj).then(res=> {
            if(res.status===true && res.extra.length>0){
                let data = res.extra;
                let chartData = {categories:[],series:[],colors:[]};
                var cursiteidx = undefined;
                for (let i = 0; i < data.length; i++) {
                    chartData.categories.push(data[i].cancerSiteName);
                    chartData.series.push(parseFloat(data[i].count));
                    chartData.colors.push(data[i].cancerSiteColor);

                    if(this.props.fobj.cancerSiteId3 === data[i].cancerSiteId){
                        cursiteidx = i;
                    }
                }
                if(gender==="Male"){
                    this.setState({ MaleCommonCancersDonutChartData:chartData, selectedchartidx: cursiteidx });
                }
                else if(gender==="Female"){
                    this.setState({ FemaleCommonCancersDonutChartData:chartData, selectedchartidx2: cursiteidx });
                }
            }
        });
        
    }

    getTrendOfASR = () =>{
        let sobj = {selCancerSites:[{id: this.props.fobj.cancerSiteId ,name: "",icdcode: ""}], basedOnType: "CANCER_SITE_GENDERWISE", gender: this.props.fobj.gender, minYear:this.props.fobj.minYear, maxYear:this.props.fobj.maxYear};

        submitSets(submitCollection.getAgeStandardizedIncidenceRates,sobj).then(res=> {
            if(res.status===true && res.extra.length>0){
                let data = res.extra;
                let lineChartData = {categories:[], series:[{name:"", data:[]}]};

                var minyear = ""; var maxyear = "";
                let marray = [];let farray = [];
                for (let i = 0; i < data.length; i++) {
                    if(i === 0){
                        minyear = data[i].year;
                    } else if(i === (data.length - 1)){
                        maxyear = data[i].year;
                    }

                    lineChartData.categories.push(data[i].year);
                    for (let x = 0; x < data[i].values.length; x++) {
                        let yitem = data[i].values[x];
                        if(yitem.gender==="Male"){
                           marray.push(yitem.count);
                        }
                        else if (yitem.gender==="Female"){
                           farray.push(yitem.count);
                        }
                    }
                }
                lineChartData.series = [];
                lineChartData.series.push({name:"Male",data:marray});
                lineChartData.series.push({name:"Female",data:farray});

                this.setState({trendASRlineChartData:lineChartData, minyear:minyear, maxyear:maxyear });
            }
        });
    }

    getAgeSpecIncidents = () =>{
        let sobj = {cancerSite:this.props.fobj.cancerSiteId2,  maxYear:this.props.fobj.maxYear};

        submitSets(submitCollection.getOverallAgeSpecificIncidenceRateOfMaleAndFemale,sobj).then(res=> {
            if(res.status===true && res.extra.length>0){
                let data = res.extra;
                let lineChartData = {categories:[], series:[{name:"", data:[]}]};

                let marray = [];let farray = [];
                for (let i = 0; i < data.length; i++) {
                    for (let x = 0; x < data[i].values.length; x++) {
                        if(i===0){
                            lineChartData.categories.push(data[i].values[x].ageRange);
                        }
                        let yitem = data[i];
                        if(yitem.gender==="Male"){
                            marray.push(data[i].values[x].count);
                        }
                        else if (yitem.gender==="Female"){
                            farray.push(data[i].values[x].count);
                        }
                    }
                }
                lineChartData.series = [];
                lineChartData.series.push({name:"Male",data:marray});
                lineChartData.series.push({name:"Female",data:farray});

                this.setState({ageSpecIncLineChartData:lineChartData });
            }
        });
    }

    getCommonCounts = () =>{
        let sobj = {cancerSiteGroup:this.props.fobj.cancerSiteGroupId ,year:this.props.fobj.year, orgUnit:this.props.fobj.orgUnit};
        submitSets(submitCollection.getCommonCounts,sobj).then(res=> {
            if(res.status===true && res.extra.length>0){
                let CommonData = {male:null,female:null, both:null};
                for (let i = 0; i < res.extra.length; i++) {
                   if(res.extra[i].gender==="Male"){
                        CommonData.male = res.extra[i].commonData;
                   }
                   else if(res.extra[i].gender==="Female"){
                        CommonData.female = res.extra[i].commonData;
                   }
                   else if(res.extra[i].gender==="Both"){
                        CommonData.both = res.extra[i].commonData;
                    }
                }
                this.setState({CommonData:CommonData});
                //console.log(this.state.CommonData);
            }
        });
    }

    export = () =>{
        var element = document.getElementById('generating-section-single');
        var pagebreak = {mode:"avoid-all"};
        var opt = {
            margin:       0,
            filename:     this.props.fobj.sheetName.replace(/ /g,'')+"_"+this.props.language+'.pdf',
            image:        { type: 'jpeg', quality: 0.98 },
            html2canvas:  { scale: 5, logging: true, dpi: 192, letterRendering: true },
            jsPDF:        { unit: 'mm', format: 'a4', orientation: 'portrait', compress: true, precision: 8 },
            pagebreak: pagebreak
        };

        html2pdf().set(opt).from(element).toPdf().get('pdf').then(function (pdf) {
            var totalPages = pdf.internal.getNumberOfPages(); 
            var cdate = new Date();
            //print current pdf width & height to console
            
            for (var i = 1; i <= totalPages; i++) {
              pdf.setPage(i);
              pdf.setFontSize(10);
              pdf.setTextColor("#d04571");
              pdf.text('National Cancer Registry : All rights reserved '+cdate.getFullYear(), 100, 290,'center');
            } 
            }).save();
    }

    render(){
        return (
        <Col className="main-container-col" style={{width:"815px"}}>
            
            <Col style={{marginBottom:"5px"}}>
                <ButtonGroup aria-label="Basic example" size="sm">
                    <Button onClick={()=>this.props.changeLanguage("en")} variant={this.props.language==="en"?"primary":"secondary"}>English</Button>
                    <Button onClick={()=>this.props.changeLanguage("sin")} variant={this.props.language==="sin"?"primary":"secondary"}>Sinhala</Button>
                    <Button onClick={()=>this.props.changeLanguage("ta")} variant={this.props.language==="ta"?"primary":"secondary"}>Tamil</Button>
                </ButtonGroup>
            
                <Col style={{position:"relative", float:"right"}}>
                    <Button variant="success" size="sm" onClick={()=>this.export()}>Download</Button>{" "}
                    <Button variant="secondary" size="sm" onClick={()=>this.props.changeViewType("mainView")}>Back</Button>
                </Col>
            </Col>
            
            <Col xs={12} style={{width:"815px",border:"1px solid #ccc",padding:"10px"}}>
                <Col xs={12} className="generating-section-single both-view" id="generating-section-single">
                    {/* <Col className="overlay-bg" style={{background:"linear-gradient(to right, transparent, "+hexToRGB("#00BDAA",0.3)+")"}}></Col> */}
                    <Col className={"gs-title"+(this.props.language!=="en"?" sub-lang":"")}>
                        <Row style={{paddingLeft:"10px"}}>
                            <Col xs={1}>
                                <img alt="" src={foldericon} width="55px" id="mainLogo" style={{marginTop:"-4px"}} />
                            </Col>
                            <Col xs={11}>
                                <h6 className="main-heading-single ">{this.props.t("NCCP_SUB")}</h6>
                                <h5 className="sub-heading-single">{this.props.t("NCCP")}</h5>
                                <img src={nccplogo} className="fsheet-logo" alt=""/>
                            </Col>
                        </Row>
                    </Col>

                    <Col className={"chart-title-col"+(this.props.language!=="en"?" sub-lang":"")} style={this.props.fobj&&this.props.fobj.color?{background:this.props.fobj.color}:{}}>
                    {this.props.language==="en"?this.props.fobj.cancerSiteName:this.props.language==="sin"?this.props.fobj.cancerSiteSinName:this.props.language==="ta"?this.props.fobj.cancerSiteTamName:""} - {this.props.fobj.year}
                        <div className="year-view"><span>{this.props.t("FACT_SHEET")}</span></div>
                    </Col>

                    <Col xs={12} className={"chart-containts"+(this.props.language!=="en"?" sub-lang":"")}>
                        <Row>
                            <Col xs={6} style={{paddingRight:"3px"}}>
                                <Col className="chart-sub-card" style={{position:"relative",height:"265px"}}>
                                    <h6 className="sub-title-single" style={{color:(this.props.fobj&&this.props.fobj.color?this.props.fobj.color:"#111D5E"),fontSize:(this.props.language!=="en"?"12px":"16px")}}>{this.props.t("MOST_COMMON_CANCERS_FEMALE")} {this.props.fobj.year}</h6>
                                    <Col xs={10} style={{marginLeft:"-45px",marginTop:"-20px"}}>
                                        {this.state.FemaleCommonCancersDonutChartData?<DynamicDonutChart data={this.state.FemaleCommonCancersDonutChartData} height={170} expandindex={this.state.selectedchartidx2} isshowlegend={false} />:<></>}
                                    </Col>
                                    <div className="chartview-legend" style={{top:"50px"}}>
                                        <ul>
                                            {this.state.FemaleCommonCancersDonutChartData?this.state.FemaleCommonCancersDonutChartData.categories.map((xitem,xidx) => {
                                                return <li key={xidx} style={{fontSize:"11px"}}><div style={{background: this.state.FemaleCommonCancersDonutChartData.colors[xidx]}}></div> {xitem}</li>;
                                            }):<></>}
                                        </ul>
                                    </div>
                                </Col>
                            </Col>
                            <Col xs={6} style={{paddingLeft:"3px"}}>
                                <Col className="chart-sub-card" style={{position:"relative",height:"265px"}}>
                                    <h6 className="sub-title-single" style={{color:(this.props.fobj&&this.props.fobj.color?this.props.fobj.color:"#111D5E"),fontSize:(this.props.language!=="en"?"12px":"16px")}}>{this.props.t("MOST_COMMON_CANCERS_MALE")} {this.props.fobj.year}</h6>
                                    <Col xs={10} style={{marginLeft:"-45px",marginTop:"-20px"}}>
                                        {this.state.MaleCommonCancersDonutChartData?<DynamicDonutChart data={this.state.MaleCommonCancersDonutChartData} height={170} expandindex={this.state.selectedchartidx} isshowlegend={false} />:<></>}
                                    </Col>
                                    <div className="chartview-legend" style={{top:"50px"}}>
                                        <ul>
                                            {this.state.MaleCommonCancersDonutChartData?this.state.MaleCommonCancersDonutChartData.categories.map((xitem,xidx) => {
                                                return <li key={xidx} style={{fontSize:"11px"}}><div style={{background: this.state.MaleCommonCancersDonutChartData.colors[xidx]}}></div> {xitem}</li>;
                                            }):<></>}
                                        </ul>
                                    </div>
                                </Col>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={7} style={{paddingRight:"0px"}}>
                                <Col className="chart-sub-card">
                                    <h6 className="sub-title-single" style={{color:(this.props.fobj&&this.props.fobj.color?this.props.fobj.color:"#111D5E")}}>{this.props.t("SINGLE_T1_"+this.props.fobj.cancerSiteId)} {this.state.minyear}&nbsp;-&nbsp;{this.state.maxyear}</h6>
                                    <Col className={"linechart-main "+(this.state.genderType==="Both"?"both-view":"")} style={{height:"240px"}}>
                                        <DynamicLineChart data={this.state.trendASRlineChartData} x={this.props.t("YEAR_X")} y={this.props.t("ASR_X")} height={240} colors={["#0E49B5","#F21170"]} isshowlegend={false} />
                                    </Col>
                                    <Col xs={2} className="chart-containt-labels">
                                        <Row>
                                            <Col xs={6}><div className="label-color mle"></div> {this.props.t("Male")}</Col>
                                            <Col xs={6}><div className="label-color fmle"></div> {this.props.t("Female")}</Col>
                                        </Row>
                                    </Col>
                                </Col>

                                <Col className="chart-sub-card">
                                    <h6 className="sub-title-single" style={{color:(this.props.fobj&&this.props.fobj.color?this.props.fobj.color:"#111D5E")}}>{this.props.t("SINGLE_T2_"+this.props.fobj.cancerSiteId)} {this.props.fobj.year}</h6>
                                    <Col className={"linechart-main "+(this.state.genderType==="Both"?"both-view":"")} style={{height:"235px"}}>
                                        <DynamicLineChart data={this.state.ageSpecIncLineChartData} x={this.props.t("AGE_RANGE_X")}  y={this.props.t("ASR_X")}  height={240} colors={["#0E49B5","#F21170"]} isshowlegend={false} xfontsize={"11px"} />
                                    </Col>
                                    <Col xs={2} className="chart-containt-labels">
                                        <Row>
                                            <Col xs={6}><div className="label-color mle"></div> {this.props.t("Male")}</Col>
                                            <Col xs={6}><div className="label-color fmle"></div> {this.props.t("Female")}</Col>
                                        </Row>
                                    </Col>
                                </Col>

                            </Col>
                            <Col xs={5} style={{paddingRight:"15px",paddingLeft:"5px"}}>
                                <Col xs={12} className="chart-sub-card" style={{paddingBottom:"5px"}}>
                                    <Col xs={12} className={"summary-section "+(this.props.fobj.gender==="Both"?"both-view":"")}>
                                        <h6 style={{textAlign:"center"}} className="sub-content-header fmale">{this.props.t("Female")}</h6>
                                        <Row style={{padding: "0px 15px"}}>
                                            <Col xs={12} className="summary-item world">
                                                <Row>
                                                    <Col xs={2} className="img-col"><img alt="" src={worldicon} /></Col>
                                                    <Col xs={6} className="data-col">
                                                        <h6>ASR: {this.props.t("WORLD")}</h6>
                                                        
                                                        {/* <div className="percentage-line-main">
                                                            <div className="percentage" style={{width:"47.1%"}}></div>
                                                        </div> */}
                                                    </Col>    
                                                    <Col xs={4} className="percentage-col">
                                                        <Col xs={12} className="percentage-sub" style={{width:"78%"}}>
                                                            <h5>{this.state.CommonData.female!==null?this.state.CommonData.female.asrGS:"0"}</h5>
                                                        </Col>
                                                    </Col>
                                                </Row>
                                            </Col>

                                            <Col xs={12} className="summary-item asia">
                                                <Row>
                                                    <Col xs={2} className="img-col"><img alt="" src={asiaicon} /></Col>
                                                    <Col xs={6} className="data-col">
                                                        <h6>ASR: {this.props.t("SOUTH_ASIA")}</h6>
                                                        {/* <div className="percentage-line-main">
                                                            <div className="percentage" style={{width:"41.1%"}}></div>
                                                        </div> */}
                                                    </Col>    
                                                    <Col xs={4} className="percentage-col">
                                                        <Col xs={12} className="percentage-sub" style={{width:"58%"}}>
                                                            <h5>{this.state.CommonData.female!==null?this.state.CommonData.female.asrSEA:"0"}</h5>
                                                        </Col>
                                                    </Col>
                                                </Row>
                                            </Col>

                                            <Col xs={12} className="summary-item sl">
                                                <Row>
                                                    <Col xs={2} className="img-col"><img alt="" src={slicon} /></Col>
                                                    <Col xs={6} className="data-col">
                                                        <h6>ASR: {this.props.t("SRI_LANKA")}</h6>
                                                        {/* <div className="percentage-line-main">
                                                            <div className="percentage" style={{width:"33.5%"}}></div>
                                                        </div> */}
                                                    </Col> 
                                                    <Col xs={4} className="percentage-col">
                                                        <Col xs={12} className="percentage-sub" style={{width:"38%"}}>
                                                            <h5>{this.state.CommonData.female!==null?this.state.CommonData.female.asr:"0"}</h5>
                                                        </Col>
                                                    </Col>   
                                                </Row>
                                            </Col>    
                                        </Row>
                                    </Col>
                                </Col>
                                <Col xs={12} className="chart-sub-card" style={{paddingBottom:"5px"}}>
                                    <Col xs={12} className={"summary-section "+(this.props.fobj.gender==="Both"?"both-view":"")}>
                                        <h6 style={{textAlign:"center"}} className="sub-content-header male">{this.props.t("Male")}</h6>
                                        <Row style={{padding: "0px 15px"}}>
                                            <Col xs={12} className="summary-item world">
                                                <Row>
                                                    <Col xs={2} className="img-col"><img alt="" src={worldicon} /></Col>
                                                    <Col xs={6} className="data-col">
                                                        <h6>ASR: {this.props.t("WORLD")}</h6>
                                                        {/* <div className="percentage-line-main">
                                                            <div className="percentage" style={{width:"47.1%"}}></div>
                                                        </div> */}
                                                    </Col>  
                                                    <Col xs={4} className="percentage-col">
                                                        <Col xs={12} className="percentage-sub" style={{width:"78%"}}>
                                                            <h5>{this.state.CommonData.male!==null?this.state.CommonData.male.asrGS:"0"}</h5>
                                                        </Col>
                                                    </Col>  
                                                </Row>
                                            </Col>

                                            <Col xs={12} className="summary-item asia">
                                                <Row>
                                                    <Col xs={2} className="img-col"><img alt="" src={asiaicon} /></Col>
                                                    <Col xs={6} className="data-col">
                                                        <h6>ASR: {this.props.t("SOUTH_ASIA")}</h6>
                                                        {/* <div className="percentage-line-main">
                                                            <div className="percentage" style={{width:"41.1%"}}></div>
                                                        </div> */}
                                                    </Col>    
                                                    <Col xs={4} className="percentage-col">
                                                        <Col xs={12} className="percentage-sub" style={{width:"58%"}}>
                                                            <h5>{this.state.CommonData.male!==null?this.state.CommonData.male.asrSEA:"0"}</h5>
                                                        </Col>
                                                    </Col>
                                                </Row>
                                            </Col>

                                            <Col xs={12} className="summary-item sl">
                                                <Row>
                                                    <Col xs={2} className="img-col"><img alt="" src={slicon} /></Col>
                                                    <Col xs={6} className="data-col">
                                                        <h6>ASR: {this.props.t("SRI_LANKA")}</h6>
                                                        {/* <div className="percentage-line-main">
                                                            <div className="percentage" style={{width:"33.5%"}}></div>
                                                        </div> */}
                                                    </Col>    
                                                    <Col xs={4} className="percentage-col">
                                                        <Col xs={12} className="percentage-sub" style={{width:"38%"}}>
                                                            <h5>{this.state.CommonData.male!==null?this.state.CommonData.male.asr:"0"}</h5>
                                                        </Col>
                                                    </Col>
                                                </Row>
                                            </Col>    
                                        </Row>
                                        
                                    </Col>    
                                </Col>
                                
                                <Col className="chart-sub-card">
                                    <h6 className="sub-title-single" style={{color:"#fff",background:(this.props.fobj&&this.props.fobj.color?this.props.fobj.color:"#d24572"),textAlign:"center",padding:"6px 0px",height:"auto",borderRadius:"4px"}}>{this.props.t("NO_OF_NEW_CASES_IN")} {this.props.fobj.year}</h6>
                                    <Row style={{marginTop:"10px",}}>
                                        <Col xs={6} style={{padding:"0px 10px",paddingRight:"5px"}}>
                                            <Col className="gender-view fmale">
                                                <h6 className="text-center">{this.props.t("Female")}</h6>
                                                <img src={femaleicon} width="70px" alt="female"/>
                                                <h5 className="text-center">{this.state.CommonData.female!==null?parseInt(this.state.CommonData.female.count):"0"}</h5>
                                            </Col>
                                        </Col>
                                        <Col xs={6} style={{padding:"0px 10px",paddingLeft:"5px"}}>
                                            <Col className="gender-view">
                                                <h6 className="text-center">{this.props.t("Male")}</h6>
                                                <img src={maleicon} width="70px" alt="male"/>
                                                <h5 className="text-center">{this.state.CommonData.male!==null?parseInt(this.state.CommonData.male.count):"0"}</h5>    
                                            </Col>
                                        </Col>
                                    </Row>
                                </Col>

                            </Col>
                        </Row>

                    </Col>
                </Col>
            </Col>
            
           
        </Col>
         );
    }
}

const mapDispatchToProps = dispatch => ({
    setResetState: (payload) => dispatch(logoutAction(payload)),
});

export default withTranslation()(withRouter(connect(null,mapDispatchToProps)(SinglePageFactSheetBothSex)));
