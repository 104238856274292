export const TRANSLATIONS_TA = {
    NCCP:"தேசிய புற்றுநோய் கட்டுப்பாட்டு திட்டம்",
    NCCP_SUB:"இலங்கையின் தேசிய புற்றுநோய் பதிவு",
    CANCER: "புற்றுநோய்",
    CANCER_SITE:"புற்றுநோய்",
    FACT_SHEET:"உண்மை தாள்",
    TO: "-",

    Male:"ஆண்",
    Female:"பெண்",
    Both:"இரண்டு பாலினங்களும்",

    WORLD:"உலகம்",
    SOUTH_ASIA:"தெற்கு ஆசியா",
    SRI_LANKA:"இலங்கை",
    DISTRICT: "மாவட்டம்",

    NO_OF_NEW_CASES_IN:"புதிய சம்பவங்களின் எண்ணிக்கை",
    YEAR:"ஆண்டு",
    AGE_GROUP:"வயது குழு",
    RATE:"Rate",
    RATE_PER_POPULATION:"Per 100,000 population",
    MOST_COMMON_CANCERS_FEMALE:"பெண்களில் மிகவும் பொதுவான புற்றுநோய்கள்",
    MOST_COMMON_CANCERS_MALE:"ஆண்களில் மிகவும் பொதுவான புற்றுநோய்கள்",
    CASES:"நிகழ்வு",
    Colombo:"கொழும்பு",

    INCIDENCE_MALE:"நிகழ்வு ஆண்",
    INCIDENCE_FEMALE:"நிகழ்வு பெண்",
    MORTALITY_MALE:"இறப்பு ஆண்",
    MORTALITY_FEMALE:"இறப்பு பெண்",

    TREND_MALE_ASR:"இலங்கையில் ஆண் புற்றுநோய் ASR விகிதங்களின் போக்கு",
    TENDS_MALE_CR:"இலங்கையில் ஆண் புற்றுநோய் CR விகிதங்களின் போக்கு",

    TREND_FEMALE_ASR:"இலங்கையில் பெண் புற்றுநோய் ASR விகிதங்களின் போக்கு",
    TENDS_FEMALE_CR:"இலங்கையில் பெண் புற்றுநோய் CR விகிதங்களின் போக்கு",

    POP_TITLE1 :"இது ஒரு சோதனை தலைப்பு",
    POP_TITLE2:"இது ஒரு சோதனை தலைப்பு",
    POP_TITLE3: "நிகழ்வு/இறப்பு விகிதத்தின் ஒப்பீடு",

    SINGLE_T1_CervixASR:"இது ஒரு சோதனை தலைப்பு",
    SINGLE_T2_CervixASR:"இது ஒரு சோதனை தலைப்பு",

    SINGLE_T1_BreastASR:"இது ஒரு சோதனை தலைப்பு",
    SINGLE_T2_BreastASR:"இது ஒரு சோதனை தலைப்பு",

    SINGLE_T1_LTMASR:"இது ஒரு சோதனை தலைப்பு",
    SINGLE_T2_LTMASR:"இது ஒரு சோதனை தலைப்பு",

    SINGLE_T1_LungASR:"இது ஒரு சோதனை தலைப்பு",
    SINGLE_T2_LungASR:"இது ஒரு சோதனை தலைப்பு",

    SINGLE_T1_ThyroidASR:"இது ஒரு சோதனை தலைப்பு",
    SINGLE_T2_ThyroidASR:"இது ஒரு சோதனை தலைப்பு",

    SINGLE_T1_ColonRectumASR:"இது ஒரு சோதனை தலைப்பு",
    SINGLE_T2_ColonRectumASR:"இது ஒரு சோதனை தலைப்பு",

    SINGLE_T1_OesophagusASR:"இது ஒரு சோதனை தலைப்பு",
    SINGLE_T2_OesophagusASR:"இது ஒரு சோதனை தலைப்பு",

    AGE_RANGE_MOST_COMMON_FEMALE_0_19:"உள்ள 0-19 வயதுக்குட்பட்ட பெண்களின் முன்னணி புற்றுநோய்கள்",
    AGE_RANGE_MOST_COMMON_FEMALE_20_59:"உள்ள 20-59 வயதுக்குட்பட்ட பெண்களின் புற்றுநோய்கள்",
    AGE_RANGE_MOST_COMMON_FEMALE_60_PLUS:"உள்ள 60 வயதிற்கு மேற்பட்ட வயதினரால் முன்னணி புற்றுநோய்கள்",
    AGE_SPECIFIC_CANCER_INCI_FEMALE:"இலங்கையில் வயது குறிப்பிட்ட புற்றுநோய் நிகழ்வு விகிதங்கள்",

    AGE_RANGE_MOST_COMMON_MALE_0_19:"உள்ள 0-19 வயதுக்குட்பட்ட பெண்களின் முன்னணி புற்றுநோய்கள்",
    AGE_RANGE_MOST_COMMON_MALE_20_59:"உள்ள 20-59 வயதுக்குட்பட்ட பெண்களின் புற்றுநோய்கள்",
    AGE_RANGE_MOST_COMMON_MALE_60_PLUS:"உள்ள 60 வயதிற்கு மேற்பட்ட வயதினரால் முன்னணி புற்றுநோய்கள்",
    AGE_SPECIFIC_CANCER_INCI_MALE:"இலங்கையில் வயது குறிப்பிட்ட புற்றுநோய் நிகழ்வு விகிதங்கள்",

    OVB_ASR_BOTH:"இது ஒரு சோதனை ASR",
    OVB_CR_BOTH:"இது ஒரு சோதனை CR",
    OVB_CAN_INC_AGE:"இது ஒரு சோதனை",
    OVB_TOP10_CAN:"இது 10 ஒரு சோதனை",
    OVB_ASR_PER_SEX_TOP10:"ASR இது ஒரு சோதனை",

    PED_NEW_0_19_CANCERS:"இது ஒரு சோதனை",
    PED_CRUDE_INC_RATE_CANCERS:"இது ஒரு 0-19 சோதனை ",
    PED_AGE_STAN_INC_RATE_CANCERS:"இது  சோதனை 0-19 ஒரு",
    PED_AGE_SPEC_INC_RATE_CANCERS_0_4:"இது ஒரு  0-4 சோதனை",
    PED_AGE_SPEC_INC_RATE_CANCERS_5_9:"இது  சோதனை 5-9 ஒரு",
    PED_AGE_SPEC_INC_RATE_CANCERS_10_14:"இது ஒரு 10-14 சோதனை",
    PED_AGE_SPEC_INC_RATE_CANCERS_15_19:"இது  சோதனை 15-19 ஒரு",
    PED_DIST_CANCERS_MALE:"இது ஒரு சோதனை சோதனை",
    PED_DIST_CANCERS_FEMALE:"இது ஒரு சோதனை சோதனை",

    //chart x, y axises
    //X
    CRUDE_RATE_X:"Crude Rate / 100,000 Pop.",
    ASR_X:"ASR / 100,000 Pop",
    AGE_SPEC_RATE_X:"Age Specific rate / 100,000 Pop.",
    AGE_RANGE_X:"Age Range",
    YEAR_X:"Year",
    AGE_GROUP_X:"Age Group",

    //Y
    CANCER_SITES_Y:"Cancer Sites",
    AGE_RANGE_Y:"Age Range",
    INCI_RATE_Y:"Incidence Rate",
    CRUDE_RATE_Y:"Crude Rate",
    ASR_Y:"ASR",
    INC_RATE_PER:"Incidence rate per 100,000 pop"
};