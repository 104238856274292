import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Col, Button, Row, ButtonGroup } from 'react-bootstrap';
//import Pdf from "react-to-pdf";

/* import DynamicBarChart from '../charttypes/barchart';
import DynamicPieChart from '../charttypes/piechart'; */
import DynamicLineChart from '../charttypes/linechart';
import DynamicDonutChart from '../charttypes/donut';
//import AmPieChart from '../charttypes/ampiechart';
import html2pdf from 'html2pdf.js';

import { submitCollection } from '../../_services/submit.service';
import { submitSets } from '../UiComponents/SubmitSets';
//import { alertService } from '../../_services/alert.service';
//import { hexToRGB } from '../../_services/common.service';

import { logoutAction } from '../../actions/auth/login_action';

//import i18n from "i18next";
import { withTranslation } from "react-i18next";
import "../../_translations/i18n";
import "./singlePageFactSheet.css";

import nccplogo from '../../assets/img/logo512.png';
import maleicon from '../../assets/img/male-icon.png';
import femaleicon from '../../assets/img/female-icon.png';
import foldericon from '../../assets/img/slcr-logo.png';
import worldicon from '../../assets/img/maps/world.png';
import asiaicon from '../../assets/img/maps/asia.png';
import slicon from '../../assets/img/maps/srilanka.png';

class SinglePageFactSheet extends React.Component{
    _isMounted = false;

    constructor(props){
        super(props);
        this.state = {
            genderType:"Female", 
            selectedchartidx: 0,
            MaleCommonCancersDonutChartData:null,
            FemaleCommonCancersDonutChartData:null,

            CommonData:{male:null,female:null, both:null},

            trendASRlineChartData:{categories:[],series:[{name:"",data:[]}]},
            ageSpecIncLineChartData:{categories:[],series:[{name:"",data:[]}]},
            minyear: "-", maxyear: "-",
        }

        this.signinBtnElement = React.createRef();
        //this.printdiv = React.createRef();
    }
      
    componentDidMount(){
        // if(this.props.signedobj===null || this.props.signedobj.signinDetails.signinDetails===false){
        //     this.props.handleSignObj({signinDetails:false, username:""});
        //     this.props.history.push("/");
        // }

        this.getMostCommonCancers(this.props.fobj.gender);
        //console.log(this.props.fobj);
        this.getTrendOfASR();
        this.getAgeSpecIncidents();
        this.getCommonCounts();
    }

    componentWillUnmount(){
        this._isMounted = false;
        
    }

    getMostCommonCancers = (gender) =>{
        
        let sobj = {selCancerSites:[{id: 0,name: "All Sites",icdcode: "All Sites"}], gender: gender, totalNoOfResults:10, maxYear:this.props.fobj.maxYear};
        submitSets(submitCollection.getProprtionOfLeadingCancers,sobj).then(res=> {
            if(res.status===true && res.extra.length>0){
                let data = res.extra;
                let chartData = {categories:[],series:[],colors:[]};
                var cursiteidx = 0;
                for (let i = 0; i < data.length; i++) {
                    chartData.categories.push(data[i].cancerSiteName);
                    chartData.series.push(parseFloat(data[i].count));
                    chartData.colors.push(data[i].cancerSiteColor);

                    if(this.props.fobj.cancerSiteId3 === data[i].cancerSiteId){
                        cursiteidx = i;
                    }
                }
                if(gender==="Male"){
                    this.setState({MaleCommonCancersDonutChartData:chartData, selectedchartidx: cursiteidx });
                }
                else if(gender==="Female"){
                    this.setState({FemaleCommonCancersDonutChartData:chartData, selectedchartidx: cursiteidx });
                }
            }
        });
        
    }

    getTrendOfASR = () =>{
        let sobj = {selCancerSites:[{id: this.props.fobj.cancerSiteId ,name: "",icdcode: ""}], basedOnType: "CANCER_SITE_GENDERWISE", gender: this.props.fobj.gender, minYear:this.props.fobj.minYear, maxYear:this.props.fobj.maxYear};

        submitSets(submitCollection.getAgeStandardizedIncidenceRates,sobj).then(res=> {
            if(res.status===true && res.extra.length>0){
                let data = res.extra;
                let lineChartData = {categories:[], series:[{name:"", data:[]}]};

                let marray = [];let farray = [];
                var minyear = ""; var maxyear = "";
                for (let i = 0; i < data.length; i++) {
                    if(i === 0){
                        minyear = data[i].year;
                    } else if(i === (data.length - 1)){
                        maxyear = data[i].year;
                    }
                    lineChartData.categories.push(data[i].year);
                    for (let x = 0; x < data[i].values.length; x++) {
                        let yitem = data[i].values[x];
                        if(yitem.gender==="Male"){
                            marray.push(yitem.count);
                        }
                        else if (yitem.gender==="Female"){
                            farray.push(yitem.count);
                        }
                    }
                }
                lineChartData.series = [];
                lineChartData.series.push({name:"Male",data:(this.props.fobj.gender==="Both"||this.props.fobj.gender==="Male"?marray:[])});
                lineChartData.series.push({name:"Female",data:(this.props.fobj.gender==="Both"||this.props.fobj.gender==="Female"?farray:[])});

                this.setState({trendASRlineChartData:lineChartData, minyear: minyear, maxyear: maxyear});
            }
        });
    }

    getAgeSpecIncidents = () =>{
        let sobj = {cancerSite:this.props.fobj.cancerSiteId2, maxYear:this.props.fobj.maxYear };

        submitSets(submitCollection.getOverallAgeSpecificIncidenceRateOfMaleAndFemale,sobj).then(res=> {
            if(res.status===true && res.extra.length>0){
                let data = res.extra;
                let lineChartData = {categories:[], series:[{name:"", data:[]}]};

                let marray = [];let farray = [];
                for (let i = 0; i < data.length; i++) {
                    for (let x = 0; x < data[i].values.length; x++) {
                        if(i===0){
                            lineChartData.categories.push(data[i].values[x].ageRange);
                        }
                        let yitem = data[i];
                        if(yitem.gender==="Male"){
                            marray.push(data[i].values[x].count);
                        }
                        else if (yitem.gender==="Female"){
                            farray.push(data[i].values[x].count);
                        }
                    }
                }
                lineChartData.series = [];
                lineChartData.series.push({name:"Male",data:(this.props.fobj.gender==="Both"||this.props.fobj.gender==="Male"?marray:[])});
                lineChartData.series.push({name:"Female",data:(this.props.fobj.gender==="Both"||this.props.fobj.gender==="Female"?farray:[])});

                this.setState({ageSpecIncLineChartData:lineChartData });
            }
        });
    }

    getCommonCounts = () =>{
        let sobj = {cancerSiteGroup:this.props.fobj.cancerSiteGroupId ,year:this.props.fobj.year, orgUnit:this.props.fobj.orgUnit};
        submitSets(submitCollection.getCommonCounts,sobj).then(res=> {
            if(res.status===true && res.extra.length>0){
                let CommonData = {male:null,female:null, both:null};
                for (let i = 0; i < res.extra.length; i++) {
                   if(res.extra[i].gender==="Male"){
                        CommonData.male = res.extra[i].commonData;
                   }
                   else if(res.extra[i].gender==="Female"){
                        CommonData.female = res.extra[i].commonData;
                   }
                   else if(res.extra[i].gender==="Both"){
                        CommonData.both = res.extra[i].commonData;
                    }
                }
                this.setState({CommonData:CommonData});
                //console.log(this.state.CommonData);
            }
        });
    }

    export = () =>{
        var element = document.getElementById('generating-section-single');
        var pagebreak = {mode:"avoid-all"};
        var opt = {
            margin:       0,
            filename:     this.props.fobj.sheetName.replace(/ /g,'')+"_"+this.props.language+'.pdf',
            image:        { type: 'jpeg', quality: 0.98 },
            html2canvas:  { scale: 5, logging: true, dpi: 192, letterRendering: true },
            jsPDF:        { unit: 'mm', format: 'a4', orientation: 'portrait',  compress: true, precision: 8 },
            pagebreak: pagebreak
        };

        html2pdf().set(opt).from(element).toPdf().get('pdf').then(function (pdf) {
            var totalPages = pdf.internal.getNumberOfPages(); 
            var cdate = new Date();
            //print current pdf width & height to console
            
            for (var i = 1; i <= totalPages; i++) {
              pdf.setPage(i);
              pdf.setFontSize(10);
              pdf.setTextColor("#d04571");
              pdf.text('National Cancer Registry : All rights reserved '+cdate.getFullYear(), 100, 290,'center');
            } 
            }).save();
    }

    render(){
        return (
        <Col className="main-container-col" style={{width:"815px"}}>
            
            <Col style={{marginBottom:"5px"}}>
                <ButtonGroup aria-label="Basic example" size="sm">
                    <Button onClick={()=>this.props.changeLanguage("en")} variant={this.props.language==="en"?"primary":"secondary"}>English</Button>
                    <Button onClick={()=>this.props.changeLanguage("sin")} variant={this.props.language==="sin"?"primary":"secondary"}>Sinhala</Button>
                    <Button onClick={()=>this.props.changeLanguage("ta")} variant={this.props.language==="ta"?"primary":"secondary"}>Tamil</Button>
                </ButtonGroup>
            
                <Col style={{position:"relative", float:"right"}}>
                    {/* <Pdf targetRef={this.printdiv} filename="code-example.pdf">
                        {({ toPdf }) => <button onClick={toPdf}>Generate Pdf</button>}
                    </Pdf> */}
                    <Button variant="success" size="sm" onClick={()=>this.export()}>Download</Button>{" "}
                    <Button variant="secondary" size="sm" onClick={()=>this.props.changeViewType("mainView")}>Back</Button>
                </Col>

                
            </Col>
            
            <Col xs={12} style={{width:"815px",border:"1px solid #ccc",padding:"10px"}}>
                <Col xs={12} className="generating-section-single" id="generating-section-single">
                    {/* <Col className="overlay-bg" style={{background:"linear-gradient(to right, transparent, "+hexToRGB("#00BDAA",0.3)+")"}}></Col> */}
                    <Col className={"gs-title"+(this.props.language!=="en"?" sub-lang":"")}>
                        <Row style={{paddingLeft:"10px"}}>
                            <Col xs={1}>
                                <img alt="" src={foldericon} width="55px" id="mainLogo" style={{marginTop:"-4px"}} />
                            </Col>
                            <Col xs={11}>
                                <h6 className={"main-heading-single"}>{this.props.t("NCCP_SUB")}</h6>
                                <h5 className={"sub-heading-single"}>{this.props.t("NCCP")}</h5>
                                <img src={nccplogo} className="fsheet-logo" alt=""/>
                            </Col>
                        </Row>
                    </Col>

                    <Col className={"chart-title-col"+(this.props.language!=="en"?" sub-lang":"")} style={{background:(this.props.fobj?this.props.fobj.color:"#04879C")}}>
                        {this.props.language==="en"?this.props.fobj.cancerSiteName:this.props.language==="sin"?this.props.fobj.cancerSiteSinName:this.props.language==="ta"?this.props.fobj.cancerSiteTamName:""} - {this.props.fobj.year}
                        <div className="year-view"><span>{this.props.t("FACT_SHEET")}</span></div>
                    </Col>

                    <Col xs={12} className={"chart-containts"+(this.props.language!=="en"?" sub-lang":"")}>
                        <Row style={{padding:"5px 0px"}}>
                            
                            <Col xs={7} style={{padding:"0px",position:"relative"}}>
                                <Col className="chart-sub-card">
                                    <h6 className={"sub-title-single"+(this.props.language!=="en"?" sub-lang":"")} style={{color:(this.props.fobj?this.props.fobj.color:"#04879C")}}>{ (this.props.fobj.gender==="Female"?this.props.t("MOST_COMMON_CANCERS_FEMALE"):this.props.t("MOST_COMMON_CANCERS_MALE")) } {this.props.fobj.year}</h6>
                                    {this.state.FemaleCommonCancersDonutChartData?<>
                                        <Col xs={8} style={{marginLeft:"-5px",height:"255px",marginTop:"-20px",paddingBottom:"20px"}}>
                                            <DynamicDonutChart data={this.state.FemaleCommonCancersDonutChartData} isxchange={true} expandindex={this.state.selectedchartidx} isshowlegend={false} />
                                        </Col>
                                        <div className="chartview-legend" style={{top:"55px",right:"15px"}}>
                                            <ul>
                                                {this.state.FemaleCommonCancersDonutChartData?this.state.FemaleCommonCancersDonutChartData.categories.map((xitem,xidx) => {
                                                    return <li key={xidx} style={{fontSize:"12px"}}><div style={{background: this.state.FemaleCommonCancersDonutChartData.colors[xidx]}}></div> {xitem}</li>;
                                                }):<></>}
                                            </ul>
                                        </div>
                                    </>:<></>}
                                    {/* {this.state.FemaleCommonCancersDonutChartData?<AmPieChart data={this.state.FemaleCommonCancersDonutChartData} expandindex={this.state.selectedchartidx} />:<></>} */}
                                </Col>
                            </Col>
                            <Col xs={5} className={"summary-section"}>
                                <Col className="chart-sub-card" style={{height:"305px",padding:"0px",paddingTop:"50px"}}>
                                    <Col xs={12} className="summary-item world">
                                        <Row style={{paddingRight:"5px"}}>
                                            <Col xs={3} className="img-col"><img alt="" src={worldicon} /></Col>
                                            <Col xs={5} className="data-col">
                                                <h6>ASR: {this.props.t("WORLD")}</h6>
                                                
                                                {/* <div className="percentage-line-main">
                                                    <div className="percentage" style={{width:"47.1%"}}></div>
                                                </div> */}
                                            </Col>
                                            <Col xs={4} className="percentage-col">
                                                <Col xs={12} className="percentage-sub" style={{width:"90%"}}>
                                                    {
                                                        this.props.fobj.gender==="Both"?
                                                        <h5>{this.state.CommonData.both!==null && this.state.CommonData.both.asrGS!==undefined ? this.state.CommonData.both.asrGS:"0"}</h5> :
                                                            this.props.fobj.gender==="Male" ?
                                                            <h5>{this.state.CommonData.male!==null && this.state.CommonData.male.asrGS!==undefined ? this.state.CommonData.male.asrGS:"0"}</h5> :
                                                                this.props.fobj.gender==="Female" ?
                                                                    <h5>{this.state.CommonData.female!==null && this.state.CommonData.female.asrGS!==undefined ? this.state.CommonData.female.asrGS:"0"}</h5> : ""
                                                    }    
                                                </Col>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col xs={12} className="summary-item asia">
                                        <Row style={{paddingRight:"5px"}}>
                                            <Col xs={3} className="img-col"><img alt="" src={asiaicon} /></Col>
                                            <Col xs={5} className="data-col">
                                                <h6>ASR: {this.props.t("SOUTH_ASIA")}</h6>
                                                {/* <div className="percentage-line-main">
                                                    <div className="percentage" style={{width:"41.1%"}}></div>
                                                </div> */}
                                            </Col>
                                            <Col xs={4} className="percentage-col">
                                                <Col xs={12} className="percentage-sub" style={{width:"78%"}}>
                                                    {
                                                        this.props.fobj.gender==="Both"?
                                                        <h5>{this.state.CommonData.both!==null && this.state.CommonData.both.asrSEA!==undefined ? this.state.CommonData.both.asrSEA:"0"}</h5> :
                                                            this.props.fobj.gender==="Male" ?
                                                            <h5>{this.state.CommonData.male!==null && this.state.CommonData.male.asrSEA!==undefined ? this.state.CommonData.male.asrSEA:"0"}</h5> :
                                                                this.props.fobj.gender==="Female" ?
                                                                    <h5>{this.state.CommonData.female!==null && this.state.CommonData.female.asrSEA!==undefined ?this.state.CommonData.female.asrSEA:"0"}</h5> :""
                                                    }    
                                                </Col>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col xs={12} className="summary-item sl">
                                        <Row style={{paddingRight:"5px"}}>
                                            <Col xs={3} className="img-col"><img alt="" src={slicon} /></Col>
                                            <Col xs={5} className="data-col">
                                                <h6>ASR: {this.props.t("SRI_LANKA")}</h6>
                                                {/* <div className="percentage-line-main">
                                                    <div className="percentage" style={{width:"33.5%"}}></div>
                                                </div> */}
                                            </Col>
                                            <Col xs={4} className="percentage-col">
                                                <Col xs={12} className="percentage-sub" style={{width:"58%"}}>
                                                    {
                                                        this.props.fobj.gender==="Both"?
                                                        <h5>{this.state.CommonData.both!==null && this.state.CommonData.both.asr!==undefined ?this.state.CommonData.both.asr:"0"}</h5> :
                                                            this.props.fobj.gender==="Male" ?
                                                            <h5>{this.state.CommonData.male!==null && this.state.CommonData.male.asr!==undefined ?this.state.CommonData.male.asr:"0"}</h5> :
                                                                this.props.fobj.gender==="Female" ?
                                                                    <h5>{this.state.CommonData.female!==null && this.state.CommonData.female.asr!==undefined?this.state.CommonData.female.asr:"0"}</h5> : ""
                                                    }    
                                                </Col>
                                            </Col>
                                        </Row>
                                    </Col>    
                                </Col>
                                
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={8} style={{paddingRight:"0px"}}>
                                <Col className="chart-sub-card" style={{height:"299px"}}>
                                    <h6 className="sub-title-single" style={{color:(this.props.fobj?this.props.fobj.color:"#04879C")}}>{this.props.t("SINGLE_T1_"+this.props.fobj.cancerSiteId)} {this.state.minyear} - {this.state.maxyear}</h6>
                                    <DynamicLineChart data={this.state.trendASRlineChartData} isdatalable={false} ismarkers={false} x={this.props.t("YEAR_X")} y={this.props.t("ASR_X")} height={265} isshowlegend={false} colors={["#0E49B5","#F21170"]} />
                                    <Col xs={3} className="chart-containt-labels" style={{marginTop:"-50px"}}>
                                        <Row>
                                        {this.props.fobj.gender==="Both" || this.props.fobj.gender==="Female"?
                                            <Col xs={6}><div className="label-color fmle"></div> {this.props.t("Female")}</Col>
                                        :<></>}
                                        {this.props.fobj.gender==="Both" || this.props.fobj.gender==="Male"?
                                            <Col xs={6}><div className="label-color mle"></div> {this.props.t("Male")}</Col>
                                        :<></>}    
                                        </Row>
                                    </Col>
                                </Col>
                            </Col>
                            <Col xs={4} style={{paddingRight:"12px",paddingLeft:"5px"}}>
                                <Col className="chart-sub-card">
                                <h6 className="sub-title-single" style={{color:"#fff",background:(this.props.fobj?this.props.fobj.color:"#04879C"),textAlign:"center",padding:"11px 11px",borderRadius:"4px",height:"auto"}}>{this.props.t("NO_OF_NEW_CASES_IN")}  {this.props.fobj.year}</h6>
                                    <Row style={{marginTop:"10px"}}>
                                        {this.props.fobj.gender==="Both" || this.props.fobj.gender==="Female"?<Col xs={(this.props.fobj.gender==="Both"?6:12)}>
                                            <Col className="gender-view fmale">
                                                <h6 style={{textAlign:"center"}}>{this.props.t("Female")}</h6>
                                                <img src={femaleicon} width="70px" style={{display:"block",margin:"0 auto"}} alt="female"/>
                                                <h5 style={{textAlign:"center"}}>{this.state.CommonData.female!==null?parseInt(this.state.CommonData.female.count):"0"}</h5>
                                            </Col>
                                        </Col>:<></>}
                                        {this.props.fobj.gender==="Both" || this.props.fobj.gender==="Male"?<Col xs={(this.props.fobj.gender==="Both"?6:12)}>
                                            <Col className="gender-view">
                                                <h6 style={{textAlign:"center"}}>{this.props.t("Male")}</h6>
                                                <img src={maleicon} width="70px" style={{display:"block",margin:"0 auto"}} alt="male"/>
                                                <h5 style={{textAlign:"center"}}>{this.state.CommonData.male!==null?parseInt(this.state.CommonData.male.count):"0"}</h5>    
                                            </Col>
                                        </Col>:<></>}
                                    </Row>
                                </Col>
                            </Col>
                        </Row>

                        <Col className="chart-sub-card" style={{height:"299px"}}>
                            <Row>
                                <Col xs={12}>
                                    <h6 className="sub-title-single" style={{color:(this.props.fobj?this.props.fobj.color:"#04879C")}}>{this.props.t("SINGLE_T2_"+this.props.fobj.cancerSiteId)} {this.props.fobj.year}</h6>
                                    <Col className={"linechart-main "+(this.state.genderType==="Both"?"both-view":"")}>
                                        <DynamicLineChart data={this.state.ageSpecIncLineChartData} x={this.props.t("AGE_RANGE_Y")} height={265} isshowlegend={false} isdatalable={true} ismarkers={true} y={this.props.t("ASR_X")} colors={["#0E49B5","#F21170"]} />
                                        <Col xs={2} className="chart-containt-labels" style={{marginTop:"-50px"}}>
                                            <Row>
                                            {this.props.fobj.gender==="Both" || this.props.fobj.gender==="Female"?
                                                <Col xs={6}><div className="label-color fmle"></div> {this.props.t("Female")}</Col>
                                            :<></>}
                                            {this.props.fobj.gender==="Both" || this.props.fobj.gender==="Male"?
                                                <Col xs={6}><div className="label-color mle"></div> {this.props.t("Male")}</Col>
                                            :<></>}
                                            </Row>
                                        </Col>
                                    </Col>
                                </Col>
                            </Row>    
                        </Col>
                    </Col>
                </Col>
            </Col>
            
           
        </Col>
         );
    }
}

const mapDispatchToProps = dispatch => ({
    setResetState: (payload) => dispatch(logoutAction(payload)),
});

export default withTranslation()(withRouter(connect(null,mapDispatchToProps)(SinglePageFactSheet)));
