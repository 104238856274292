import { Component } from 'react';
import { Card,Form, Col, Button } from 'react-bootstrap';
import './ResetPassword.css'
import { submitCollection } from '../../_services/submit.service';
import { submitSets } from '../UiComponents/SubmitSets';
import { alertService } from '../../_services/alert.service';
//import {ArrowLeftIcon} from '@primer/octicons-react'

//import { AcInput, AcButton, ValT } from '../UiComponents/AcImports';

export default class resetPassword extends Component {
    constructor(props){
        super(props);
        this.state = {
            oldpass:"", newpass:"", retypepass:""
        }
    }

    componentDidMount(){
        if(this.props.signedobj===null || this.props.signedobj.signinDetails.signinDetails===false){
            this.props.handleSignObj({signinDetails:false, username:""});
            this.props.history.push("/");
        }
        console.log(this.props);
    }

    componentWillUnmount(){
       
    }

    changeVals = (e,type) =>{
        if(type==="opass"){
            this.setState({oldpass:e});
        }
        else if(type==="npass"){
            this.setState({newpass:e});
        }
        else if(type==="rtpass"){
            this.setState({retypepass:e});
        
        }
    }

    changePass = () => {
        if(this.state.oldpass==="" || this.state.newpass==="" || this.state.retypepass===""){
            alertService.warn("Enter all fields!");
        }
        else{
            if(this.state.newpass!==this.state.retypepass){
                alertService.warn("Passwords do not match!");
            }
            else{
                let sobj = {userId:this.props.signedobj.signinDetails.signinDetails.userId, oldPassword:this.state.oldpass, password:this.state.newpass}
                submitSets(submitCollection.changePass,sobj).then(res=> {
                    if(res.status===true){
                        alertService.success("Successfully Changed!");
                        this.reset();
                    }
                    else{
                        alertService.error(res.extra===""?"Error Occured!":res.extra);
                    }
                });
            }
        }
    }

    reset=()=>{
        this.setState({oldpass:"", newpass:"", retypepass:""});
    }

    render() {
        return (<>
           <Col xs={4} className="resetpass-container">
                <Col xs={12} sm={12} md={12} lg={12}>
                <Card>
                <Card.Body>
                    <h5 className="main-heading">Change Password</h5><hr/>
                    <Form.Group>
                        <Form.Label>Current Password</Form.Label>
                        <input value={this.state.oldpass} onChange={(event)=>this.changeVals(event.target.value,"opass")} type="password" className="form-control form-control-sm" />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>New Password</Form.Label>
                        <input value={this.state.newpass} onChange={(event)=>this.changeVals(event.target.value,"npass")} type="password" className="form-control form-control-sm" />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Retype New Password</Form.Label>
                        <input value={this.state.retypepass} onChange={(event)=>this.changeVals(event.target.value,"rtpass")} type="password" className="form-control form-control-sm" />
                    </Form.Group>
                    <Button className="primary-button" onClick={()=>this.changePass()}>Change Password</Button>{" "}
                    <Button variant="secondary" onClick={()=>this.reset()} >Reset</Button>
                </Card.Body>
                </Card>
                </Col>
           </Col>
            
        </>
        )
    }
}
