export const TRANSLATIONS_EN = {
    NCCP:"NATIONAL CANCER CONTROL PROGRAMME",
    NCCP_SUB:"The National Cancer Registry of Sri Lanka",
    CANCER: "Cancer",
    CANCER_SITE:"Cancer Site",
    FACT_SHEET: "FACT SHEET",
    TO: "-",

    Male:"Male",
    Female:"Female",
    Both:"Both Sexes",

    WORLD:"World",
    SOUTH_ASIA:"South Asia",
    SRI_LANKA:"Sri\u00A0Lanka",

    NO_OF_NEW_CASES_IN:"Number of New Cases in",
    YEAR:"Year",
    AGE_GROUP:"Age Group",
    RATE:"Rate",
    RATE_PER_POPULATION:"Per 100,000 population",
    MOST_COMMON_CANCERS_FEMALE:"Most common cancers among females",
    MOST_COMMON_CANCERS_MALE:"Most common cancers among males",
    CASES:"Cases",
    Colombo:"Colombo",
    DISTRICT: "District",

    INCIDENCE_MALE:"Incidence Male",
    INCIDENCE_FEMALE:"Incidence Female",
    MORTALITY_MALE:"Mortality Male",
    MORTALITY_FEMALE:"Mortality Female",

    TREND_MALE_ASR:"Trend of male cancer ASR rates in Sri\u00A0Lanka",
    TENDS_MALE_CR:"Trend of male cancer CR rates in Sri\u00A0Lanka",

    TREND_FEMALE_ASR:"Trend of female cancer ASR rates in Sri\u00A0Lanka",
    TENDS_FEMALE_CR:"Trend of female cancer CR rates in Sri\u00A0Lanka",

    POP_TITLE1 :"Comparison of Crude Cancer Incidence Rate PBCR & NCR",
    POP_TITLE2:"Leading cancer - Crude incidence rate of NCR and PBCR",
    POP_TITLE3: "Comparison of Incidence/Mortality Rate",

    SINGLE_T1_CervixASR:"Trend of ASR of Cervix cancer in Sri\u00A0Lanka",
    SINGLE_T2_CervixASR:"Age specific incidence rates of Cervix cancers in Sri\u00A0Lanka in year",

    SINGLE_T1_BreastASR:"Trend of ASR of Breast cancer in Sri\u00A0Lanka",
    SINGLE_T2_BreastASR:"Age specific incidence rates of Breast cancers in Sri\u00A0Lanka in year",

    SINGLE_T1_LTMASR:"Trend of ASR of Lip, Tongue & Mouth cancer in Sri\u00A0Lanka",
    SINGLE_T2_LTMASR:"Age specific incidence rates of Lip, Tongue & Mouth cancers in Sri\u00A0Lanka in year",

    SINGLE_T1_LungASR:"Trend of ASR of Lung cancer in Sri\u00A0Lanka",
    SINGLE_T2_LungASR:"Age specific incidence rates of Lung cancers in Sri\u00A0Lanka in year",

    SINGLE_T1_ThyroidASR:"Trend of ASR of Thyroid cancer in Sri\u00A0Lanka",
    SINGLE_T2_ThyroidASR:"Age Specific Incidence rates of Thyroid cancers in Sri\u00A0Lanka in year",

    SINGLE_T1_ColonRectumASR:"Trend of ASR of Colon & Rectum cancer in Sri\u00A0Lanka",
    SINGLE_T2_ColonRectumASR:"Age Specific Incidence rates of Colon & Rectum cancers in Sri\u00A0Lanka in year",

    SINGLE_T1_OesophagusASR:"Trend of ASR of Oesophagus cancer in Sri Lanka",
    SINGLE_T2_OesophagusASR:"Age Specific Incidence rates of Oesophagus cancers in Sri\u00A0Lanka in year",

    AGE_RANGE_MOST_COMMON_FEMALE_0_19:"Leading cancers by age group 0-19 year age group among females in Sri\u00A0Lanka",
    AGE_RANGE_MOST_COMMON_FEMALE_20_59:"Leading cancers by age group 20- 59 year age group among females in Sri\u00A0Lanka",
    AGE_RANGE_MOST_COMMON_FEMALE_60_PLUS:"Leading cancers by age group more than 60\u00A0years among females in Sri\u00A0Lanka",
    AGE_SPECIFIC_CANCER_INCI_FEMALE:"Age Specific Cancer Incidence rates in Sri\u00A0Lanka",

    AGE_RANGE_MOST_COMMON_MALE_0_19:"Leading cancers by age group 0-19 year age group among males in Sri\u00A0Lanka",
    AGE_RANGE_MOST_COMMON_MALE_20_59:"Leading cancers by age group 20- 59 year age group among males in Sri\u00A0Lanka",
    AGE_RANGE_MOST_COMMON_MALE_60_PLUS:"Leading cancers by age group more than 60\u00A0years among males in Sri\u00A0Lanka",
    AGE_SPECIFIC_CANCER_INCI_MALE:"Age Specific Cancer Incidence rates in Sri\u00A0Lanka",

    OVB_ASR_BOTH:"Overall Cancer ASR from",
    OVB_CR_BOTH:"Overall Cancer CR from",
    OVB_CAN_INC_AGE:"Cancer Incidence according to the Age Groups",
    OVB_TOP10_CAN:"Top Ten Cancers in Sri\u00A0Lanka",
    OVB_ASR_PER_SEX_TOP10:"Age Standardized Incident rates of leading cancers",

    PED_NEW_0_19_CANCERS:"Number of Newly reported 0-19\u00A0year childhood cancers in Sri\u00A0Lanka",
    PED_CRUDE_INC_RATE_CANCERS:"Crude Incidence Rate of childhood cancers 0-19\u00A0years",
    PED_AGE_STAN_INC_RATE_CANCERS:"Age Standardized incidence rate of childhood cancers 0-19\u00A0from",
    PED_AGE_SPEC_INC_RATE_CANCERS_0_4:"Age Specific Incidence rate of childhood cancers among 0-4\u00A0years",
    PED_AGE_SPEC_INC_RATE_CANCERS_5_9:"Age Specific Incidence rate of childhood cancers among 5-9\u00A0years",
    PED_AGE_SPEC_INC_RATE_CANCERS_10_14:"Age Specific Incidence rate of childhood cancers among 10-14\u00A0years",
    PED_AGE_SPEC_INC_RATE_CANCERS_15_19:"Age Specific Incidence rate of childhood cancers among 15-19\u00A0years",
    PED_DIST_CANCERS_MALE:"Distribution of childhood cancers among 0\u00A0-\u00A019\u00A0year males in Sri\u00A0Lanka",
    PED_DIST_CANCERS_FEMALE:"Distribution of childhood cancers among 0\u00A0-\u00A019\u00A0year females in Sri\u00A0Lanka",

    //chart x, y axises
    //X
    CRUDE_RATE_X:"Crude Rate/100,000 Pop.",
    ASR_X:"ASR/100,000 Pop",
    AGE_SPEC_RATE_X:"Age Specific rate / 100,000 Pop.",
    AGE_RANGE_X:"Age Range",
    YEAR_X:"Year",
    AGE_GROUP_X:"Age Group",

    //Y
    CANCER_SITES_Y:"Cancer Sites",
    AGE_RANGE_Y:"Age Range",
    INCI_RATE_Y:"Incidence Rate",
    CRUDE_RATE_Y:"Crude Rate",
    ASR_Y:"ASR",
    INC_RATE_PER:"Incidence rate per 100,000 pop"
};