import React from 'react';
import { Col, Table, Button, Modal, Form, ButtonGroup } from 'react-bootstrap';
import { submitCollection } from '../../_services/submit.service';
import { submitSets } from '../UiComponents/SubmitSets';
import { alertService } from '../../_services/alert.service';
import LoadingIcon from  '../../assets/img/loading.gif';
import { pageLength, getPager } from '../../_services/common.service';
import './charts.css';

class Charts extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            currentPage: 1, showPagination:true,
            isLoading:false,showEditModal:false,
            mainList:[], selectedObj:{chartId:0, chartName:"", chartType:"",description:"", descriptionSin:"", descriptionTam:""},
            oriList:[], filtertxt:"",
        }
        
    }

    componentDidMount(){
        this.loadAllCharts()
    }

    setModalShow = (type) =>{
        this.setState({showEditModal:type});
    }

    loadAllCharts = () =>{
        this.setState({isLoading:true, mainList:[], showPagination:false })
        submitSets(submitCollection.getAllCharts,null).then(res=> {
            if(res.status===true){
                this.setState({mainList:res.extra, oriList:res.extra, isLoading:false});
                this.setPage(this.state.currentPage);
            }
            else{
                this.setState({mainList:[], oriList:[], isLoading:false});
            }
        });
    }

    clickChart = (obj) =>{
        let sobj = JSON.parse(JSON.stringify(obj))
        this.setState({selectedObj:sobj, cancerSiteId:"0"});
        this.setModalShow(true)
    }

    changeVals = (e,type) =>{
        if(type==="name"){
            let sobj = this.state.selectedObj;
            sobj.chartName = e;
            this.setState({sobj:sobj});
        }
        else if(type==="des"){
            let sobj = this.state.selectedObj;
            sobj.description = e;
            this.setState({sobj:sobj});
        }
        else if(type==="s-des"){
            let sobj = this.state.selectedObj;
            sobj.descriptionSin = e;
            this.setState({sobj:sobj});
        }
        else if(type==="t-des"){
            let sobj = this.state.selectedObj;
            sobj.descriptionTam = e;
            this.setState({sobj:sobj});
        }
        
    }

    save = () =>{
        let sobj = this.state.selectedObj;
        if(sobj.chartType==="" ){
            alertService.warn("Enter Chart Name!");
        }
        else{
            submitSets(submitCollection.UpdateChart,this.state.selectedObj).then(res=> {
                if(res.status===true){
                    this.loadAllCharts();
                    this.setModalShow(false);
                    alertService.success("Successfully Saved");
                }
                else{
                    alertService.error("Error Occurred");
                }
            });
        }
    }

    handleFilterTxt = e => {
        var tarr = [];
        var mlist = this.state.oriList;
        var filterval = e.target.value.toLowerCase();
        this.setState({ filtertxt: filterval, showPagination:false });

        //console.log(this.state.plist);
        if (filterval !== "") {
            for (var i = 0; i < mlist.length; i++) {
                if (mlist[i].chartName.toLowerCase().indexOf(filterval) > -1) {
                    tarr.push(mlist[i]);
                }
            }
            this.setState({ mainList: tarr });
        }
        else{
            this.setPage(1);
        }
        
    }

    setPage = (cpage) => {
        var citems = JSON.parse(JSON.stringify(this.state.oriList));
        var pager = getPager(citems.length, cpage, pageLength);
        // check page isn't out of range
        if (cpage < 1 || cpage > pager.totalPages) {
            return;
        }
        var sspage = (pager.currentPage === 1 ? 0 : ((pager.currentPage - 1) * pageLength));
        var sepage = (pager.currentPage * pageLength);
        this.setState({
            mainList: citems.slice(sspage, sepage),
            currentPage: pager.currentPage,
            totalPages: pager.totalPages,
        });

        this.setState({showPagination:(citems.length>0 ? true:false)});
    }


    render(){
        return(
            <>
                <Col className="main-container">
                    <h5 className="main-heading">
                        Charts
                        <ul className="top-right-content list-inline">
                            <li className="list-inline-item"><input className="form-control find-txt" value={this.state.filtertxt} onChange={this.handleFilterTxt} placeholder="Find" /></li>
                        </ul>    
                    </h5>
                    <Table bordered hover size="sm" className="main-table">
                        <thead>
                            <tr>
                            <th width="200px">Chart</th>
                            <th width="250px">Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className={this.state.isLoading===true?"":"d-none"}><td colSpan="10" align="center" style={{backgroundColor:"#FFF"}}><img alt="" src={LoadingIcon} /></td></tr>
                            {
                                this.state.mainList.map((item, i) =>{
                                    return(
                                        <tr key={i}>
                                            <td onClick={()=>this.clickChart(item)}>{item.chartName}</td>
                                            <td onClick={()=>this.clickChart(item)}>{item.description}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                    <Col sm={12} className={this.state.showPagination===true?"div-pagination":"div-pagination d-none" }>
                        <ButtonGroup aria-label="Basic example">
                            <Button onClick={() => { this.setPage(1) }} variant="default" size="sm">First</Button>
                            <Button onClick={() => { this.setPage((this.state.currentPage - 1)) }} variant="default" size="sm">Prev</Button>
                            <Button variant="default" size="sm" disabled id="btncurpageno">Page {this.state.currentPage}</Button>
                            <Button onClick={() => { this.setPage((this.state.currentPage + 1)) }} variant="default" size="sm">Next</Button>
                            <Button onClick={() => { this.setPage(this.state.totalPages) }} variant="default" size="sm">Last</Button>

                        </ButtonGroup>
                    </Col>
                </Col>

                <Modal animation={false} show={this.state.showEditModal} onHide={() => this.setModalShow(false)}>
                    <Modal.Header>
                        <Modal.Title>Edit Chart</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>Name*</Form.Label>
                            <input className="form-control" type="text" disabled size="sm" value={this.state.selectedObj.chartName} onChange={(event)=>this.changeVals(event.target.value,"name")} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Description</Form.Label>
                            <input className="form-control" type="text" size="sm" value={this.state.selectedObj.description!==null?this.state.selectedObj.description:""} onChange={(event)=>this.changeVals(event.target.value,"des")} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Sinhala Description</Form.Label>
                            <input className="form-control" type="text" size="sm" value={this.state.selectedObj.descriptionSin!==null?this.state.selectedObj.descriptionSin:""} onChange={(event)=>this.changeVals(event.target.value,"s-des")} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Tamil Description</Form.Label>
                            <input className="form-control" type="text" size="sm" value={this.state.selectedObj.descriptionTam!==null?this.state.selectedObj.descriptionTam:""} onChange={(event)=>this.changeVals(event.target.value,"t-des")} />
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" size="sm" onClick={() => this.setModalShow(false)}>Close</Button>
                        <Button className="primary-button" size="sm" onClick={() => this.save()}>Save Changes</Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}

export default Charts;
