import React from 'react';
import { useLocation, withRouter } from 'react-router-dom';
import { Col, Nav } from 'react-bootstrap';
import { ChecklistIcon, ProjectIcon, SearchIcon, BrowserIcon, CircleIcon, QuoteIcon, TagIcon, UploadIcon} from '@primer/octicons-react';

//import { cversion } from '../../_services/common.service';
import NccpLogo from "../../assets/img/logo512.png"

import './common.css'

function SidebarMenu(props) {
    const location = useLocation();

    const handleRedirect = (cpath) => {
      props.history.push(cpath);
    }

    const checkUrlContains = (cpath) => {
      return location.pathname.includes(cpath);
    }

    /* const handleDropDownView = (evt, cid) => {
      evt.target.classList.toggle("active");
      document.getElementById(cid).classList.toggle("d-none");
    } */

    //console.log(location);
    return (
    <Col className="main-sidemenu">
      <Col className="text-center"><img src={NccpLogo} alt="logo" style={{width:"65px",marginBottom:"20px"}}/></Col>
    {props.signedobj!==null&&props.signedobj.signinDetails?
      <Nav className="col-md-12 d-none d-md-block sidebar" activeKey="/dashboard">
          <div className="sidebar-sticky"></div>
      <Nav.Item>
          <Nav.Link className={"menu-item"+(checkUrlContains("/factsheets")?" active":"")} onClick={e => handleRedirect("/factsheets")} ><ChecklistIcon  size={16}/> Fact Sheets</Nav.Link>
      </Nav.Item>
      <Nav.Item>
          <Nav.Link className={"menu-item"+(checkUrlContains("/charts")?" active":"")} onClick={e => handleRedirect("/charts")}><ProjectIcon  size={16}/> <span style={{paddingLeft:"2px"}}>Charts</span></Nav.Link>
      </Nav.Item>
      <Nav.Item>
          <Nav.Link className={"menu-item"+(checkUrlContains("/cancer-sites")?" active":"")} onClick={e => handleRedirect("/cancer-sites")}><CircleIcon size={16}/> Cancer Sites</Nav.Link>
      </Nav.Item>
      <Nav.Item>
          <Nav.Link className={"menu-item"+(checkUrlContains("/search-keywords")?" active":"")} onClick={e => handleRedirect("/search-keywords")}><SearchIcon size={16}/> Search Keywords</Nav.Link>
      </Nav.Item>
      <Nav.Item>
          <Nav.Link className={"menu-item"+(checkUrlContains("/roadmap")?" active":"")} onClick={e => handleRedirect("/roadmap")}><QuoteIcon size={16}/> Roadmap</Nav.Link>
      </Nav.Item>
      <Nav.Item>
          <Nav.Link className={"menu-item"+(checkUrlContains("/body-parts")?" active":"")} onClick={e => handleRedirect("/body-parts")}><TagIcon size={16}/> Body Parts</Nav.Link>
      </Nav.Item>
      <Nav.Item>
          <Nav.Link className={"menu-item"+(checkUrlContains("/master-data")?" active":"")} onClick={e => handleRedirect("/master-data")}><BrowserIcon size={16}/> Master Data</Nav.Link>
      </Nav.Item>
      <Nav.Item>
          <Nav.Link className={"menu-item"+(checkUrlContains("/excel-upload")?" active":"")} onClick={e => handleRedirect("/excel-upload")}><UploadIcon size={16}/> Excel Upload</Nav.Link>
      </Nav.Item>
      
      </Nav>
      :<></>
    }
    </Col>);
}

export default withRouter(SidebarMenu);