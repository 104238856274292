import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Col, Button, Row, ButtonGroup } from 'react-bootstrap';

import DynamicDonut from '../charttypes/donut';
import DynamicBarChart from '../charttypes/barchart';
import DynamicLineChart from '../charttypes/linechart';
import html2pdf from 'html2pdf.js';

import { submitCollection } from '../../_services/submit.service';
import { submitSets } from '../UiComponents/SubmitSets';
//import { alertService } from '../../_services/alert.service';
import { numberWithCommas } from '../../_services/common.service';

import { logoutAction } from '../../actions/auth/login_action';

//import i18n from "i18next";
import { withTranslation } from "react-i18next";
import "../../_translations/i18n";
import "./twoPageFactSheet.css";

import nccplogo from '../../assets/img/logo512.png';
import foldericon from '../../assets/img/slcr-logo.png';
/* import asiaicon from '../../assets/img/maps/colombo.png';
import slicon from '../../assets/img/maps/srilanka.png'; */

class TwoPageFactSheet extends React.Component{
    _isMounted = false;

    constructor(props){
        super(props);
        this.state = {
            mostCommonData:null,
            mostCommonDataColors:[],
            trendASRData:{categories:[],series:[{name:"",data:[]}],colors:[]},
            trendASRDataColors: [], trendASRColors: null,
            trendCRData:{categories:[],series:[{name:"",data:[]}],colors:[]},
            trendCRDataColors: [], trendCRColors: null,
            samplecolors: ['#2a52be','#de6fa1','#da4040','#486090', '#00e699', '#a035ea','#35ead4', '#FF6600', '#ebc55e', '#409caf'],
            
            barFirstCatData : {categories:[],series:[{data:[]}]},
            barSecondCatData : {categories:[],series:[{data:[]}]},
            barThirdCatData : {categories:[],series:[{data:[]}]},
            barFourthCatData : {categories:[],series:[{data:[]}]},
            isbardataloaded: false,

            minyear: "-", maxyear: "-",
            minyear1: "-", maxyear1: "-",
        }

        this.signinBtnElement = React.createRef();
    }
      
    componentDidMount(){
        this.getMostCommonCancers(this.props.fobj.gender);
        this.getTrendASR(this.props.fobj.gender);
        this.getTrendCR(this.props.fobj.gender);
        this.getAgeSpecificIncidence(this.props.fobj.gender);
        this.getLeadingCancersByAge("GAR01",this.props.fobj.gender);
        this.getLeadingCancersByAge("GAR02",this.props.fobj.gender);
        this.getLeadingCancersByAge("GAR03",this.props.fobj.gender);
    }

    componentWillUnmount(){
        this._isMounted = false;
        
    }

    getMostCommonCancers = (gender) =>{
        
        let sobj = {selCancerSites:[{id: 0,name: "All Sites",icdcode: "All Sites"}], gender: gender, totalNoOfResults:10, maxYear:this.props.fobj.maxYear};
        submitSets(submitCollection.getProprtionOfLeadingCancers,sobj).then(res=> {
            if(res.status===true){
                //console.log(res);
                let data = res.extra;
                let chartData = {categories:[],series:[],colors:[]};
                let mostcolors = [];
                for (let i = 0; i < data.length; i++) {
                    chartData.categories.push(data[i].cancerSiteName);
                    chartData.series.push(parseFloat(data[i].count));
                    chartData.colors.push(data[i].cancerSiteColor);
                    mostcolors.push({site:data[i].cancerSiteName, color:data[i].cancerSiteColor, count: data[i].count});
                }
                //console.log(mostcolors);
                this.setState({mostCommonData:chartData, mostCommonDataColors: mostcolors });
                
            }
        });
        
    }

    getTrendASR = (gender) =>{
        let sobj = {selCancerSites:[{id: 0}], gender: gender, basedOnType: this.props.fobj.basedOnType, minYear:this.props.fobj.minYear, maxYear:this.props.fobj.maxYear, totalNoOfResults:10};
        submitSets(submitCollection.getAgeStandardizedIncidenceRates,sobj).then(res=> {
            if(res.status===true){
                //console.log(res);
                let data = res.extra;
                let lineChartData = {categories:[], series:[{name:"", data:[]}]};
                let linecolors = []; let sitecolors = [];

                var minyear = ""; var maxyear = "";
                let cancerSiteList = [];
                for (let i = 0; i < data.length; i++) {
                    if(i === 0){
                        minyear = data[i].year;
                    } else if(i === (data.length - 1)){
                        maxyear = data[i].year;
                    }

                    const mitem = data[i];
                    lineChartData.categories.push(mitem.year);

                    if(i===0){
                        for (let x = 0; x < mitem.values.length; x++) {
                            linecolors.push({site: mitem.values[x].cancerSiteName, color:mitem.values[x].cancerSiteColor});
                            sitecolors.push(mitem.values[x].cancerSiteColor);
                            cancerSiteList.push({name:mitem.values[x].cancerSiteName, data:[]});
                            //cancerSitesNames = cancerSitesNames + " "+mitem.values[x].cancerSiteName +(x=== (mitem.values.length - 1)?"":"," );
                        }
                    }
                }

                for (let y = 0; y < data.length; y++) {
                    const yitem = data[y];
                    for (let z = 0; z < yitem.values.length; z++) {
                        for (let g = 0; g < cancerSiteList.length; g++) {
                            if(yitem.values[z].cancerSiteName===cancerSiteList[g].name){
                                cancerSiteList[g].data.push(yitem.values[z].count);
                            }
                            
                        }
                        
                    }
                    
                }
                lineChartData.series = cancerSiteList;
                //console.log(sitecolors);
                this.setState({trendASRData:lineChartData, trendASRDataColors: linecolors, trendASRColors: sitecolors, minyear1:minyear, maxyear1:maxyear  });
            }
        });
        
    }


    getTrendCR = (gender) =>{
        let sobj = {selCancerSites:[{id: 0}], gender: gender, basedOnType: this.props.fobj.basedOnType, minYear:this.props.fobj.minYear, maxYear:this.props.fobj.maxYear,totalNoOfResults:10 };
        submitSets(submitCollection.getCrudeCancerIncidenceRates,sobj).then(res=> {
            if(res.status===true){
                let data = res.extra;
                let lineChartData = {categories:[], series:[{name:"", data:[]}]};
                let linecolors = []; let sitecolors = [];

                var minyear = ""; var maxyear = "";
                let cancerSiteList = [];
                for (let i = 0; i < data.length; i++) {
                    if(i === 0){
                        minyear = data[i].year;
                    } else if(i === (data.length - 1)){
                        maxyear = data[i].year;
                    }

                    const mitem = data[i];
                    lineChartData.categories.push(mitem.year);
                    
                    if(i===0){
                        for (let x = 0; x < mitem.values.length; x++) {
                            linecolors.push({site: mitem.values[x].cancerSiteName, color: mitem.values[x].cancerSiteColor});
                            sitecolors.push(mitem.values[x].cancerSiteColor);
                            cancerSiteList.push({name:mitem.values[x].cancerSiteName, data:[]});
                            //cancerSitesNames = cancerSitesNames + " "+mitem.values[x].cancerSiteName +(x=== (mitem.values.length - 1)?"":"," );
                        }
                    }
                }

                for (let y = 0; y < data.length; y++) {
                    const yitem = data[y];
                    for (let z = 0; z < yitem.values.length; z++) {
                        for (let g = 0; g < cancerSiteList.length; g++) {
                            if(yitem.values[z].cancerSiteName===cancerSiteList[g].name){
                                cancerSiteList[g].data.push(yitem.values[z].count);
                            }
                            
                        }
                        
                    }
                    
                }
                lineChartData.series = cancerSiteList;
                //console.log(sitecolors);
                this.setState({trendCRData:lineChartData, trendCRDataColors: linecolors, trendCRColors: sitecolors, minyear:minyear, maxyear:maxyear });
            }
        });
        
    }

    getLeadingCancersByAge = (agerange , gender) =>{
        let sobj = {ageRangeCode:agerange, gender: gender, maxYear:this.props.fobj.maxYear, totalNoOfResults:10,};
        submitSets(submitCollection.getAgeSpecificIncidenceProportionOfGroupedAgeRange,sobj).then(res=> {
            if(res.status===true){
                let masdata = res.extra;
                let chartData = {categories:[],series:[{data:[]}],colors:[]};
                let datalist = [];
                for (let i = 0; i < masdata.length; i++) {
                    chartData.categories.push(masdata[i].cancerSiteName);
                    chartData.colors.push(masdata[i].cancerSiteColor);
                    datalist.push(masdata[i].count);
                }
                chartData.series.push({data:datalist});
                if(agerange==="GAR01"){
                    this.setState({barFirstCatData:chartData}, () => { this.setState({isbardataloaded: true}); });
                }
                else if(agerange==="GAR02"){
                    this.setState({barSecondCatData:chartData}, () => { this.setState({isbardataloaded: true}); });
                }
                else if(agerange==="GAR03"){
                    this.setState({barThirdCatData:chartData}, () => { this.setState({isbardataloaded: true}); });
                }
            } else{
                this.setState({isbardataloaded: true});
            }
        });
    }

    getAgeSpecificIncidence = (gender) => {
        let sobj = {cancerSite:"AllSitesAgeSpecificRate",  selCancerSites:[], gender: gender, totalNoOfResults:10, maxYear:this.props.fobj.maxYear};
        submitSets(submitCollection.getOverallAgeSpecificIncidenceRate,sobj).then(res=> {
            if(res.status===true){
                let masdata = res.extra;
                let chartData = {categories:[],series:[{data:[]}]}; 
                let datalist = [];
                for (let i = 0; i < masdata.length; i++) {
                    chartData.categories.push(masdata[i].ageRangeName);
                    datalist.push(masdata[i].rate);
                }
                chartData.series.push({data:datalist});
                this.setState({barFourthCatData:chartData});
            }
        });
    }

    export = () =>{
        var element = document.getElementById('generating-section-pop');
        var pagebreak = {mode:"avoid-all"};
        var opt = {
            margin:       0,
            filename:     this.props.fobj.sheetName.replace(/ /g,'')+"_"+this.props.language+'.pdf',
            image:        { type: 'jpeg', quality: 0.98},
            html2canvas:  { scale: 5, logging: true, dpi: 192, letterRendering: true },
            jsPDF:        { unit: 'mm', format: 'a4', orientation: 'portrait', compress: true, precision: 8 },
            pagebreak: pagebreak
        };

        html2pdf().set(opt).from(element).toPdf().get('pdf').then(function (pdf) {
            var totalPages = pdf.internal.getNumberOfPages(); 
            var cdate = new Date();
            //print current pdf width & height to console
            
            for (var i = 1; i <= totalPages; i++) {
              pdf.setPage(i);
              pdf.setFontSize(10);
              pdf.setTextColor("#d04571");
              //divided by 2 to go center
              //var img = new Image()
              //img.src = '../../assets/img/logo512.png'
              //pdf.addImage(img,"png", 5, 1, 10, 10);
              //pdf.text('Page ' + i + ' of ' + totalPages, 10, 290,);
              pdf.text('National Cancer Registry : All rights reserved '+cdate.getFullYear(), 100, 293,'center');
            } 
            }).save();
    }

    shadeColor(color, percent) {

        var R = parseInt(color.substring(1,3),16);
        var G = parseInt(color.substring(3,5),16);
        var B = parseInt(color.substring(5,7),16);
    
        R = parseInt(R * (100 + percent) / 100);
        G = parseInt(G * (100 + percent) / 100);
        B = parseInt(B * (100 + percent) / 100);
    
        R = (R<255)?R:255;  
        G = (G<255)?G:255;  
        B = (B<255)?B:255;  
    
        var RR = ((R.toString(16).length===1)?"0"+R.toString(16):R.toString(16));
        var GG = ((G.toString(16).length===1)?"0"+G.toString(16):G.toString(16));
        var BB = ((B.toString(16).length===1)?"0"+B.toString(16):B.toString(16));
    
        return "#"+RR+GG+BB;
    }

    render(){
        //console.log(this.state.mostCommonDataColors);

        return (
        <Col className="main-container-col" style={{width:"815px"}} >
            
            <Col style={{marginBottom:"5px"}}>
                <ButtonGroup aria-label="Basic example" size="sm">
                    <Button onClick={()=>this.props.changeLanguage("en")} variant={this.props.language==="en"?"primary":"secondary"}>English</Button>
                    <Button onClick={()=>this.props.changeLanguage("sin")} variant={this.props.language==="sin"?"primary":"secondary"}>Sinhala</Button>
                    <Button onClick={()=>this.props.changeLanguage("ta")} variant={this.props.language==="ta"?"primary":"secondary"}>Tamil</Button>
                </ButtonGroup>
            
                <Col style={{position:"relative", float:"right"}}>
                    <Button variant="success" size="sm" onClick={()=>this.export()}>Download</Button>{" "}
                    <Button variant="secondary" size="sm" onClick={()=>this.props.changeViewType("mainView")}>Back</Button>
                </Col>
            </Col>
            
            <Col xs={12} style={{width:"830px",border:"1px solid #ccc",padding:"10px"}}>
                <Col xs={12} className="generating-section-pop pop-based overall-based" id="generating-section-pop">
                    {/* <Col className="overlay-bg"></Col> */}
                    <Col className={"gs-title"+(this.props.language!=="en"?" sub-lang":"")}>
                        <Row style={{paddingLeft:"10px"}}>
                            <Col xs={1}>
                                <img alt="" src={foldericon} width="55px" id="mainLogo" style={{marginTop:"-4px"}} />
                            </Col>
                            <Col xs={11}>
                                <h6 className="main-heading-pop ">{this.props.t("NCCP_SUB")}</h6>
                                <h5 className="sub-heading-pop">{this.props.t("NCCP")}</h5>
                                <img alt="" src={nccplogo} className="fsheet-logo"/>
                            </Col>    
                        </Row>
                    </Col>

                    <Col className={"chart-title-col"+(this.props.language!=="en"?" sub-lang":"")} style={{background:(this.props.fobj&&this.props.fobj.gender==="Female"?"#e61356":"#345fdf")}}>
                    {this.props.language==="en"?this.props.fobj.sheetName:this.props.language==="sin"?this.props.fobj.sinhalaName:this.props.language==="ta"?this.props.fobj.tamilName:""} - {this.props.fobj.maxYear}
                        <div className="year-view"><span>{this.props.t("FACT_SHEET")}</span></div>
                    </Col>

                    <Col xs={12} className={"chart-containts"+(this.props.language!=="en"?" sub-lang":"")}>
                        <Col  className="chart-sub-card" style={{height:"340px"}}>
                            <Row style={{padding:"5px 0px"}}>
                                <h6 className="sub-title-single" style={{color:(this.props.fobj&&this.props.fobj.gender==="Female"?"#e61356":"#345fdf")}}>{this.props.fobj.gender==="Male"?this.props.t("MOST_COMMON_CANCERS_MALE"):this.props.fobj.gender==="Female"?this.props.t("MOST_COMMON_CANCERS_FEMALE"):""} {this.props.fobj.year}</h6>
                                <Col xs={5} style={{padding:"0px",marginLeft:"-10px"}}>
                                    {this.state.mostCommonData?<DynamicDonut data={this.state.mostCommonData} isshowlegend={false} />:<></>}
                                </Col>
                                <Col xs={7} className="twofact-pielegend" style={{paddingLeft:"15px",paddingRight:"0px"}}>
                                    <Row>
                                        {this.state.mostCommonDataColors && this.state.mostCommonDataColors.length > 0?(this.state.mostCommonDataColors.map((xitem, xidx) => {
                                            return <Col key={xidx} xs={6}><div style={{background:(xitem.color?xitem.color:"#666")}}></div> 
                                            {xitem.site} <label style={{color:(xitem.color?this.shadeColor(xitem.color,-40):"#666")}}><small>n=</small>{numberWithCommas(xitem.count)}</label></Col>
                                        })):<></>}
                                    </Row>
                                </Col>
                            </Row>
                        </Col>

                        <Col className="chart-sub-card" style={{height:"300px"}}>
                            <Row style={{padding:"5px 0px"}}>
                                <h6 className="sub-title-single" style={{color:(this.props.fobj&&this.props.fobj.gender==="Female"?"#e61356":"#345fdf")}}>{this.props.fobj.gender==="Male"?this.props.t("TENDS_MALE_CR"):this.props.fobj.gender==="Female"?this.props.t("TENDS_FEMALE_CR"):""} {this.state.minyear} - {this.state.maxyear}</h6>
                                <Col xs={9} style={{height:"235px",marginTop:"-20px"}}>
                                    {this.state.trendCRColors?<DynamicLineChart data={this.state.trendCRData} linestroke={2} x={this.props.t("YEAR_X")} y={this.props.t("CRUDE_RATE_X")} height={275} isshowlegend={false} colors={this.state.trendCRColors} />:<></>}
                                </Col>
                                <Col xs={3} style={{padding:"0px"}}>
                                    <ul className="twofact-linelegend">
                                        {this.state.trendCRDataColors && this.state.trendCRDataColors.length > 0?(this.state.trendCRDataColors.map((xitem, xidx) => {
                                            return <React.Fragment key={xidx}>{xidx<10?<li key={xidx}><div style={{background:(xitem.color?xitem.color:"#666")}}></div> {xitem.site}</li>:<></>}</React.Fragment>;
                                        })):<></>}
                                    </ul>
                                </Col>
                            </Row>
                        </Col>
                        
                        <Col className="chart-sub-card" style={{height:"300px"}}>
                            <Row style={{padding:"5px 0px"}}>
                                <h6 className="sub-title-single" style={{color:(this.props.fobj&&this.props.fobj.gender==="Female"?"#e61356":"#345fdf")}}>{this.props.fobj.gender==="Male"?this.props.t("TREND_MALE_ASR"):this.props.fobj.gender==="Female"?this.props.t("TREND_FEMALE_ASR"):""} {this.state.minyear1} - {this.state.maxyear1}</h6>
                                <Col xs={9} style={{height:"235px",marginTop:"-20px"}}>
                                    {this.state.trendASRColors?<DynamicLineChart data={this.state.trendASRData} linestroke={2} x={this.props.t("YEAR_X")} y={this.props.t("ASR_X")} height={275} isshowlegend={false} colors={this.state.trendASRColors} />:<></>}
                                </Col>
                                <Col xs={3} style={{padding:"0px"}}>
                                    <ul className="twofact-linelegend">
                                        {this.state.trendASRDataColors && this.state.trendASRDataColors.length > 0?(this.state.trendASRDataColors.map((xitem, xidx) => {
                                            return <React.Fragment key={xidx}>{xidx<10?<li><div style={{background:(xitem.color?xitem.color:"#666")}}></div> {xitem.site}</li>:<></>}</React.Fragment>;
                                        })):<></>}
                                    </ul>
                                </Col>
                            </Row>
                        </Col>
                    </Col>
                    {/* first page end */}
                    {/* second page start */}
                    <div className="html2pdf__page-break"></div>
                    <Col xs={12} style={{height:'30px'}}></Col> 

                    <Col xs={12} className={"secondpage chart-containts"+(this.props.language!=="en"?" sub-lang":"")}>
                        <Col  className="chart-sub-card" style={{height:"340px"}}>
                            <Row style={{padding:"5px 0px"}}>
                                <Col xs={12}>
                                    <h6 className={"sub-title-single"+(this.props.language!=="en"?" sub-lang":"")} style={{ color:(this.props.fobj&&this.props.fobj.gender==="Female"?"#e61356":"#345fdf")}}>{this.props.fobj.gender==="Male"?this.props.t("AGE_SPECIFIC_CANCER_INCI_FEMALE"):this.props.fobj.gender==="Female"?this.props.t("AGE_SPECIFIC_CANCER_INCI_FEMALE"):""} {this.props.fobj.year}</h6>
                                    {this.state.isbardataloaded?<DynamicBarChart data={this.state.barFourthCatData} x={this.props.t("AGE_GROUP_X")} y={this.props.t("INC_RATE_PER")} colors={["#5297E0"]} height={290} isshowlegend={false} showdatalabel={true} iscolumn={true} angleType="ver" distributed={true} />:<></>}
                                </Col>
                            </Row>
                        </Col>
                        
                        <Row>
                            <Col xs={6} style={{paddingRight:"2px"}}>
                                <Col  className="chart-sub-card" style={{height:"335px"}}>
                                    <h6 className={"sub-title-single"+(this.props.language!=="en"?" sub-lang":"")} style={{ color:(this.props.fobj&&this.props.fobj.gender==="Female"?"#e61356":"#345fdf")}}>{this.props.fobj.gender==="Male"?this.props.t("AGE_RANGE_MOST_COMMON_MALE_0_19"):this.props.fobj.gender==="Female"?this.props.t("AGE_RANGE_MOST_COMMON_FEMALE_0_19"):""} {this.props.fobj.year}</h6>
                                    {this.state.isbardataloaded?<DynamicBarChart data={this.state.barFirstCatData} x={"Percentage out of all cancers"} y={this.props.t("CANCER_SITES_Y")} height={290} isshowlegend={false} showdatalabel={true} angleType="hori" distributed={true} />:<></>}   
                                </Col>      
                            </Col>

                            <Col xs={6} style={{paddingLeft:"2px"}}>
                                <Col  className="chart-sub-card" style={{height:"335px"}}>
                                    <h6 className={"sub-title-single"+(this.props.language!=="en"?" sub-lang":"")} style={{ color:(this.props.fobj&&this.props.fobj.gender==="Female"?"#e61356":"#345fdf")}}>{this.props.fobj.gender==="Male"?this.props.t("AGE_RANGE_MOST_COMMON_MALE_20_59"):this.props.fobj.gender==="Female"?this.props.t("AGE_RANGE_MOST_COMMON_FEMALE_20_59"):""} {this.props.fobj.year}</h6>
                                    {this.state.isbardataloaded?<DynamicBarChart data={this.state.barSecondCatData} x={"Percentage out of all cancers"} y={this.props.t("CANCER_SITES_Y")} height={290} isshowlegend={false} showdatalabel={true} angleType="hori" distributed={true} />:<></>}
                                </Col>         
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={6} style={{paddingRight:"2px"}}>
                                <Col  className="chart-sub-card" style={{height:"335px"}}>
                                    <h6 className={"sub-title-single"+(this.props.language!=="en"?" sub-lang":"")} style={{color:(this.props.fobj&&this.props.fobj.gender==="Female"?"#e61356":"#345fdf"),fontSize:(this.props.language!=="en"?"12px":"16px")}}>{this.props.fobj.gender==="Male"?this.props.t("AGE_RANGE_MOST_COMMON_MALE_60_PLUS"):this.props.fobj.gender==="Female"?this.props.t("AGE_RANGE_MOST_COMMON_FEMALE_60_PLUS"):""} {this.props.fobj.year}</h6>
                                    {this.state.isbardataloaded?<DynamicBarChart data={this.state.barThirdCatData} x={"Percentage out of all cancers"} y={this.props.t("CANCER_SITES_Y")} height={290} isshowlegend={false} showdatalabel={true} angleType="hori" distributed={true} />:<></>}
                                </Col>
                            </Col>          
                        </Row>
                        
                        {/* <Col  className="chart-sub-card" style={{height:"350px"}}>
                            <Row style={{padding:"5px 0px"}}>
                                <Col xs={7}>
                                    <h6 className="sub-title-single" style={{color:(this.props.fobj&&this.props.fobj.gender==="Female"?"#e61356":"#345fdf")}}>{this.props.fobj.gender==="Male"?this.props.t("AGE_RANGE_MOST_COMMON_MALE_60_PLUS"):this.props.fobj.gender==="Female"?this.props.t("AGE_RANGE_MOST_COMMON_FEMALE_60_PLUS"):""} {this.props.fobj.year}</h6>
                                    <DynamicBarChart data={this.state.barThirdCatData} x={"Percentage out of all cancers"} y={this.props.t("CANCER_SITES_Y")} colors={["#033FFF","#54E346"]} height={290} isshowlegend={false} showdatalabel={true} />
                                </Col>
                                <Col xs={5}>

                                </Col>
                            </Row>
                        </Col> */}
                    </Col>

                </Col>    
            </Col>
            
        </Col>
         );
    }
}

const mapDispatchToProps = dispatch => ({
    setResetState: (payload) => dispatch(logoutAction(payload)),
});

export default withTranslation()(withRouter(connect(null,mapDispatchToProps)(TwoPageFactSheet)));
