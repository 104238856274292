import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Col, Button, Row, ButtonGroup } from 'react-bootstrap';

import DynamicDonutChart from '../charttypes/donut';
import DynamicBarChart from '../charttypes/barchart';
import DynamicLineChart from '../charttypes/linechart';
import html2pdf from 'html2pdf.js';

import { submitCollection } from '../../_services/submit.service';
import { submitSets } from '../UiComponents/SubmitSets';
//import { alertService } from '../../_services/alert.service';
//import { numberWithCommas } from '../../_services/common.service';

import { logoutAction } from '../../actions/auth/login_action';

//import i18n from "i18next";
import { withTranslation } from "react-i18next";
import "../../_translations/i18n";
import "./PaediatricFactSheet.css";

import nccplogo from '../../assets/img/logo512.png';
import foldericon from '../../assets/img/slcr-logo.png';
import worldicon from '../../assets/img/maps/world.png';
import asiaicon from '../../assets/img/maps/asia.png';
import slicon from '../../assets/img/maps/srilanka.png';
import maleicon from '../../assets/img/male-icon.png';
import femaleicon from '../../assets/img/female-icon.png';

class PaediatricFactSheet extends React.Component{
    _isMounted = false;

    constructor(props){
        super(props);
        this.state = {
            samplecolors: ['#2a52be','#de6fa1','#da4040','#486090', '#00e699', '#a035ea','#35ead4', '#FF6600', '#ebc55e', '#409caf'],
            
            //
            newlyCancersData:{categories:[],series:[{name:"",data:[]}],colors:[]},
            newlyCancersMinYear:"", newlyCancersMaxYear:"",

            crudeIncRatesCancersData:{categories:[],series:[{name:"",data:[]}],colors:[]},
            crudeIncRatesCancersMinYear:"", crudeIncRatesCancersMaxYear:"",

            ageStanIncRatesCancersData:{categories:[],series:[{name:"",data:[]}],colors:[]},
            ageStanIncCancersMinYear:"", ageStanIncCancersMaxYear:"",

            ageSpecIncRatesCancersData_0_4:{categories:[],series:[{name:"",data:[]}],colors:[]},
            ageSpecIncRatesCancersData_5_9:{categories:[],series:[{name:"",data:[]}],colors:[]},
            ageSpecIncRatesCancersData_10_14:{categories:[],series:[{name:"",data:[]}],colors:[]},
            ageSpecIncRatesCancersData_15_19:{categories:[],series:[{name:"",data:[]}],colors:[]},
            ageSpecIncCancersMinYear:"", ageSpecIncCancersMaxYear:"",

            distributionOfChildhoodMaleCancers:{categories:[],series:[{name:"",data:[]}],colors:[]},
            distributionOfChildhoodFemaleCancers:{categories:[],series:[{name:"",data:[]}],colors:[]},

            legenddistributionOfChildhoodMaleCancers:{categories:[],series:[{name:"",data:[]}],colors:[]},
            legenddistributionOfChildhoodFemaleCancers:{categories:[],series:[{name:"",data:[]}],colors:[]},

            ASRWorldMale:"0",ASRSouthAsiaMale:"0",ASRSriLankaMale:"0",
            ASRWorldFemale:"0",ASRSouthAsiaFemale:"0",ASRSriLankaFemale:"0",

            maleNewCases:0, femaleNewCases:0
        }

        this.signinBtnElement = React.createRef();
    }
      
    componentDidMount(){
        this.getNewlyCancers();
        this.GetDistributionOfChildhoodCancers("Male");
        this.GetDistributionOfChildhoodCancers("Female");
        this.getGroupedAgeRangeGenderWiseCrudeRates();
        this.getGroupedAgeRangeGenderWiseAgeStandardizedIncidenceRates();
        this.getAgeRangeGenderWiseAgeSpecificRates("PAE-AR01");
        this.getAgeRangeGenderWiseAgeSpecificRates("PAE-AR02");
        this.getAgeRangeGenderWiseAgeSpecificRates("PAE-AR03");
        this.getAgeRangeGenderWiseAgeSpecificRates("PAE-AR04");
        this.getPedreacticASRSummary();
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    getPedreacticASRSummary = () =>{
        let sobj = {cancerSiteGroup:this.props.fobj.cancerSiteGroupId ,year:this.props.fobj.year, orgUnit:"001"};
        submitSets(submitCollection.getPedreacticASRSummary,sobj).then(res=> {
            if(res.status===true && res.extra.length>0){
               let asrworldm = 0; let asrsouthasiam = 0; let asrsrilankam = 0; let newCasesm = 0;
               let asrworldf = 0; let asrsouthasiaf = 0; let asrsrilankaf = 0; let newCasesf = 0;
                for (let i = 0; i < res.extra.length; i++) {
                   if(res.extra[i].gender==="Male"){
                        asrworldm = res.extra[i].commonData.asrGS !== undefined ?  res.extra[i].commonData.asrGS: 0 ;
                        asrsouthasiam = res.extra[i].commonData.asrSEA !== undefined ? res.extra[i].commonData.asrSEA: 0;
                        asrsrilankam = res.extra[i].commonData.asr !== undefined ? res.extra[i].commonData.asr : 0 ;
                        newCasesm = res.extra[i].commonData.count !== undefined ? parseInt(res.extra[i].commonData.count) : 0 ;
                   }
                   if(res.extra[i].gender==="Female"){
                    asrworldf = res.extra[i].commonData.asrGS !== undefined ?  res.extra[i].commonData.asrGS: 0 ;
                    asrsouthasiaf = res.extra[i].commonData.asrSEA !== undefined ? res.extra[i].commonData.asrSEA: 0;
                    asrsrilankaf = res.extra[i].commonData.asr !== undefined ? res.extra[i].commonData.asr : 0 ;
                    newCasesf = res.extra[i].commonData.count !== undefined ? parseInt(res.extra[i].commonData.count) : 0 ;
               }
                }
                this.setState({
                    ASRWorldMale:asrworldm,ASRSouthAsiaMale:asrsouthasiam, ASRSriLankaMale:asrsrilankam , maleNewCases:newCasesm,
                    ASRWorldFemale:asrworldf,ASRSouthAsiaFemale:asrsouthasiaf, ASRSriLankaFemale:asrsrilankaf , femaleNewCases:newCasesf
                });
            }
        });
    }

    GetDistributionOfChildhoodCancers = (gender) =>{//getProprtionOfLeadingCancers
        let sobj = {selCancerSites:[{id: 0,name: "All Sites",icdcode: "All Sites"}], gender: gender, totalNoOfResults:10,  maxYear:this.props.fobj.maxYear};
        submitSets(submitCollection.GetDistributionOfChildhoodCancers,sobj).then(res=> {
            if(res.status===true && res.extra.length>0){
                let data = res.extra;
                let chartData = {categories:[],series:[],colors:[]};
                var cursiteidx = undefined;
                for (let i = 0; i < data.length; i++) {
                    chartData.categories.push(data[i].cancerSiteName);
                    chartData.series.push(parseFloat(data[i].percentage));
                    chartData.colors.push(data[i].cancerSiteColor);
                }

                const reversedata = JSON.parse(JSON.stringify(chartData));
                /* reversedata.categories.reverse();
                reversedata.series.reverse();
                reversedata.colors.reverse(); */

                if(gender==="Male"){
                    this.setState({ legenddistributionOfChildhoodMaleCancers: chartData, distributionOfChildhoodMaleCancers:reversedata, selectedchartidx: cursiteidx });
                }
                else if(gender==="Female"){
                    this.setState({ legenddistributionOfChildhoodFemaleCancers: chartData, distributionOfChildhoodFemaleCancers:reversedata, selectedchartidx2: cursiteidx });
                }
            }
        });
        
    }

    getNewlyCancers = () =>{
        let sobj = {gender:"Both", ageRangeCode:"GAR01", minYear: this.props.fobj.minYear, maxYear: this.props.fobj.maxYear };
        submitSets(submitCollection.getGroupedAgeRangeGenderWiseCounts,sobj).then(res=> {
            if(res.status===true){
                let masdata = res.extra;
                let chartData = {categories:[],series:[{data:[]}],colors:["#033FFF", "#FF008B"]};
                let marr = []; let farr = [];
                var minyear = ""; var maxyear = "";
                
                for (let i = 0; i < masdata.length; i++) {
                    if(i === 0){
                        minyear = masdata[i].year;
                    } else if(i === (masdata.length - 1)){
                        maxyear = masdata[i].year;
                    }
                    chartData.categories.push(masdata[i].year);

                    for (let x = 0; x < masdata[i].values.length; x++) {
                        let item = masdata[i].values[x];
                        if(item.gender==="Male"){
                            marr.push(item.count);
                        }
                        else if (item.gender==="Female"){
                            farr.push(item.count);
                        }
                        
                    }
                    
                }

                chartData.series = [];
                chartData.series.push({name:"Male",data:marr});
                chartData.series.push({name:"Female",data:farr});
                //console.log(chartData);
                
                this.setState({newlyCancersData:chartData, newlyCancersMinYear:minyear, newlyCancersMaxYear:maxyear});
            }
        });
    }

    getGroupedAgeRangeGenderWiseCrudeRates = () =>{//Crude Incidence Rate of Childhood Cancers 0-19
        let sobj = {gender:"Both", ageRangeCode:"GAR01", minYear: this.props.fobj.minYear, maxYear: this.props.fobj.maxYear };
        submitSets(submitCollection.getGroupedAgeRangeGenderWiseCrudeRates,sobj).then(res=> {
            if(res.status===true){
                let masdata = res.extra;
                let chartData = {categories:[],series:[{data:[]}],colors:["#033FFF", "#FF008B"]};
                let marr = []; let farr = [];
                var minyear = ""; var maxyear = "";
                
                for (let i = 0; i < masdata.length; i++) {
                    if(i === 0){
                        minyear = masdata[i].year;
                    } else if(i === (masdata.length - 1)){
                        maxyear = masdata[i].year;
                    }
                    chartData.categories.push(masdata[i].year);

                    for (let x = 0; x < masdata[i].values.length; x++) {
                        let item = masdata[i].values[x];
                        if(item.gender==="Male"){
                            marr.push(item.count);
                        }
                        else if (item.gender==="Female"){
                            farr.push(item.count);
                        }
                        
                    }
                    
                }

                chartData.series = [];
                chartData.series.push({name:"Male",data:marr});
                chartData.series.push({name:"Female",data:farr});
                //console.log(chartData);
                
               this.setState({crudeIncRatesCancersData:chartData, crudeIncRatesCancersMinYear:minyear, crudeIncRatesCancersMaxYear:maxyear});
            }
        });
    }
    
    getGroupedAgeRangeGenderWiseAgeStandardizedIncidenceRates = () =>{//Age Standardized incidence rate of Childhood Cancers 0-19 
        let sobj = {gender:"Both", ageRangeCode:"GAR01", minYear: this.props.fobj.minYear, maxYear: this.props.fobj.maxYear };
        submitSets(submitCollection.getGroupedAgeRangeGenderWiseAgeStandardizedIncidenceRates,sobj).then(res=> {
            if(res.status===true){
                let masdata = res.extra;
                let chartData = {categories:[],series:[{data:[]}],colors:["#033FFF", "#FF008B"]};
                let marr = []; let farr = [];
                var minyear = ""; var maxyear = "";
                
                for (let i = 0; i < masdata.length; i++) {
                    if(i === 0){
                        minyear = masdata[i].year;
                    } else if(i === (masdata.length - 1)){
                        maxyear = masdata[i].year;
                    }
                    chartData.categories.push(masdata[i].year);

                    for (let x = 0; x < masdata[i].values.length; x++) {
                        let item = masdata[i].values[x];
                        if(item.gender==="Male"){
                            marr.push(item.count);
                        }
                        else if (item.gender==="Female"){
                            farr.push(item.count);
                        }
                        
                    }
                    
                }

                chartData.series = [];
                chartData.series.push({name:"Male",data:marr});
                chartData.series.push({name:"Female",data:farr});
                //console.log(chartData);
                
               this.setState({ageStanIncRatesCancersData:chartData, ageStanIncCancersMinYear:minyear, ageStanIncCancersMaxYear:maxyear});
            }
        });
    }

    getAgeRangeGenderWiseAgeSpecificRates = (range) =>{//Age Specific Incidence rate of Childhood cancers among
        let sobj = {gender:"Both", ageRangeCode:range, minYear: this.props.fobj.minYear, maxYear: this.props.fobj.maxYear };
        submitSets(submitCollection.getAgeRangeGenderWiseAgeSpecificRates,sobj).then(res=> {
            if(res.status===true){
                let masdata = res.extra;
                let chartData = {categories:[],series:[{data:[]}],colors:["#033FFF", "#FF008B"]};
                let marr = []; let farr = [];
                var minyear = ""; var maxyear = "";
                
                for (let i = 0; i < masdata.length; i++) {
                    if(i === 0){
                        minyear = masdata[i].year;
                    } else if(i === (masdata.length - 1)){
                        maxyear = masdata[i].year;
                    }
                    chartData.categories.push(masdata[i].year);

                    for (let x = 0; x < masdata[i].values.length; x++) {
                        let item = masdata[i].values[x];
                        if(item.gender==="Male"){
                            marr.push(item.count);
                        }
                        else if (item.gender==="Female"){
                            farr.push(item.count);
                        }
                        
                    }
                    
                }

                chartData.series = [];
                chartData.series.push({name:"Male",data:marr});
                chartData.series.push({name:"Female",data:farr});
                //console.log(chartData);

                if(range==="PAE-AR01"){
                    this.setState({ageSpecIncRatesCancersData_0_4:chartData});
                }
                else if(range==="PAE-AR02"){
                    this.setState({ageSpecIncRatesCancersData_5_9:chartData});
                }
                else if(range==="PAE-AR03"){
                    this.setState({ageSpecIncRatesCancersData_10_14:chartData});
                }
                if(range==="PAE-AR04"){
                    this.setState({ageSpecIncRatesCancersData_15_19:chartData, ageSpecIncCancersMinYear:minyear, ageSpecIncCancersMaxYear:maxyear});
                }
                
            }
        });
    }

    export = () =>{
        var element = document.getElementById('generating-section-pop');
        var pagebreak = {mode:"avoid-all"};
        var opt = {
            margin:       0,
            filename:     this.props.fobj.sheetName.replace(/ /g,'')+"_"+this.props.language+'.pdf',
            image:        { type: 'jpeg', quality: 0.98},
            html2canvas:  { scale: 5, logging: true, dpi: 192, letterRendering: true },
            jsPDF:        { unit: 'mm', format: 'a4', orientation: 'portrait', compress: true, precision: 8 },
            pagebreak: pagebreak
        };

        html2pdf().set(opt).from(element).toPdf().get('pdf').then(function (pdf) {
            var totalPages = pdf.internal.getNumberOfPages(); 
            var cdate = new Date();
            //print current pdf width & height to console
            
            for (var i = 1; i <= totalPages; i++) {
              pdf.setPage(i);
              pdf.setFontSize(10);
              pdf.setTextColor("#d04571");
              //divided by 2 to go center
              //var img = new Image()
              //img.src = '../../assets/img/logo512.png'
              //pdf.addImage(img,"png", 5, 1, 10, 10);
              //pdf.text('Page ' + i + ' of ' + totalPages, 10, 290,);
              pdf.text('National Cancer Registry : All rights reserved '+cdate.getFullYear(), 100, 293,'center');
            } 
            }).save();
    }

    shadeColor(color, percent) {

        var R = parseInt(color.substring(1,3),16);
        var G = parseInt(color.substring(3,5),16);
        var B = parseInt(color.substring(5,7),16);
    
        R = parseInt(R * (100 + percent) / 100);
        G = parseInt(G * (100 + percent) / 100);
        B = parseInt(B * (100 + percent) / 100);
    
        R = (R<255)?R:255;  
        G = (G<255)?G:255;  
        B = (B<255)?B:255;  
    
        var RR = ((R.toString(16).length===1)?"0"+R.toString(16):R.toString(16));
        var GG = ((G.toString(16).length===1)?"0"+G.toString(16):G.toString(16));
        var BB = ((B.toString(16).length===1)?"0"+B.toString(16):B.toString(16));
    
        return "#"+RR+GG+BB;
    }

    render(){
        //console.log(this.state);

        return (
        <Col className="main-container-col" style={{width:"815px"}} >
            
            <Col style={{marginBottom:"5px"}}>
                <ButtonGroup aria-label="Basic example" size="sm">
                    <Button onClick={()=>this.props.changeLanguage("en")} variant={this.props.language==="en"?"primary":"secondary"}>English</Button>
                    <Button onClick={()=>this.props.changeLanguage("sin")} variant={this.props.language==="sin"?"primary":"secondary"}>Sinhala</Button>
                    <Button onClick={()=>this.props.changeLanguage("ta")} variant={this.props.language==="ta"?"primary":"secondary"}>Tamil</Button>
                </ButtonGroup>
            
                <Col style={{position:"relative", float:"right"}}>
                    <Button variant="success" size="sm" onClick={()=>this.export()}>Download</Button>{" "}
                    <Button variant="secondary" size="sm" onClick={()=>this.props.changeViewType("mainView")}>Back</Button>
                </Col>
            </Col>
            
            <Col xs={12} style={{width:"830px",border:"1px solid #ccc",padding:"10px"}}>
                <Col xs={12} className="generating-section-pop pop-based overall-based" id="generating-section-pop">
                    {/* <Col className="overlay-bg"></Col> */}
                    <Col className={"gs-title"+(this.props.language!=="en"?" sub-lang":"")}>
                        <Row style={{paddingLeft:"10px"}}>
                            <Col xs={1}>
                                <img alt="" src={foldericon} width="55px" id="mainLogo" style={{marginTop:"-4px"}} />
                            </Col>
                            <Col xs={11}>
                                <h6 className="main-heading-pop">{this.props.t("NCCP_SUB")}</h6>
                                <h5 className="sub-heading-pop">{this.props.t("NCCP")}</h5>
                                <img alt="" src={nccplogo} className="fsheet-logo"/>
                            </Col>    
                        </Row>
                    </Col>

                    <Col className={"chart-title-col"+(this.props.language!=="en"?" sub-lang":"")} style={{background:(this.props.fobj.color ? this.props.fobj.color:"#345fdf")}}>
                    {this.props.language==="en"?this.props.fobj.sheetName:this.props.language==="sin"?this.props.fobj.sinhalaName:this.props.language==="ta"?this.props.fobj.tamilName:""}&nbsp;-&nbsp;{this.props.fobj.maxYear}
                        <div className="year-view"><span>{this.props.t("FACT_SHEET")}</span></div>
                    </Col>

                    <Col xs={12} className={"chart-containts"+(this.props.language!=="en"?" sub-lang":"")}>
                        <Row>
                            <Col xs={6} style={{paddingRight:"3px", marginRight:"-5px"}}>
                                <Col  className="chart-sub-card" style={{position:"relative",height:"300px"}}>
                                    <h6 className="sub-title-single" style={{color:this.props.fobj.color,}}>{this.props.t("PED_DIST_CANCERS_MALE")} {this.props.fobj.maxYear}</h6>
                                    <Col xs={10} style={{marginLeft:"-45px",marginTop:"18px"}}>
                                        {this.state.distributionOfChildhoodMaleCancers?<DynamicDonutChart data={this.state.legenddistributionOfChildhoodMaleCancers} height={170} startangle={5} isshowlegend={false} />:<></>}
                                    </Col>
                                    <div className="chartview-legend" style={{top:"60px"}}>
                                        <ul>
                                            {this.state.legenddistributionOfChildhoodMaleCancers?this.state.legenddistributionOfChildhoodMaleCancers.categories.map((xitem,xidx) => {
                                                return <li key={xidx} style={{fontSize:"11px"}}><div style={{background: this.state.legenddistributionOfChildhoodMaleCancers.colors[xidx]}}></div> {xitem}</li>;
                                            }):<></>}
                                        </ul>
                                    </div>
                                </Col>
                            </Col>
                            <Col xs={6} style={{paddingRight:"3px", marginLeft:"-5px"}}>
                                <Col  className="chart-sub-card" style={{position:"relative", height:"300px"}}>
                                    <h6 className="sub-title-single" style={{color:this.props.fobj.color}}>{this.props.t("PED_DIST_CANCERS_FEMALE")} {this.props.fobj.maxYear}</h6>
                                    <Col xs={10} style={{marginLeft:"-45px",marginTop:"18px"}}>
                                        {this.state.distributionOfChildhoodFemaleCancers?<DynamicDonutChart data={this.state.legenddistributionOfChildhoodFemaleCancers} height={170} startangle={3} isshowlegend={false} />:<></>}
                                    </Col>
                                    <div className="chartview-legend" style={{top:"60px"}}>
                                        <ul>
                                            {this.state.legenddistributionOfChildhoodFemaleCancers?this.state.legenddistributionOfChildhoodFemaleCancers.categories.map((xitem,xidx) => {
                                                return <li key={xidx} style={{fontSize:"11px"}}><div style={{background: this.state.legenddistributionOfChildhoodFemaleCancers.colors[xidx]}}></div> {xitem}</li>;
                                            }):<></>}
                                        </ul>
                                    </div>
                                </Col>
                            </Col>
                        </Row>

                        <Col  className="chart-sub-card" style={{height:"310px"}}>
                            <h6 className="sub-title-single" style={{color:this.props.fobj.color}}>{this.props.t("PED_NEW_0_19_CANCERS")} {this.state.newlyCancersMinYear}&nbsp;-&nbsp;{this.state.newlyCancersMaxYear}</h6>
                            {this.state.newlyCancersData.series.length>0?<DynamicBarChart data={this.state.newlyCancersData} x={"Year"} y={"No. of new patients 0-19 years"} colors={["#5297E0"]} height={260} isshowlegend={true} showdatalabel={false} iscolumn={true} angleType="ver" distributed={false} />:<></>}
                        </Col>

                        <Row>
                            <Col xs={8} style={{paddingRight:"3px", marginRight:"-2px"}}>
                                <Col xs={12} className="chart-sub-card" style={{height:"320px"}}>
                                    <h6 className="sub-title-single" style={{color:this.props.fobj.color}}>{this.props.t("PED_CRUDE_INC_RATE_CANCERS")} {this.state.crudeIncRatesCancersMinYear}&nbsp;-&nbsp;{this.state.crudeIncRatesCancersMaxYear}</h6>
                                    {this.state.crudeIncRatesCancersData.series.length>0?<DynamicLineChart data={this.state.crudeIncRatesCancersData} x={"Years"} y={"Incidence rate per 1000,000"} height={275} isshowlegend={true} linestroke={3} ismarkers={false} isdatalable={false} colors={["#033FFF", "#FF008B"]} legendOffsetY={-10} />:<></>}
                                </Col>
                            </Col>
                            <Col xs={4} style={{paddingLeft:"5px", paddingRight:"9px"}} className="newcases-sec">
                                <Col xs={12} className="chart-sub-card" style={{height:"320px"}}>
                                    <h6 className="sub-title-single" style={{color:"#fff",background:(this.props.fobj&&this.props.fobj.color?this.props.fobj.color:"#d24572"),textAlign:"center",padding:"6px 0px",height:"auto",borderRadius:"4px"}}>{this.props.t("NO_OF_NEW_CASES_IN")} {this.props.fobj.year}</h6>
                                    <Col className="male-sec" xs={12} style={{padding:"0px 10px"}}>
                                        <Col className="gender-view">
                                            <h6 className="text-center">{this.props.t("Male")}</h6>
                                            <img src={maleicon} width="28px" alt="male"/>
                                            <h5 className="text-center">{this.state.maleNewCases}</h5>    
                                        </Col>
                                    </Col>  
                                    <Col className="fmale-sec" xs={12} style={{padding:"0px 10px"}}>
                                        <Col className="gender-view fmale">
                                            <h6 className="text-center">{this.props.t("Female")}</h6>
                                            <img src={femaleicon} width="28px" alt="female"/>
                                            <h5 className="text-center">{this.state.femaleNewCases}</h5>
                                        </Col>
                                    </Col>     
                               </Col>
                            </Col>
                        </Row>                    

                    </Col>

                    {/* first page end */}
                    {/* second page start */}
                    <div className="html2pdf__page-break"></div>
                    <Col xs={12} style={{height:'40px'}}></Col> 

                    <Col xs={12} className={"chart-containts"+(this.props.language!=="en"?" sub-lang":"")}>
                        <Row>
                            <Col xs={7} style={{paddingRight:"3px", marginRight:"-2px"}}>
                                <Col  className="chart-sub-card" style={{height:"385px"}}>
                                    <h6 className="sub-title-single" style={{color:this.props.fobj.color,marginBottom:"30px"}}>{this.props.t("PED_AGE_STAN_INC_RATE_CANCERS")} {this.state.ageStanIncCancersMinYear}&nbsp;-&nbsp;{this.state.ageStanIncCancersMaxYear}</h6>
                                    {this.state.ageStanIncRatesCancersData.series.length>0?<DynamicLineChart data={this.state.ageStanIncRatesCancersData} x={"Years"} y={"ASR per 1000,000"} height={300} isshowlegend={true} linestroke={3} ismarkers={false} isdatalable={false} colors={["#033FFF", "#FF008B"]} legendOffsetY={-10} />:<></>}
                                </Col>
                            </Col>
                            <Col xs={5} style={{paddingLeft:"5px"}} >
                                <Col  className="chart-sub-card" style={{height:"190px"}}>
                                    <Col xs={12} className={"summary-section both-view"}> {/* both-view */}
                                    <h6 style={{textAlign:"center"}} className="sub-content-header">{this.props.t("Male")}</h6>
                                        <Row style={{padding: "0px 5px"}}>
                                            <Col xs={12} className="summary-item world">
                                                <Row>
                                                    <Col xs={2} className="img-col"><img alt="" src={worldicon} /></Col>
                                                    <Col xs={6} className="data-col">
                                                        <h6>ASR: {this.props.t("WORLD")}</h6>
                                                      
                                                    </Col>    
                                                    <Col xs={4} className="percentage-col">
                                                        <Col xs={12} className="percentage-sub" style={{width:"78%"}}>
                                                            <h5>{this.state.ASRWorldMale}</h5>
                                                        </Col>
                                                    </Col>
                                                </Row>
                                            </Col>

                                            <Col xs={12} className="summary-item asia">
                                                <Row>
                                                    <Col xs={2} className="img-col"><img alt="" src={asiaicon} /></Col>
                                                    <Col xs={6} className="data-col">
                                                        <h6>ASR: {this.props.t("SOUTH_ASIA")}</h6>
                                                    </Col>    
                                                    <Col xs={4} className="percentage-col">
                                                        <Col xs={12} className="percentage-sub" style={{width:"58%"}}>
                                                            <h5>{this.state.ASRSouthAsiaMale}</h5>
                                                        </Col>
                                                    </Col>
                                                </Row>
                                            </Col>

                                            <Col xs={12} className="summary-item sl">
                                                <Row>
                                                    <Col xs={2} className="img-col"><img alt="" src={slicon} /></Col>
                                                    <Col xs={6} className="data-col">
                                                        <h6>ASR: {this.props.t("SRI_LANKA")}</h6>
                                                    </Col> 
                                                    <Col xs={4} className="percentage-col">
                                                        <Col xs={12} className="percentage-sub" style={{width:"38%"}}>
                                                            <h5>{this.state.ASRSriLankaMale}</h5>
                                                        </Col>
                                                    </Col>   
                                                </Row>
                                            </Col>    
                                        </Row>

                                    </Col>
                                </Col>

                                <Col  className="chart-sub-card" style={{height:"190px"}}>
                                    <Col xs={12} className={"summary-section both-view"}> {/* both-view */}
                                        <h6 style={{textAlign:"center"}} className="sub-content-header fmale">{this.props.t("Female")}</h6>
                                        <Row style={{padding: "0px 5px"}}>
                                            <Col xs={12} className="summary-item world">
                                                <Row>
                                                    <Col xs={2} className="img-col"><img alt="" src={worldicon} /></Col>
                                                    <Col xs={6} className="data-col">
                                                        <h6>ASR: {this.props.t("WORLD")}</h6>
                                                      
                                                    </Col>    
                                                    <Col xs={4} className="percentage-col">
                                                        <Col xs={12} className="percentage-sub" style={{width:"78%"}}>
                                                            <h5>{this.state.ASRWorldFemale}</h5>
                                                        </Col>
                                                    </Col>
                                                </Row>
                                            </Col>

                                            <Col xs={12} className="summary-item asia">
                                                <Row>
                                                    <Col xs={2} className="img-col"><img alt="" src={asiaicon} /></Col>
                                                    <Col xs={6} className="data-col">
                                                        <h6>ASR: {this.props.t("SOUTH_ASIA")}</h6>
                                                    </Col>    
                                                    <Col xs={4} className="percentage-col">
                                                        <Col xs={12} className="percentage-sub" style={{width:"58%"}}>
                                                            <h5>{this.state.ASRSouthAsiaFemale}</h5>
                                                        </Col>
                                                    </Col>
                                                </Row>
                                            </Col>

                                            <Col xs={12} className="summary-item sl">
                                                <Row>
                                                    <Col xs={2} className="img-col"><img alt="" src={slicon} /></Col>
                                                    <Col xs={6} className="data-col">
                                                        <h6>ASR: {this.props.t("SRI_LANKA")}</h6>
                                                    </Col> 
                                                    <Col xs={4} className="percentage-col">
                                                        <Col xs={12} className="percentage-sub" style={{width:"38%"}}>
                                                            <h5>{this.state.ASRSriLankaFemale}</h5>
                                                        </Col>
                                                    </Col>   
                                                </Row>
                                            </Col>    
                                        </Row>

                                    </Col>
                                </Col>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={6} style={{paddingRight:"3px"}} >
                                <Col  className="chart-sub-card" style={{height:"320px"}}>
                                    <h6 className="sub-title-single" style={{color:this.props.fobj.color}}>{this.props.t("PED_AGE_SPEC_INC_RATE_CANCERS_0_4")} {this.state.ageSpecIncCancersMinYear}&nbsp;-&nbsp;{this.state.ageSpecIncCancersMaxYear}</h6>
                                    {this.state.ageSpecIncRatesCancersData_0_4.series.length>0?<DynamicLineChart data={this.state.ageSpecIncRatesCancersData_0_4} x={"Years"} y={"Age Spec. Inc. rate per 1000,000"} height={275} isshowlegend={true} linestroke={3} ismarkers={false} isdatalable={false} colors={["#033FFF", "#FF008B"]} legendOffsetY={-10} />:<></>}
                                </Col>
                            </Col>
                            <Col xs={6} style={{paddingLeft:"3px"}}>
                                <Col  className="chart-sub-card" style={{height:"320px"}}>
                                    <h6 className="sub-title-single" style={{color:this.props.fobj.color}}>{this.props.t("PED_AGE_SPEC_INC_RATE_CANCERS_5_9")} {this.state.ageSpecIncCancersMinYear}&nbsp;-&nbsp;{this.state.ageSpecIncCancersMaxYear}</h6>
                                    {this.state.ageSpecIncRatesCancersData_5_9.series.length>0?<DynamicLineChart data={this.state.ageSpecIncRatesCancersData_5_9} x={"Years"} y={"Age Spec. Inc. rate per 1000,000"} height={275} isshowlegend={true} linestroke={3} ismarkers={false} isdatalable={false} colors={["#033FFF", "#FF008B"]} legendOffsetY={-10} />:<></>}
                                </Col>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={6} style={{paddingRight:"3px"}}>
                                <Col  className="chart-sub-card" style={{height:"320px"}}>
                                    <h6 className="sub-title-single" style={{color:this.props.fobj.color}}>{this.props.t("PED_AGE_SPEC_INC_RATE_CANCERS_10_14")} {this.state.ageSpecIncCancersMinYear}&nbsp;-&nbsp;{this.state.ageSpecIncCancersMaxYear}</h6>
                                    {this.state.ageSpecIncRatesCancersData_10_14.series.length>0?<DynamicLineChart data={this.state.ageSpecIncRatesCancersData_10_14} x={"Years"} y={"Age Spec. Inc. rate per 1000,000"} height={275} isshowlegend={true} linestroke={3} ismarkers={false} isdatalable={false} colors={["#033FFF", "#FF008B"]} legendOffsetY={-10} />:<></>}
                                </Col>
                            </Col>
                            <Col xs={6} style={{paddingLeft:"3px"}}>
                                <Col  className="chart-sub-card" style={{height:"320px"}}>
                                    <h6 className="sub-title-single" style={{color:this.props.fobj.color}}>{this.props.t("PED_AGE_SPEC_INC_RATE_CANCERS_15_19")} {this.state.ageSpecIncCancersMinYear}&nbsp;-&nbsp;{this.state.ageSpecIncCancersMaxYear}</h6>
                                    {this.state.ageSpecIncRatesCancersData_15_19.series.length>0?<DynamicLineChart data={this.state.ageSpecIncRatesCancersData_15_19} x={"Years"} y={"Age Spec. Inc. rate per 1000,000"} height={275} isshowlegend={true} linestroke={3} ismarkers={false} isdatalable={false} colors={["#033FFF", "#FF008B"]} legendOffsetY={-10} />:<></>}
                                </Col>
                            </Col>
                        </Row>
                    </Col>

                </Col>    
            </Col>
            
        </Col>
         );
    }
}

const mapDispatchToProps = dispatch => ({
    setResetState: (payload) => dispatch(logoutAction(payload)),
});

export default withTranslation()(withRouter(connect(null,mapDispatchToProps)(PaediatricFactSheet)));
