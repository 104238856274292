export const TRANSLATIONS_SIN = {
    NCCP:"ජාතික පිළිකා පාලන වැඩසටහන",
    NCCP_SUB:"ශ්‍රී ලංකාවේ ජාතික පිළිකා ලේඛකාධිකාරය",
    CANCER: "පිළිකා",
    CANCER_SITE:"පිළිකාව",
    FACT_SHEET:"කරුණු පත්‍ර",
    TO: "-",

    Male:"පිරිමි",
    Female:"ගැහැණු",
    Both:"ස්ත්‍රී පුරුෂ දෙපාර්ශවයම",

    WORLD:"ලෝකය",
    SOUTH_ASIA:"දකුණු ආසියාව",
    SRI_LANKA:"ශ්‍රී\u00A0ලංකාව",

    NO_OF_NEW_CASES_IN:"නව සිදුවීම් සංඛ්යාව ",
    YEAR:"වර්ෂය",
    AGE_GROUP:"වයස් කණ්ඩායම",
    RATE:"Rate",
    RATE_PER_POPULATION:"Per 100,000 population",
    MOST_COMMON_CANCERS_FEMALE:"කාන්තාවන් අතර බහුලව දක්නට ලැබෙන පිළිකා",
    MOST_COMMON_CANCERS_MALE:"පිරිමින් අතර බහුලව දක්නට ලැබෙන පිළිකා",
    CASES:"සිදුවීම්",
    Colombo:"කොළඹ",
    DISTRICT: "දිස්ත්රික්කය",

    INCIDENCE_MALE:"සිදුවීම පිරිමි",
    INCIDENCE_FEMALE:"සිදුවීම ගැහැණු",
    MORTALITY_MALE:"පිරිමි මරණ",
    MORTALITY_FEMALE:"කාන්තා මරණ",

    TREND_MALE_ASR:"ශ්‍රී ලංකාවේ පිරිමි පිළිකා ASR අනුපාතයන්ගේ නැඹුරුව",
    TENDS_MALE_CR:"ශ්‍රී ලංකාවේ පිරිමි පිළිකා CR අනුපාතයන්ගේ නැඹුරුව",

    TREND_FEMALE_ASR:"ශ්‍රී ලංකාවේ කාන්තා පිළිකා ASR අනුපාතයන්ගේ නැඹුරුව",
    TENDS_FEMALE_CR:"ශ්‍රී ලංකාවේ කාන්තා CR අනුපාතයන්ගේ නැඹුරුව",

    POP_TITLE1 :"PBCR සහ NCR හි පිළිකා සිදුවීම් අනුපාතය සංසන්දනය",
    POP_TITLE2:"ප්‍රමුඛ පිළිකා - NCR සහ PBCR වල වර්ධන වේගය",
    POP_TITLE3: "සිදුවීම්/මරණ අනුපාතය සංසන්දනය කිරීම",

    SINGLE_T1_CervixASR:"ශ්‍රී ලංකාවේ ගැබ්ගෙල පිළිකා වල ASR හි ප්‍රවනතාවය",
    SINGLE_T2_CervixASR:"ශ්‍රී ලංකාවේ ගැබ්ගෙල පිළිකා වල වයස් නිශ්චිත සිදුවීම් අනුපාතය",

    SINGLE_T1_BreastASR:"ශ්‍රී ලංකාවේ පියයුරු පිළිකා පිළිබඳ ASR හි ප්‍රවනතාවය",
    SINGLE_T2_BreastASR:"ශ්‍රී ලංකාවේ පියයුරු පිළිකා වල වයස් නිශ්චිත සිදුවීම් අනුපාතය",

    SINGLE_T1_LTMASR:"ශ්‍රී ලංකාවේ තොල්, දිව සහ මුඛ පිළිකා වල ASR හි ප්‍රවනතාවය",
    SINGLE_T2_LTMASR:"ශ්‍රී ලංකාවේ තොල්, දිව සහ මුඛ පිළිකා වල වයස් නිශ්චිත සිදුවීම් අනුපාතය",

    SINGLE_T1_LungASR:"ශ්‍රී ලංකාවේ පෙනහළු පිළිකා වල ASR\u00A0හි ප්‍රවනතාවය",
    SINGLE_T2_LungASR:"වර්ෂය තුළ ශ්‍රී ලංකාවේ පෙනහළු පිළිකා වල වයස් නිශ්චිත සිදුවීම් අනුපාතය",

    SINGLE_T1_ThyroidASR:"ශ්‍රී ලංකාවේ තයිරොයිඩ් පිළිකාවේ ASR\u00A0හි ප්‍රවනතාවය",
    SINGLE_T2_ThyroidASR:"ශ්‍රී ලංකාවේ තයිරොයිඩ් පිළිකා වල වයස් නිශ්චිත සිදුවීම් අනුපාතය",

    SINGLE_T1_ColonRectumASR:"ශ්‍රී ලංකාවේ මහා බඩවැලේ සහ ගුදමාර්ගයේ පිළිකා වල ASR\u00A0හි ප්‍රවනතාවය",
    SINGLE_T2_ColonRectumASR:"ශ්‍රී ලංකාවේ මහා බඩවැලේ සහ ගුදමාර්ගයේ පිළිකා වල වයස් නිශ්චිත සිදුවීම් අනුපාතය",

    SINGLE_T1_OesophagusASR:"ශ්‍රී ලංකාවේ ගලනාලය පිළිකාවේ ASR හි ප්‍රවනතාවය",
    SINGLE_T2_OesophagusASR:"ශ්‍රී ලංකාවේ ගලනාලය පිළිකා වල වයස් නිශ්චිත සිදුවීම් අනුපාතය",

    AGE_RANGE_MOST_COMMON_FEMALE_0_19:"ශ්‍රී ලංකාවේ කාන්තාවන් අතර 0-19 වයස් කාණ්ඩ වල ප්‍රමුඛ පිළිකා",
    AGE_RANGE_MOST_COMMON_FEMALE_20_59:"ශ්‍රී ලංකාවේ කාන්තාවන් අතර 20-59 වයස් කාණ්ඩ වල ප්‍රමුඛ පිළිකා",
    AGE_RANGE_MOST_COMMON_FEMALE_60_PLUS:"ශ්‍රී ලංකාවේ කාන්තාවන් අතර වයස අවුරුදු 60\u00A0ට වැඩි වයස් කාණ්ඩ වල පිළිකා වල ඉදිරියෙන්ම සිටින අය",
    AGE_SPECIFIC_CANCER_INCI_FEMALE:"ශ්‍රී ලංකාවේ වයස් විශේෂිත පිළිකා අනුපාත",

    AGE_RANGE_MOST_COMMON_MALE_0_19:"ශ්‍රී ලංකාවේ පිරිමින් අතර 0-19 වයස් කාණ්ඩ වල ප්‍රමුඛ පිළිකා",
    AGE_RANGE_MOST_COMMON_MALE_20_59:"ශ්‍රී ලංකාවේ පිරිමින් අතර 20-59 වයස් කාණ්ඩ වල ප්‍රමුඛ පිළිකා",
    AGE_RANGE_MOST_COMMON_MALE_60_PLUS:"ශ්‍රී ලංකාවේ පිරිමින් අතර වයස අවුරුදු 60\u00A0ට වැඩි වයස් කාණ්ඩ වල පිළිකා වල ඉදිරියෙන්ම සිටින අය",
    AGE_SPECIFIC_CANCER_INCI_MALE:"ශ්‍රී ලංකාවේ වයස් විශේෂිත පිළිකා අනුපාත",

    OVB_ASR_BOTH:"සමස්ත පිළිකා ASR ",
    OVB_CR_BOTH:"සමස්ත පිළිකා CR ",
    OVB_CAN_INC_AGE:"වයස් කණ්ඩායම් අනුව පිළිකා ඇතිවීම",
    OVB_TOP10_CAN:"ශ්‍රී ලංකාවේ ප්‍රථම පිළිකාවන් දහය",
    OVB_ASR_PER_SEX_TOP10:"ASR ස්ත්‍රී පුරුෂ භාවය අනුව පළමු පිළිකා දහය",

    PED_NEW_0_19_CANCERS:"ශ්‍රී ලංකාවේ අලුතින් වාර්තා වූ 0-19 ළමා පිළිකා සංඛ්‍යාව",
    PED_CRUDE_INC_RATE_CANCERS:"වයස අවුරුදු 0-19 අතර ළමා පිළිකා වර්‍ගයේ අනුපාතය",
    PED_AGE_STAN_INC_RATE_CANCERS:"0-19 සිට ළමා වියේ පිළිකාවන්හි වූ සිදුවීම් අනුපාතය",
    PED_AGE_SPEC_INC_RATE_CANCERS_0_4:"අවුරුදු 0-4 අතර වයස් නිශ්චිත ළමා රෝග පිළිකා අනුපාතය",
    PED_AGE_SPEC_INC_RATE_CANCERS_5_9:"අවුරුදු 5-9 අතර වයස් නිශ්චිත ළමා රෝග පිළිකා අනුපාතය",
    PED_AGE_SPEC_INC_RATE_CANCERS_10_14:"අවුරුදු 10-14 අතර වයස් නිශ්චිත ළමා රෝග පිළිකා අනුපාතය",
    PED_AGE_SPEC_INC_RATE_CANCERS_15_19:"අවුරුදු 15-19 අතර වයස් නිශ්චිත ළමා රෝග පිළිකා අනුපාතය",
    PED_DIST_CANCERS_MALE:"ශ්‍රී ලංකාවේ අවුරුදු 0-19 අතර පිරිමි පිළිකා",
    PED_DIST_CANCERS_FEMALE:"ශ්‍රී ලංකාවේ අවුරුදු 0-19 අතර ස්ත්‍රී පිළිකා",

    //chart x, y axises
    //X
    CRUDE_RATE_X:"Crude Rate / 100,000 Pop.",
    ASR_X:"ASR / 100,000 Pop",
    AGE_SPEC_RATE_X:"Age Specific rate / 100,000 Pop.",
    AGE_RANGE_X:"Age Range",
    YEAR_X:"Year",
    AGE_GROUP_X:"Age Group",

    //Y
    CANCER_SITES_Y:"Cancer Sites",
    AGE_RANGE_Y:"Age Range",
    INCI_RATE_Y:"Incidence Rate",
    CRUDE_RATE_Y:"Crude Rate",
    ASR_Y:"ASR",
    INC_RATE_PER:"Incidence rate per 100,000 pop"
};