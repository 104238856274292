import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Col, Button, Row, ButtonGroup } from 'react-bootstrap';

import DynamicBarChart from '../charttypes/barchart';
import DynamicLineChart from '../charttypes/linechart';
import html2pdf from 'html2pdf.js';

import { submitCollection } from '../../_services/submit.service';
import { submitSets } from '../UiComponents/SubmitSets';
//import { alertService } from '../../_services/alert.service';
import { numberWithCommas } from '../../_services/common.service';

import { logoutAction } from '../../actions/auth/login_action';

//import i18n from "i18next";
import { withTranslation } from "react-i18next";
import "../../_translations/i18n";
import "./populationBased.css";

import nccplogo from '../../assets/img/logo512.png';
import foldericon from '../../assets/img/slcr-logo.png';
import asiaicon from '../../assets/img/maps/colombo.png';
import slicon from '../../assets/img/maps/srilanka.png';

class PopulationBasedFactSheet extends React.Component{
    _isMounted = false;

    constructor(props){
        super(props);
        this.state = {
            orgUnit:"1020",
            SlCounts:{asr: "0",count: "0",cr: "0"},
            districtCounts:{asr: "0",count: "0",cr: "0"},
            InsMortChartData:{categories:[],series:[{name:"",data:[]}]},
            
            maleNcrPbcrData:{categories:[],series:[{name:"",data:[]}]},
            femaleNcrPbcrData:{categories:[],series:[{name:"",data:[]}]},
            minyear1: "-", maxyear1: "-",

            maleNcrPbcrSiteWiseData:{categories:[],series:[{name:"",data:[]},]},
            femaleNcrPbcrSiteWiseData:{categories:[],series:[{name:"",data:[]},]},
            minyear2: "-", maxyear2: "-",
            
            
        }

        this.signinBtnElement = React.createRef();
    }
      
    componentDidMount(){
        // if(this.props.signedobj===null || this.props.signedobj.signinDetails.signinDetails===false){
        //     this.props.handleSignObj({signinDetails:false, username:""});
        //     this.props.history.push("/");
        // }
        //console.log(this.props.fobj);
        this.getOUWiseCRAndMortalityRatesMaleAndFemaleOverYears();
        this.getSLCounts(); this.getDistrictCounts();
        this.getNCRAndPBCROverYears("Male"); this.getNCRAndPBCROverYears("Female");
        this.getNCRAndPBCRCancerSiteWise("Male");this.getNCRAndPBCRCancerSiteWise("Female");
    }

    componentWillUnmount(){
        this._isMounted = false;
        
    }

    getOUWiseCRAndMortalityRatesMaleAndFemaleOverYears = () =>{
        let sobj = {orgUnit:this.props.fobj.subOrgUnit,maxYear:this.props.fobj.maxYear,minYear:this.props.fobj.minYear};
        submitSets(submitCollection.getOUWiseCRAndMortalityRatesMaleAndFemaleOverYears,sobj).then(res=> {
            if(res.status===true){
                let data = res.extra;
                let InsMortChartData = {categories:[],series:[{name:"",data:[]}]};
                let maleInsarray = [];let maleMortarray = [];let femaleInsarray = [];let femaleMortarray = [];
                var minyear = ""; var maxyear = "";
                for (let i = 0; i < data.length; i++) {
                    if(i === 0){
                        minyear = data[i].year;
                    } else if(i === (data.length - 1)){
                        maxyear = data[i].year;
                    }

                    InsMortChartData.categories.push(data[i].year);
                    for (let x = 0; x < data[i].values.length; x++) {
                        let item = data[i].values[x];
                        if(item.name==="Incidence Male"){
                            maleInsarray.push(data[i].values[x].rate);
                        }
                        else if (item.name==="Mortality Male"){
                            maleMortarray.push(data[i].values[x].rate);
                        }
                        else if (item.name==="Incidence Female"){
                            femaleInsarray.push(data[i].values[x].rate);
                        }
                        else if (item.name==="Mortality Female"){
                            femaleMortarray.push(data[i].values[x].rate);
                        }
                    }
                }
                InsMortChartData.series = [];
                InsMortChartData.series.push({name:"Incidence Male",data:maleInsarray});
                InsMortChartData.series.push({name:"Mortality Male",data:maleMortarray});
                InsMortChartData.series.push({name:"Incidence Female",data:femaleInsarray});
                InsMortChartData.series.push({name:"Mortality Female",data:femaleMortarray});
                this.setState({InsMortChartData:InsMortChartData, minyear1:minyear, maxyear1:maxyear });
            }
        });
    }

    getSLCounts = () =>{
        let sobj = {cancerSiteGroup:"AllSites", year:this.props.fobj.year, orgUnit:this.props.fobj.orgUnit};
        submitSets(submitCollection.getCommonCounts,sobj).then(res=> {
            if(res.status===true){
                let SlCounts = {asr: "0",count: "0",cr: "0"};
                for (let i = 0; i < res.extra.length; i++) {
                    if(res.extra[i].gender==="Both"){
                        SlCounts = res.extra[i].commonData;
                    }
                }
                
                this.setState({SlCounts:SlCounts});
            }
        });
    }

    getDistrictCounts = () =>{
        let sobj = {cancerSiteGroup:"AllSites",year:this.props.fobj.year, orgUnit:this.props.fobj.subOrgUnit};
        submitSets(submitCollection.getCommonCounts,sobj).then(res=> {
            if(res.status===true){
                let districtCounts = {asr: "0",count: "0",cr: "0"};
                for (let i = 0; i < res.extra.length; i++) {
                   if(res.extra[i].gender==="Both"){
                    districtCounts = res.extra[i].commonData;
                   }
                }
                this.setState({districtCounts: districtCounts});
            
            }
        });
    }

    getNCRAndPBCROverYears = (gender) =>{
        let sobj = {orgUnit:this.props.fobj.subOrgUnit, maxYear:this.props.fobj.maxYear,minYear:this.props.fobj.minYear,gender:gender};
        submitSets(submitCollection.getNCRAndPBCROverYears,sobj).then(res=> {
            if(res.status===true){
                let data = res.extra;
                let ncrPbcrData = {categories:[],series:[{name:"",data:[]}]};
                let ncrarray = [];let pbcrarray = [];
                var minyear = ""; var maxyear = "";
                for (let i = 0; i < data.length; i++) {
                    if(i === 0){
                        minyear = data[i].year;
                    } else if(i === (data.length - 1)){
                        maxyear = data[i].year;
                    }
                    ncrPbcrData.categories.push(data[i].year);
                    for (let x = 0; x < data[i].values.length; x++) {
                        let item = data[i].values[x];
                        if(item.name==="NCR SriLanka" || item.name==="NCR Sri Lanka"){
                            ncrarray.push(data[i].values[x].rate);
                        }
                        else if (item.name==="PBCR Colombo"){
                            pbcrarray.push(data[i].values[x].rate);
                        }
                        
                    }
                }
                ncrPbcrData.series = [];
                ncrPbcrData.series.push({name:"NCR Sri Lanka",data:ncrarray});
                ncrPbcrData.series.push({name:"PBCR Colombo",data:pbcrarray});

                if(gender==="Male"){
                    this.setState({maleNcrPbcrData:ncrPbcrData, minyear2:minyear, maxyear2:maxyear });
                }
                else if(gender==="Female"){
                    this.setState({femaleNcrPbcrData:ncrPbcrData, minyear2:minyear, maxyear2:maxyear });
                }
            }
        });
    }

    getNCRAndPBCRCancerSiteWise = (gender) =>{
        let sobj = {orgUnit:this.props.fobj.subOrgUnit,maxYear:this.props.fobj.maxYear,minYear:this.props.fobj.minYear,gender:gender,totalNoOfResults:10};
        submitSets(submitCollection.getNCRAndPBCRCancerSiteWise,sobj).then(res=> {
            if(res.status===true){
                let data = res.extra;
                let ncrPbcrData = {categories:[],series:[{name:"",data:[]}]};
                let ncrarray = [];let pbcrarray = [];
                for (let i = 0; i < data.length; i++) {
                    if(i<10){
                        //separate long text
                        // if(data[i].cancerSiteName.length>12){
                        //     let arr = data[i].cancerSiteName.split(/(?=[&])/g);
                        //     ncrPbcrData.categories.push(arr);
                        // }
                        // else{
                        //     ncrPbcrData.categories.push(data[i].cancerSiteName);
                        // }
    
                        ncrPbcrData.categories.push(data[i].cancerSiteName);
                        for (let x = 0; x < data[i].values.length; x++) {
                            let item = data[i].values[x];
                            if(item.name==="NCR SriLanka" || item.name==="NCR Sri Lanka"){
                                ncrarray.push(data[i].values[x].rate);
                            }
                            else if (item.name==="PBCR Colombo"){
                                pbcrarray.push(data[i].values[x].rate);
                            }
                            
                        }

                    }

                }
                ncrPbcrData.series = [];
                ncrPbcrData.series.push({name:"NCR Sri Lanka",data:ncrarray});
                ncrPbcrData.series.push({name:"PBCR Colombo",data:pbcrarray});

                if(gender==="Male"){
                    this.setState({maleNcrPbcrSiteWiseData:ncrPbcrData });
                }
                else if(gender==="Female"){
                    this.setState({femaleNcrPbcrSiteWiseData:ncrPbcrData });
                }
            }
        });
    }

    export = () =>{
        var element = document.getElementById('generating-section-pop');
        var pagebreak = {mode:"avoid-all"};
        var opt = {
            margin:       0,
            filename:     this.props.fobj.sheetName.replace(/ /g,'')+"_"+this.props.language+'.pdf',
            image:        { type: 'jpeg', quality: 0.98 },
            html2canvas:  { scale: 5, logging: true, dpi: 192, letterRendering: true },
            jsPDF:        { unit: 'mm', format: 'a4', orientation: 'portrait', compress: true, precision: 8 },
            pagebreak: pagebreak
        };

        html2pdf().set(opt).from(element).toPdf().get('pdf').then(function (pdf) {
            var totalPages = pdf.internal.getNumberOfPages(); 
            var cdate = new Date();
            //print current pdf width & height to console
            
            for (var i = 1; i <= totalPages; i++) {
              pdf.setPage(i);
              pdf.setFontSize(10);
              pdf.setTextColor("#d04571");
              //divided by 2 to go center
              //var img = new Image()
              //img.src = '../../assets/img/logo512.png'
              //pdf.addImage(img,"png", 5, 1, 10, 10);
              //pdf.text('Page ' + i + ' of ' + totalPages, 10, 290,);
              pdf.text('National Cancer Registry : All rights reserved '+cdate.getFullYear(), 100, 293,'center');
            } 
            }).save();
    }

    render(){
        return (
        <Col className="main-container-col" style={{width:"815px"}} >
            
            <Col style={{marginBottom:"5px"}}>
                <ButtonGroup aria-label="Basic example" size="sm">
                    <Button onClick={()=>this.props.changeLanguage("en")} variant={this.props.language==="en"?"primary":"secondary"}>English</Button>
                    <Button onClick={()=>this.props.changeLanguage("sin")} variant={this.props.language==="sin"?"primary":"secondary"}>Sinhala</Button>
                    <Button onClick={()=>this.props.changeLanguage("ta")} variant={this.props.language==="ta"?"primary":"secondary"}>Tamil</Button>
                </ButtonGroup>
            
                <Col style={{position:"relative", float:"right"}}>
                    <Button variant="success" size="sm" onClick={()=>this.export()}>Download</Button>{" "}
                    <Button variant="secondary" size="sm" onClick={()=>this.props.changeViewType("mainView")}>Back</Button>
                </Col>
            </Col>
            
            <Col xs={12} style={{width:"815px",border:"1px solid #ccc",padding:"10px"}}>
                <Col xs={12} className="generating-section-pop pop-based" id="generating-section-pop">
                    {/* <Col className="overlay-bg"></Col> */}
                    <Col className={"gs-title"+(this.props.language!=="en"?" sub-lang":"")}>
                        <Row style={{paddingLeft:"10px"}}>
                            <Col xs={1}>
                                <img alt="" src={foldericon} width="55px" id="mainLogo" style={{marginTop:"-4px"}} />
                            </Col>
                            <Col xs={11}>
                                <h6 className="main-heading-pop">{this.props.t("NCCP_SUB")}</h6>
                                <h5 className="sub-heading-pop">{this.props.t("NCCP")}</h5>
                                <img alt="" src={nccplogo} className="fsheet-logo"/>
                            </Col>    
                        </Row>
                    </Col>
                
                    <Col className={"chart-title-col"+(this.props.language==="sin"?" sub-lang":this.props.language==="ta"?" sub-lang2":"")}>
                        {this.props.language==="en"?this.props.fobj.sheetName:this.props.language==="sin"?this.props.fobj.sinhalaName:this.props.language==="ta"?this.props.fobj.tamilName:""} - {this.props.fobj.year}
                        <div className="year-view"><span>{this.props.t("FACT_SHEET")}</span></div>
                    </Col>

                    <Col xs={12} className={"chart-containts"+(this.props.language!=="en"?" sub-lang":"")}>
                        <Col className="chart-sub-card">
                            <Row>
                            <Col xs={12}><h6 className="sub-title">{this.props.t("POP_TITLE3")}  {this.state.minyear1} {this.props.t("TO")} {this.state.maxyear1}</h6></Col>
                                <Col xs={7} style={{paddingLeft:"5px"}}>
                                    <DynamicLineChart data={this.state.InsMortChartData} x={this.props.t("YEAR_X")} y={this.props.t("CRUDE_RATE_Y")} colors={["#6807F9","#54E346","#F30067","#72147E"]} isshowlegend={false} />
                                    <Col xs={12} className="chart-containt-labels" style={{marginTop:"-30px"}}>
                                        <Row>
                                            <Col xs={6}><div className="label-color im"></div> {this.props.t("INCIDENCE_MALE")}</Col>
                                            <Col xs={6}><div className="label-color mm"></div> {this.props.t("MORTALITY_MALE")}</Col>
                                            <Col xs={6}><div className="label-color if"></div> {this.props.t("INCIDENCE_FEMALE")}</Col>
                                            <Col xs={6}><div className="label-color mf"></div> {this.props.t("MORTALITY_FEMALE")}</Col>
                                        </Row>
                                    </Col>
                                </Col>
                                <Col xs={5} className="summary-section-pop">
                                    <Row style={{padding:"10px"}}>
                                        
                                        <Col xs={12} className="summaryitem-pop-main">
                                            <Col className="summary-item-pop asia">
                                                <h6>{this.props.t(this.props.fobj.orgUnitName)} {this.props.t("DISTRICT")}</h6>
                                                <img alt="" src={asiaicon} className="img-fluid"/>
                                                <Row style={{marginTop:"10px",marginBottom:"5px"}}>
                                                    <Col xs={7}><h5><small>{this.props.t("CASES")}</small>{numberWithCommas(parseInt(this.state.districtCounts.count))}</h5></Col>
                                                    <Col xs={5}><h5><small>CR</small>{numberWithCommas(this.state.districtCounts.cr)}</h5></Col>
                                                </Row>
                                            </Col>
                                        </Col>  

                                        <Col xs={12} className="summaryitem-pop-main" style={{marginTop:"8px"}}>
                                            <Col className="summary-item-pop sl">
                                                <h6>{this.props.t("SRI_LANKA")}</h6>
                                                <img alt="" src={slicon} className="img-fluid"/>
                                                <Row style={{marginTop:"10px",marginBottom:"5px"}}>
                                                    <Col xs={7}><h5><small>{this.props.t("CASES")}</small>{numberWithCommas(parseInt(this.state.SlCounts.count))}</h5></Col>
                                                    <Col xs={5}><h5><small>CR</small>{numberWithCommas(this.state.SlCounts.cr)}</h5></Col>
                                                </Row>
                                            </Col>
                                        </Col> 

                                    </Row>
                                    
                                </Col>
                                {/* <Col xs={10} className="chart-containt-labels">
                                    <Row>
                                        <Col xs={3}><div className="label-color im"></div> Incidence Male</Col>
                                        <Col xs={3}><div className="label-color mm"></div> Mortality Male</Col>
                                        <Col xs={3}><div className="label-color if"></div> Incidence Female</Col>
                                        <Col xs={3}><div className="label-color mf"></div> Mortality Female</Col>
                                    </Row>
                                </Col> */}
                            </Row>    
                        </Col>
                        
                        <Col className="chart-sub-card" style={{height:"290px"}}>
                            <Row>
                                <Col xs={12}><h6 className="sub-title">{this.props.t("POP_TITLE1")}  {this.state.minyear2} {this.props.t("TO")} {this.state.maxyear2}</h6></Col>
                                <Col xs={6} className="subchart-content" style={{paddingLeft:"0px",position:"relative"}}>
                                    <h6 style={{textAlign:"center"}}>{this.props.t("Male")}</h6>
                                    <Col style={{marginTop:"-10px"}}><DynamicLineChart data={this.state.maleNcrPbcrData} x={this.props.t("YEAR_X")} y={this.props.t("CRUDE_RATE_Y")} colors={["#033FFF","#54E346"]} height={230} isshowlegend={false} /></Col>
                                    <Col xs={12} className="chart-containt-labels" style={{marginTop:"-32px"}}>
                                        <Row>
                                            <Col xs={4}><div className="label-color mnc"></div> NCR {this.props.t("SRI_LANKA")}</Col>
                                            <Col xs={8}><div className="label-color pb"></div> PBCR {this.props.t(this.props.fobj.orgUnitName)} {this.props.t("DISTRICT")}</Col>
                                        </Row>
                                    </Col>
                                </Col>
                                <Col xs={6} className="subchart-content" style={{paddingLeft:"0px",position:"relative"}}>
                                    <h6 style={{textAlign:"center"}} className="fmale">{this.props.t("Female")}</h6>
                                    <Col style={{marginTop:"-10px"}}><DynamicLineChart data={this.state.femaleNcrPbcrData} x={this.props.t("YEAR_X")} y={this.props.t("CRUDE_RATE_Y")} colors={["#FF008B","#54E346"]} height={230} isshowlegend={false} /></Col>
                                    <Col xs={12} className="chart-containt-labels" style={{marginTop:"-32px"}}>
                                        <Row>
                                            <Col xs={4}><div className="label-color fnc"></div> NCR {this.props.t("SRI_LANKA")}</Col>
                                            <Col xs={8}><div className="label-color pb"></div> PBCR {this.props.t(this.props.fobj.orgUnitName)} {this.props.t("DISTRICT")}</Col>
                                        </Row>
                                    </Col>
                                </Col>
                            </Row>    
                        </Col>
                        
                        <Col className="chart-sub-card">
                            <Row>
                                <Col xs={12}><h6 className="sub-title">{this.props.t("POP_TITLE2")} {this.props.t(this.props.fobj.orgUnitName)} {this.props.t("DISTRICT")}</h6></Col>
                                <Col xs={6} className="subchart-content" style={{paddingLeft:"0px",height:"295px"}}>
                                    <h6 style={{textAlign:"center"}}>{this.props.t("Male")}</h6>
                                    <DynamicBarChart data={this.state.maleNcrPbcrSiteWiseData} x={this.props.t("CRUDE_RATE_Y")} y={this.props.t("CANCER_SITES_Y")} colors={["#033FFF","#54E346"]} height={285} isshowlegend={false} />
                                    <Col xs={12} className="chart-containt-labels" style={{marginTop:"-35px"}}>
                                        <Row>
                                            <Col xs={4}><div className="label-color mnc"></div> NCR {this.props.t("SRI_LANKA")}</Col>
                                            <Col xs={8}><div className="label-color pb"></div> PBCR {this.props.t(this.props.fobj.orgUnitName)} {this.props.t("DISTRICT")}</Col>
                                        </Row>
                                    </Col>
                                </Col>
                                <Col xs={6} className="subchart-content" style={{paddingLeft:"0px",height:"295px"}}>
                                    <h6 style={{textAlign:"center"}} className="fmale">{this.props.t("Female")}</h6>
                                    <DynamicBarChart data={this.state.femaleNcrPbcrSiteWiseData} x={this.props.t("CRUDE_RATE_Y")} y={this.props.t("CANCER_SITES_Y")}  colors={["#FF008B","#54E346"]} height={285} isshowlegend={false} />
                                    <Col xs={12} className="chart-containt-labels" style={{marginTop:"-35px"}}>
                                        <Row>
                                            <Col xs={4}><div className="label-color fnc"></div> NCR {this.props.t("SRI_LANKA")}</Col>
                                            <Col xs={8}><div className="label-color pb"></div> PBCR {this.props.t(this.props.fobj.orgUnitName)} {this.props.t("DISTRICT")}</Col>
                                        </Row>
                                    </Col>
                                </Col>
                            </Row>    
                        </Col>
                        
                    </Col>
                </Col>    
            </Col>
            
        </Col>
         );
    }
}

const mapDispatchToProps = dispatch => ({
    setResetState: (payload) => dispatch(logoutAction(payload)),
});

export default withTranslation()(withRouter(connect(null,mapDispatchToProps)(PopulationBasedFactSheet)));
