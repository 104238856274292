import React from 'react';
import Chart from "react-apexcharts";

export default function DynamicBarChartComparison(props) {
    var options = {
      chart: {
        type: 'bar',
        height: 350,
        toolbar: {
            show: false,
        },
        stacked: true,
        animations: { enabled: false },
      },
      plotOptions: {
        bar: {
          borderRadius: 0,
          horizontal:true,
          dataLabels: {
            position: 'top', // top, center, bottom
            hideOverflowingLabels: false
          },
          distributed: props.distributed===true?props.distributed:false,
          barHeight: '100%'
        }
      },
      tooltip: { enabled: true },
      dataLabels: {
        formatter: function (val) {
          return (val<0? (val*-1) : val)
        },
        offsetY:props.angleType==="ver"?-16:0.5,
        offsetX:props.angleType==="hori"?20:0,
        enabled: props.showdatalabel===true?true:false,
        style: {
          fontSize: '10px',
          colors: ["#2E4053"]
        },
      },
      xaxis: {
        categories: props.data.categories,
        max:props.highestValue,
        title:{
            text:props.x,
            offsetY:-5,
            style:{
                fontWeight: 600,
                fontSize:'13px',
                fontFamily: "'Roboto', sans-serif",
                color:"#111D5E"
            }
        },
        labels: {
          formatter: function (val) {
            return val !== 0 ?Math.abs(Math.round(val)):""
          },
          style:{
              fontWeight: 600,
              fontSize:'12px',
              fontFamily: "'Roboto', sans-serif",
              color:"#000"
          }
        }
      },
      yaxis:{
        min:(Math.abs(props.highestValue) * -1),//props.highestValue
        //max:props.highestValue,
        title:{
            text:props.y,
            offsetX:-3,
            style:{
                fontWeight: 600,
                fontSize:'13px',
                fontFamily: "'Roboto', sans-serif",
                color:"#111D5E"
            }
        },
        labels: {
          minWidth:10,
          maxWidth: 300,
          offsetX:10,
          style:{
              fontWeight: 600,
              fontSize:'10px',
              fontFamily: "'Roboto', sans-serif",
              color:"#000"
          }
        }
      },
      stroke: {
        width: 1.5,
        colors: ["#fff"]
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.9
        }, 
        xaxis: {
            lines: {
                show: true
            }
        },  
        yaxis: {
            lines: {
                show: true
            }
        }
      },
      legend: {
        show: false,
        position:"top",
        offsetY:20,
        offsetX:100,
        markers:{width:0, height:0}
      },
      colors: (props.data.colors?props.data.colors:props.colors),
    };
    var series = props.data.series;
    //console.log(props.highestValue)
    return (
        <>
            <Chart className="mchart-view" options={options} series={series} type="bar" height={props.height?props.height:260} />
        </>
    )


}
