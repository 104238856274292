import React from 'react';
import { Switch, withRouter, Route } from "react-router-dom";
import { connect } from "react-redux";
import { Row, Col } from 'react-bootstrap';
import './App.css';

import i18n from "i18next";
import { withTranslation } from "react-i18next";
import "./_translations/i18n";

import NavbarTop from './components/common_layouts/navbartop';
import SidebarMenu from './components/common_layouts/sidebarmenu';

import { submitCollection } from './_services/submit.service';
import { submitSets } from './components/UiComponents/SubmitSets';

import SignInComponent from './components/signin/signin';
import FactSheetsComponent from './components/fact-sheets/factSheets';
import ChartsComponent from './components/charts/charts';
import CancerSitesComponent from './components/cancerSites/cancerSites';
import SearchKeywordComponent from './components/searchKeywords/searchKeywords';
import MasterDataComponent from './components/masterData/masterData';
import ExcelUpload from './components/excelUpload/excelUpload';
import Roadmap from './components/roadmap/roadmap';
import BodyParts from './components/body-parts/bodyParts';
import NoMatchComponent from './components/nomatch/nomatch';
import ResetPassword from './components/resetPassword/ResetPassword';

import { loginAction } from './actions/auth/login_action';
import { languageAction } from './actions/auth/login_action';


class App extends React.Component {
  _isMounted = false;

  constructor(props){
    super(props);
    this.state = {
      dmode: (localStorage.getItem("pgdmode")?true:false), //dark mode
      cstat: null,
      signedobj: null,
    }
  }
  
  componentDidMount(){
    this._isMounted = true;

    if(this._isMounted){
      //add class d-mode if darkmode activated
      if(this.state.dmode){
        document.body.classList.add("d-mode");
      } else{
        document.body.classList.remove("d-mode");
      }
    }
    //this.getComStat(); //get online stat
  }
  componentWillUnmount(){
    this._isMounted = false;
  }
  //dark mode toggle
  dmodeToggle = () => {
    if(this.state.dmode){
      document.body.classList.remove("d-mode");
      localStorage.removeItem("pgdmode",true);
    } else{
      document.body.classList.add("d-mode");
      localStorage.setItem("pgdmode",true);
    }
    
    this.setState({
      dmode: !this.state.dmode
    });
  }
  //get b-end commiunication stat
  getComStat = () => {
    submitSets(submitCollection.checkstat, null).then(resp => {
      //console.log(resp);
      this.setState({cstat:resp});
    });
  }

  handleSignObj = (cobj) => {
    this.props.setSigninObj(cobj);
    this.props.history.push("/factsheets");
  }

  handleLangObj = (cobj) => {
    this.props.setLangObj(cobj);
  }

  redirect = (path) =>{
    this.props.history.push(path);
  }
  
  render(){
    const isRTL = i18n.dir((this.props.langState?this.props.langState.languageDetails.code:"en"));
    //console.log(this.props);
    return (
      <div className={this.props.signState.signinDetails.signinDetails===true?"App":"App loggedout"} dir={isRTL} style={{}}>
        
        <NavbarTop dmode={this.state.dmode} signedobj={this.props.signState} handleSignObj={this.handleSignObj} dmodeToggle={this.dmodeToggle}/>
        
        {
          this.props.signState.signinDetails.signinDetails===true ?
            <Row className="">
                <Col xs={2} className={this.props.signState.signinDetails.signinDetails===true?"":"d-none"} style={{position: "fixed",zIndex: "9",height: "100vh",background: "linear-gradient(145deg, #52057B, #52057B, #9D0191)",paddingLeft:"20px",paddingRight:"0px",width:"230px"}}>
                    <SidebarMenu dmode={this.state.dmode} signedobj={this.props.signState}/>
                </Col>
                <Col className="main-wrapper-container" xs={this.props.signState.signinDetails.signinDetails===true?10:12} style={{padding:"0px"}}>
                    <Switch>
                        <Route path="/resetPassword"><ResetPassword handleSignObj={this.handleSignObj} signedobj={this.props.signState} /></Route>

                        {/* <Route exact path="/"><SignInComponent langobj={this.props.langState} handleSignObj={this.handleSignObj}/></Route> */}
                        <Route path="/factsheets"><FactSheetsComponent redirect={this.redirect} handleSignObj={this.handleSignObj} signedobj={this.props.signState} lang={this.props.i18n.language}/></Route>
                        <Route path="/charts"><ChartsComponent handleSignObj={this.handleSignObj} signedobj={this.props.signState} langobj={this.props.langState}/></Route>
                        <Route path="/cancer-sites"><CancerSitesComponent handleSignObj={this.handleSignObj} signedobj={this.props.signState} langobj={this.props.langState}/></Route>
                        <Route path="/search-keywords"><SearchKeywordComponent handleSignObj={this.handleSignObj} signedobj={this.props.signState} langobj={this.props.langState}/></Route>
                        <Route path="/master-data"><MasterDataComponent handleSignObj={this.handleSignObj} signedobj={this.props.signState} langobj={this.props.langState}/></Route>
                        <Route path="/roadmap"><Roadmap handleSignObj={this.handleSignObj} signedobj={this.props.signState} langobj={this.props.langState}/></Route>
                        <Route path="/body-parts"><BodyParts handleSignObj={this.handleSignObj} signedobj={this.props.signState} langobj={this.props.langState}/></Route>
                        <Route path="/excel-upload"><ExcelUpload handleSignObj={this.handleSignObj} signedobj={this.props.signState} langobj={this.props.langState}/></Route>
                        <Route><NoMatchComponent signedobj={this.props.signState} /></Route>
                    </Switch>
                </Col>

            </Row>
          :
          <SignInComponent langobj={this.props.langState} handleSignObj={this.handleSignObj}/>

        }
        
      </div> 
    );  
  }
  
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({
  setSigninObj: (payload) => dispatch(loginAction(payload)),
  setLangObj: (payload) => dispatch(languageAction(payload))
});

export default withTranslation()(withRouter(connect(mapStateToProps,mapDispatchToProps)(App)));
