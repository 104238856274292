import React from 'react';
import { Col, Table, Modal, Button, Form, ButtonGroup } from 'react-bootstrap';

import { submitCollection } from '../../_services/submit.service';
import { submitSets } from '../UiComponents/SubmitSets';
import { alertService } from '../../_services/alert.service';
import './cancerSites.css';
import LoadingIcon from  '../../assets/img/loading.gif';
import { pageLength, getPager } from '../../_services/common.service';
//import { X } from 'react-feather';

class CancerSites extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            currentPage: 1, showPagination:true,
            isLoading:false,showEditModal:false,
            mainList:[], selectedObj:{dhisRefCode:"", cancerSiteNameEng:"", ICD10Code:"", colorCode:""},
            bodyParts:[], bodyPartId:"0",
            oriList:[], filtertxt:"",
        }
        
    }

    componentDidMount(){
        this.loadAllCancerSites();
    }

    loadAllCancerSites = () =>{
        this.setState({isLoading:true,  mainList:[],  showPagination:false})
        submitSets(submitCollection.getAllCancerSites,null).then(res=> {
            if(res.status===true){
                this.setState({mainList:res.extra, oriList:res.extra, isLoading:false});
                this.setPage(this.state.currentPage);
            }
            else{
                this.setState({mainList:[], oriList:[], isLoading:false});
            }
        });
    }

    loadAllBodyParts = () =>{
        submitSets(submitCollection.getAllBodyParts,null).then(res=> {
            if(res.status===true){
                this.setState({bodyParts:res.extra,});
            }
            else{
                this.setState({mainList:[]});
            }
        });
    }

    setModalShow = (type) =>{
        this.setState({showEditModal:type});
    }

    clickCancerSite = (obj) => {
        let sobj = JSON.parse(JSON.stringify(obj))
        this.setState({selectedObj:sobj, bodyPartId:"0"});
        this.setModalShow(true)
    }

    changeVals = (e,type) =>{
        if(type==="e-name"){
            let sobj = this.state.selectedObj;
            sobj.cancerSiteNameEng = e;
            this.setState({sobj:sobj});
        }
        else if(type==="ref"){
            let sobj = this.state.selectedObj;
            sobj.dhisRefCode = e;
            this.setState({sobj:sobj});
        }
        else if(type==="s-name"){
            let sobj = this.state.selectedObj;
            sobj.cancerSiteNameSin = e;
            this.setState({sobj:sobj});
        }
        else if(type==="t-name"){
            let sobj = this.state.selectedObj;
            sobj.cancerSiteNameTam = e;
            this.setState({sobj:sobj});
        }
        else if(type==="icd"){
            let sobj = this.state.selectedObj;
            sobj.ICD10Code = e;
            this.setState({sobj:sobj});
        }
        else if(type==="color"){
            let sobj = this.state.selectedObj;
            sobj.colorCode = e;
            this.setState({sobj:sobj});
        }
        else if(type==="bp"){
            this.setState({bodyPartId:e});
        }
    }

    addBodyPart = () =>{
        if(this.state.bodyPartId==="0" || this.state.bodyPartId===0){
            alertService.warn("Select a Body Part!");
        }
        else{
            let sobj = this.state.selectedObj;
            let count = 0;
            for (let x = 0; x < sobj.bodyParts.length; x++) {
                if(parseInt(this.state.bodyPartId)===parseInt(sobj.bodyParts[x].bodyPartId)){
                    count = (count +1);
                }
            }

            if(count===0){
                let bpname = "";
                for (let i = 0; i < this.state.bodyParts.length; i++) {
                    if(parseInt(this.state.bodyPartId)===this.state.bodyParts[i].bodyPartId){
                        bpname = this.state.bodyParts[i].bodyPartName;
                    }
                }
                sobj.bodyParts.push({bodyPartId:this.state.bodyPartId, bodyPartName:bpname});
                this.setState({sobj:sobj, bodyPartId:"0"});
            }
            else{
                alertService.warn("Already Added!");
            }

        }

    }

    deleteBodyPart = (i) =>{
        let sobj = this.state.selectedObj;
        sobj.bodyParts.splice(i,1)
        this.setState({sobj:sobj});
    }

    save = () =>{
        let sobj = this.state.selectedObj;
        if(sobj.cancerSiteNameEng==="" || sobj.cancerSiteNameSin==="" || sobj.cancerSiteNameTam==="" || sobj.colorCode==="" || sobj.ICD10Code===""){
            alertService.warn("Enter required data!");
        }
        else{
            submitSets(submitCollection.updateCancerSiteDetails,this.state.selectedObj).then(res=> {
                if(res.status===true){
                    this.loadAllCancerSites();
                    this.setModalShow(false);
                    alertService.success("Successfully Saved");
                }
                else{
                    alertService.error("Error Occurred");
                }
            });
        }
    }

    handleFilterTxt = e => {
        var tarr = [];
        var mlist = this.state.oriList;
        var filterval = e.target.value.toLowerCase();
        this.setState({ filtertxt: filterval,  showPagination:false });

        //console.log(this.state.plist);
        if (filterval !== "") {
            for (var i = 0; i < mlist.length; i++) {
                if (mlist[i].cancerSiteNameEng.toLowerCase().indexOf(filterval) > -1 || mlist[i].ICD10Code.toLowerCase().indexOf(filterval) > -1) {
                    tarr.push(mlist[i]);
                }
            }
            this.setState({ mainList: tarr });
        }
        else{
            this.setPage(1);
        }
        
    }

    setPage = (cpage) => {
        var citems = JSON.parse(JSON.stringify(this.state.oriList));
        var pager = getPager(citems.length, cpage, pageLength);
        // check page isn't out of range
        if (cpage < 1 || cpage > pager.totalPages) {
            return;
        }
        var sspage = (pager.currentPage === 1 ? 0 : ((pager.currentPage - 1) * pageLength));
        var sepage = (pager.currentPage * pageLength);
        this.setState({
            mainList: citems.slice(sspage, sepage),
            currentPage: pager.currentPage,
            totalPages: pager.totalPages,
        });

        this.setState({showPagination:(citems.length>0 ? true:false)});
    }

    render(){
        return(
            <>
                <Col xs={6} className="main-container">
                    <h5 className="main-heading">
                        Cancer Sites
                        <ul className="top-right-content list-inline">
                            <li className="list-inline-item"><input className="form-control find-txt" placeholder="Find" value={this.state.filtertxt} onChange={this.handleFilterTxt} /></li>
                        </ul>    
                    </h5>

                    <Table bordered hover size="sm" className="main-table">
                        <thead>
                            <tr>
                            {/* <th width="100px">Ref Code</th> */}
                            <th width="200px">Name</th>
                            <th width="200px">ICD10 Code</th>
                            <th width="10px"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className={this.state.isLoading===true?"":"d-none"}><td colSpan="10" align="center" style={{backgroundColor:"#FFF"}}><img alt="" src={LoadingIcon} /></td></tr>
                            {
                                this.state.mainList.map((item, i) =>{
                                    return(
                                        <tr key={i} onClick={()=>this.clickCancerSite(item)}>
                                            {/* <td>{item.dhisRefCode}</td> */}
                                            <td>{item.cancerSiteNameEng}</td>
                                            <td>{item.ICD10Code}</td>
                                            <td><div style={{width:"30px", height:"20px", background:item.colorCode, marginLeft:"10%"}}></div></td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                    <Col sm={12} className={this.state.showPagination===true?"div-pagination":"div-pagination d-none" }>
                            <ButtonGroup aria-label="Basic example">
                                <Button onClick={() => { this.setPage(1) }} variant="default" size="sm">First</Button>
                                <Button onClick={() => { this.setPage((this.state.currentPage - 1)) }} variant="default" size="sm">Prev</Button>
                                <Button variant="default" size="sm" disabled id="btncurpageno">Page {this.state.currentPage}</Button>
                                <Button onClick={() => { this.setPage((this.state.currentPage + 1)) }} variant="default" size="sm">Next</Button>
                                <Button onClick={() => { this.setPage(this.state.totalPages) }} variant="default" size="sm">Last</Button>

                            </ButtonGroup>
                    </Col>
                </Col>

                <Modal size="sm" animation={false} show={this.state.showEditModal} onHide={() => this.setModalShow(false)}>
                    <Modal.Header>
                        <Modal.Title>Edit Cancer Site</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/* <Form.Group>
                            <Form.Label>Ref Code*</Form.Label>
                            <input className="form-control" type="text" size="sm" value={this.state.selectedObj.dhisRefCode===null?"-":this.state.selectedObj.dhisRefCode} onChange={(event)=>this.changeVals(event.target.value,"ref")} />
                        </Form.Group> */}
                        <Form.Group>
                            <Form.Label>Name*</Form.Label>
                            <input className="form-control" disabled type="text" size="sm" value={this.state.selectedObj.cancerSiteNameEng} onChange={(event)=>this.changeVals(event.target.value,"e-name")} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>ICD10 Code*</Form.Label>
                            <input className="form-control" type="text" size="sm" value={this.state.selectedObj.ICD10Code} onChange={(event)=>this.changeVals(event.target.value,"icd")} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Color*</Form.Label>
                            <input className="form-control" type="color" size="sm" value={this.state.selectedObj.colorCode} onChange={(event)=>this.changeVals(event.target.value,"color")} />
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" size="sm" onClick={() => this.setModalShow(false)}>Close</Button>
                        <Button className="primary-button" size="sm" onClick={() => this.save()}>Save Changes</Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}

export default CancerSites;
