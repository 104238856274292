import React from 'react';
import { Col, Table, Button, Modal, Form, ButtonGroup } from 'react-bootstrap';
import { submitCollection } from '../../_services/submit.service';
import { submitSets } from '../UiComponents/SubmitSets';
import { alertService } from '../../_services/alert.service';
import LoadingIcon from  '../../assets/img/loading.gif';
import { X } from 'react-feather';
import { pageLength, getPager } from '../../_services/common.service';

import './searchKeywords.css';

class SearchKeywords extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            currentPage: 1, showPagination:true,
            isLoading:false,showEditModal:false,
            mainList:[], selectedObj:{id:"0",searchKey:"",searchValues:""},valuetext:"",
            oriList:[], filtertxt:"",
        }
        
    }

    componentDidMount(){
        this.loadAllKeywords()
    }

    setModalShow = (type) =>{
        this.setState({showEditModal:type});
    }

    clickKey = (obj) =>{
        let sobj = JSON.parse(JSON.stringify(obj))
        this.setState({selectedObj:sobj});
        this.setModalShow(true)
    }

    loadAllKeywords = () =>{
        this.setState({isLoading:true, mainList:[],  showPagination:false})
        submitSets(submitCollection.getSearchKeywords,null).then(res=> {
            if(res.status===true){
                this.setState({mainList:res.extra, oriList:res.extra, isLoading:false});
                this.setPage(this.state.currentPage);
            }
            else{
                this.setState({mainList:[], oriList:[], isLoading:false});
            }
        });
    }

    changeVals = (e,type) =>{
        if(type==="val"){
            this.setState({valuetext:e});
        }
    }

    addNewValue = () =>{
        if(this.state.valuetext===""){
            alertService.warn("Enter a Value!");
        }
        else{
            let sobj = this.state.selectedObj;
            sobj.searchValues = sobj.searchValues+","+this.state.valuetext;
            //console.log(sobj.values.split(','));
            this.setState({selectedObj:sobj, valuetext:""});
        }
    }

    deleteValueItem = (i) =>{
        let sobj = this.state.selectedObj;
        let valarray = sobj.searchValues.split(',');
        valarray.splice(i,1);

        // let newvaltxt = "";
        // for (let i = 0; i < valarray.length; i++) {
        //     newvaltxt = newvaltxt + (i!==0?",":"") + valarray[i]
        //     //console.log(i + " - "+valarray.length)
        // }
        //console.log(valarray.join())
        sobj.searchValues = valarray.join();
        this.setState({selectedObj:sobj, valuetext:""});

    }

    save = () =>{
        //let sobj = this.state.selectedObj;
    
        submitSets(submitCollection.UpdateSearchKeyword,this.state.selectedObj).then(res=> {
            if(res.status===true){
                this.loadAllKeywords();
                this.setModalShow(false);
                alertService.success("Successfully Saved");
            }
            else{
                alertService.error("Error Occurred");
            }
        });
        
    }

    handleFilterTxt = e => {
        var tarr = [];
        var mlist = this.state.oriList;
        var filterval = e.target.value.toLowerCase();
        this.setState({ filtertxt: filterval,  showPagination:false });

        //console.log(filterval);
        if (filterval !== "") {
            for (var i = 0; i < mlist.length; i++) {
                if (mlist[i].searchKey.toLowerCase().indexOf(filterval) > -1) {
                    tarr.push(mlist[i]);
                }
            }
            this.setState({ mainList: tarr });
        }
        else{
            this.setPage(1);
        }
        
    }

    setPage = (cpage) => {
        var citems = JSON.parse(JSON.stringify(this.state.oriList));
        var pager = getPager(citems.length, cpage, pageLength);
        // check page isn't out of range
        if (cpage < 1 || cpage > pager.totalPages) {
            return;
        }
        var sspage = (pager.currentPage === 1 ? 0 : ((pager.currentPage - 1) * pageLength));
        var sepage = (pager.currentPage * pageLength);
        this.setState({
            mainList: citems.slice(sspage, sepage),
            currentPage: pager.currentPage,
            totalPages: pager.totalPages,
        });

        this.setState({showPagination:(citems.length>0 ? true:false)});
    }

    render(){
        return(
            <>
                <Col xs={5} className="main-container">
                    <h5 className="main-heading">
                        Search Keywords
                        <ul className="top-right-content list-inline">
                            <li className="list-inline-item"><input className="form-control find-txt" placeholder="Find" value={this.state.filtertxt} onChange={this.handleFilterTxt} /></li>
                        </ul>    
                    </h5>
                    <Table bordered striped hover size="sm" className="main-table">
                        <thead>
                            <tr>
                            <th width="100px">Key</th>
                            <th width="50px">No. of Values</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className={this.state.isLoading===true?"":"d-none"}><td colSpan="10" align="center" style={{backgroundColor:"#FFF"}}><img alt="" src={LoadingIcon} /></td></tr>
                            {
                                this.state.mainList.map((item, i) =>{
                                    return(
                                        <tr key={i}>
                                            <td onClick={()=>this.clickKey(item)}>{item.searchKey}</td>
                                            <td onClick={()=>this.clickKey(item)} align="center">
                                                {item.searchValues.length}
                                            {/* {
                                                item.searchValues.split(',').map((vitem, x) =>{
                                                    
                                                        return(
                                                            <label key={x} className={vitem==="" || vitem===" "?"val-item d-none":"val-item"}>
                                                                {vitem}
                                                            </label>
                                                        )
                                                   
                                                })
                                            } */}
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                    <Col sm={12} className={this.state.showPagination===true?"div-pagination":"div-pagination d-none" }>
                            <ButtonGroup aria-label="Basic example">
                                <Button onClick={() => { this.setPage(1) }} variant="default" size="sm">First</Button>
                                <Button onClick={() => { this.setPage((this.state.currentPage - 1)) }} variant="default" size="sm">Prev</Button>
                                <Button variant="default" size="sm" disabled id="btncurpageno">Page {this.state.currentPage}</Button>
                                <Button onClick={() => { this.setPage((this.state.currentPage + 1)) }} variant="default" size="sm">Next</Button>
                                <Button onClick={() => { this.setPage(this.state.totalPages) }} variant="default" size="sm">Last</Button>

                            </ButtonGroup>
                    </Col>
                </Col>

                <Modal size="lg" animation={false} show={this.state.showEditModal} onHide={() => this.setModalShow(false)}>
                    <Modal.Header>
                        <Modal.Title>Edit Search Key</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>Key</Form.Label>
                            <input className="form-control" type="text" size="sm" value={this.state.selectedObj.searchKey} disabled/>
                        </Form.Group>
                        <Form.Group>
                            <ul className="list-inline">
                                <li className="list-inline-item" style={{width:"90%"}}>
                                    <Form.Label>Add a Value</Form.Label>
                                    <input className="form-control" type="text" size="sm" value={this.state.valuetext} onChange={(event)=>this.changeVals(event.target.value,"val")} />
                                </li>
                                <li className="list-inline-item" ><Button size="sm" variant="primary" onClick={()=>this.addNewValue()}>Add</Button></li>
                            </ul>
                        </Form.Group>

                        <Col>
                            {
                                this.state.selectedObj.searchValues.split(',').map((item, i) =>{
                                    
                                        return(
                                            <label key={i} className={item==="" || item===" "?"key-item d-none":"key-item"}>
                                                <span onClick={()=>this.deleteValueItem(i)}><X size={15}/></span>
                                                {item}
                                            </label>
                                        )
                                    
                                })
                            }
                        </Col>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" size="sm" onClick={() => this.setModalShow(false)}>Close</Button>
                        <Button className="primary-button" size="sm" onClick={() => this.save()}>Save Changes</Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}

export default SearchKeywords;
