import React from 'react';
import { Col, Button, Table, Modal, ButtonGroup, Form } from 'react-bootstrap';
import i18n from "i18next";

import { submitCollection } from '../../_services/submit.service';
import { submitSets } from '../UiComponents/SubmitSets';
//import { alertService } from '../../_services/alert.service';
import { Upload, FilePlus, Eye, RefreshCw } from 'react-feather'; //Eye
import { pageLength, getPager, convertDateTime } from '../../_services/common.service';
import { alertService } from '../../_services/alert.service';
//import { Document, Page } from 'react-pdf';

import SinglePageFactSheet from '../singlePageFactSheet/singlePageFactSheet';
import SinglePageFactSheetBothSex from '../singlePageFactSheetBothSex/singlePageFactSheetBothSex';
import PopulationBasedFactSheet from '../populationBasedFactSheet/populationBased';
import TwoPageFactSheet from '../twoPageFactSheet/twoPageFactSheet';
import TwoPageFactSheetBothSex from '../twoPageFactSheetBothSex/twoPageFactSheetBothSex';
import PaediatricFactSheet from '../PaediatricFactSheet/PaediatricFactSheet';

import './factSheets.css';
import LoadingIcon from  '../../assets/img/loading.gif';

class FactSheets extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            currentPage: 1, showPagination:true,
            viewType:"mainView", //mainview, singlePage, singlePageBothSex, DoublePages, populationBasedPage
            isLoading:false,showUploadModal:false,
            mainList:[], selectedObj:{sheetName:""},
            oriList:[], filtertxt:"",
            latestYear:"2018",
            UploadObj:{sheetId:0, language:"English", sheetFileId:0, files:null},
            showPdfViewerModal:false, viewerPath:"", viewerLangList:[], viewerSelLang:"",
            isUploadLoading:false
        }
        
    }

    componentDidMount(){
        this.loadAllFactSheets();
        this.getMasterData();
    }

    getMasterData = () =>{
        submitSets(submitCollection.getMAsterData,null).then(res=> {
            if(res.status===true && res.extra){
                this.setState({latestYear:res.extra.currentYear});
            }
        });
    }

    loadAllFactSheets = () =>{
        this.setState({isLoading:true,  mainList:[], showPagination:false})
        submitSets(submitCollection.getAllFactSheets,null).then(res=> {
            if(res.status===true){
                this.setState({mainList:res.extra, oriList:res.extra, isLoading:false});
                this.setPage(this.state.currentPage);
            }
            else{
                this.setState({mainList:[], oriList:[], isLoading:false});
            }
        });
    }

    changeLanguage = (lang) =>{
        i18n.changeLanguage(lang);
    }

    generate=(type)=>{
        this.props.redirect(type);
    }

    setModalShow = (type) =>{
        this.setState({showUploadModal:type});
    }

    setPdfViewerModalShow = (type) =>{
        this.setState({showPdfViewerModal:type});
    }

    changeViewType = (type) =>{
        this.setState({viewType:type});
    }

    handleClickFactsheetGenerate = (item)=>{
        item.maxYear = this.state.latestYear;
        item.year = this.state.latestYear;

        this.setState({selectedObj:item},()=>{
            this.changeViewType(item.pageType);
        });
    }

    handleFilterTxt = e => {
        var tarr = [];
        var mlist = this.state.oriList;
        var filterval = e.target.value.toLowerCase();
        this.setState({ filtertxt: filterval, showPagination:false });

        //console.log(filterval);
        if (filterval !== "") {
            for (var i = 0; i < mlist.length; i++) {
                if (mlist[i].sheetName.toLowerCase().indexOf(filterval) > -1) {
                    tarr.push(mlist[i]);
                }
            }
            this.setState({ mainList: tarr });
        }
        else{
            this.setPage(1);
        }
        
    }

    setPage = (cpage) => {
        var citems = JSON.parse(JSON.stringify(this.state.oriList));
        var pager = getPager(citems.length, cpage, pageLength);
        // check page isn't out of range
        if (cpage < 1 || cpage > pager.totalPages) {
            return;
        }
        var sspage = (pager.currentPage === 1 ? 0 : ((pager.currentPage - 1) * pageLength));
        var sepage = (pager.currentPage * pageLength);
        this.setState({
            mainList: citems.slice(sspage, sepage),
            currentPage: pager.currentPage,
            totalPages: pager.totalPages,
        });

        this.setState({showPagination:(citems.length>0 ? true:false)});
    }

    handleOpenUploadModal = (item) =>{
        //console.log(item);
        this.setState({selectedObj:item, showUploadModal:true, UploadObj:{sheetId:0, language:"English", sheetFileId:0, files:null}});
    }

    uploadDataChange = (e,type) =>{
        let sobj = this.state.UploadObj;
       
        if(type==="lang"){
            sobj.language = e;
        }
        else if(type==="file"){
            sobj.files = e;
        }

        this.setState({UploadObj:sobj});
    }

    UploadFile = () =>{
        let sobj = this.state.UploadObj;
        let mSelObj = this.state.selectedObj;

        if(sobj.files===null){
            alertService.warn("Select a File");
        }
        else{
            let selSheetId = 0;
            for (let i = 0; i < mSelObj.files.length; i++) {
                if(mSelObj.files[i].language===sobj.language){
                    selSheetId = mSelObj.files[i].sheetFileId;
                }
                
            }

            let formData = new FormData();
            formData.append("sheetId", mSelObj.sheetId);
            formData.append("sheetFileId", selSheetId);
            formData.append("language", sobj.language);
            formData.append("files", sobj.files);

            // console.log(mSelObj.sheetId);
            // console.log(selSheetId);
            // console.log(sobj.language);
            // console.log(sobj.files);
            this.setState({isUploadLoading:true});
            submitSets(submitCollection.uploadFactSheetFile,formData).then(res=> {
                if(res.status===true){
                    this.setModalShow(false)
                    this.loadAllFactSheets();
                    this.setState({UploadObj:{sheetId:0, language:"English", sheetFileId:0, files:null}},()=>{
                        this.setModalShow(true)
                    });
                    alertService.success("Successfully Uploaded");
                }
                else{
                    alertService.error("Error Occurred");
                }
                this.setState({isUploadLoading:false});
            });

        }
    }
    //pdf viewer
    handleOpenPdfViewerModal = (item) =>{
        if(item.files.length===0){
            alertService.warn("No files were uploaded!");
        }
        else{
            let selLang = item.files[0].language;
            let selPath = item.files[0].currentFilePath;
            this.setState({viewerLangList:item.files, selectedObj:item, viewerPath:selPath, viewerSelLang:selLang});

            this.setPdfViewerModalShow(true);
        }
    }

    changeViewerLanguage = (item) =>{
        this.setState({viewerPath:item.currentFilePath, viewerSelLang:item.language});
    }

    render(){
        return(
            <>
                <Col className="main-container">
                    {/* Fact Sheets <Button size="sm" onClick={()=>this.generate("/generate-singlepage")}>Generate Single Page</Button>
                    {' '}<Button size="sm" onClick={()=>this.generate("generate-populationbased")}>Generate Population Based</Button> */}

                    <h5 className="main-heading">
                        Fact Sheets
                        <ul className={this.state.viewType==="mainView"?"top-right-content list-inline":"top-right-content list-inline d-none"}>
                            <li className="list-inline-item"><input className="form-control find-txt" placeholder="Find" value={this.state.filtertxt} onChange={this.handleFilterTxt} /></li>
                        </ul>    
                    </h5>
                    {
                        this.state.viewType==="mainView" ?
                            <Col>
                                <Table bordered hover size="sm" className="main-table">
                                    <thead>
                                        <tr>
                                        <th width="200px">Sheet Name</th>
                                        {/* <th width="200px">File Path</th> */}
                                        {/* <th width="150px">Updated Date</th> */}
                                        <th width="20px"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    <tr className={this.state.isLoading===true?"":"d-none"}><td colSpan="10" align="center" style={{backgroundColor:"#FFF"}}><img alt="" src={LoadingIcon} /></td></tr>
                                        {
                                            this.state.mainList.map((item, i) =>{
                                                return(
                                                    <tr key={i}>
                                                        <td>{item.sheetName}</td>
                                                        {/* <td>{item.currentFilePath}</td>
                                                        <td>{convertDateTime(item.lastUpdatedDate)}</td> */}
                                                        <td align="center" style={{ textAlign:"center"}}>
                                                            <Button onClick={()=>this.handleOpenPdfViewerModal(item)} variant="secondary" size="sm"><Eye size={15} /></Button>{" "}
                                                            <Button onClick={()=>this.handleOpenUploadModal(item)} variant="secondary" size="sm"><Upload size={15} /></Button>{" "}
                                                            <Button onClick={()=>this.handleClickFactsheetGenerate(item)} variant="secondary" size="sm" title="Generate New Sheet"><FilePlus size={15} /></Button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                                <Col sm={12} className={this.state.showPagination===true?"div-pagination":"div-pagination d-none" }>
                                    <ButtonGroup aria-label="Basic example">
                                        <Button onClick={() => { this.setPage(1) }} variant="default" size="sm">First</Button>
                                        <Button onClick={() => { this.setPage((this.state.currentPage - 1)) }} variant="default" size="sm">Prev</Button>
                                        <Button variant="default" size="sm" disabled id="btncurpageno">Page {this.state.currentPage}</Button>
                                        <Button onClick={() => { this.setPage((this.state.currentPage + 1)) }} variant="default" size="sm">Next</Button>
                                        <Button onClick={() => { this.setPage(this.state.totalPages) }} variant="default" size="sm">Last</Button>

                                    </ButtonGroup>
                                </Col>
                            </Col>
                        : this.state.viewType==="singlePage"?
                            <SinglePageFactSheet fobj={this.state.selectedObj} changeViewType={this.changeViewType} changeLanguage={this.changeLanguage} language={this.props.lang} />
                        : this.state.viewType==="singlePageBothSex"? 
                            <SinglePageFactSheetBothSex fobj={this.state.selectedObj} changeViewType={this.changeViewType} changeLanguage={this.changeLanguage} language={this.props.lang} />
                        :    this.state.viewType==="populationBasedPage"? 
                                <PopulationBasedFactSheet fobj={this.state.selectedObj} changeViewType={this.changeViewType} changeLanguage={this.changeLanguage} language={this.props.lang} /> 
                        : this.state.viewType==="doublePages"? 
                                    <TwoPageFactSheet fobj={this.state.selectedObj} changeViewType={this.changeViewType} changeLanguage={this.changeLanguage} language={this.props.lang} /> 
                        : this.state.viewType==="doublePagesBothSex"? 
                                        <TwoPageFactSheetBothSex fobj={this.state.selectedObj} changeViewType={this.changeViewType} changeLanguage={this.changeLanguage} language={this.props.lang} /> 
                        :this.state.viewType==="doublepagePaediatric"? 
                                            <PaediatricFactSheet fobj={this.state.selectedObj} changeViewType={this.changeViewType} changeLanguage={this.changeLanguage} language={this.props.lang} />                
                        :
                        <></>       

                    }
                </Col>

                <Modal backdrop="static" animation={false} show={this.state.showUploadModal} onHide={() => this.setModalShow(false)}>
                    <Modal.Header>
                        <Modal.Title>Upload Factsheet</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>Fact Sheet Name</Form.Label>
                            <input className="form-control" disabled value={this.state.selectedObj.sheetName} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Language</Form.Label>
                            <select disabled={this.state.isUploadLoading===true ? "disabled" : ""} style={{width:"120px"}} className="form-control" value={this.state.UploadObj.language} onChange={(event)=>this.uploadDataChange(event.target.value,"lang")}>
                                <option value="English">English</option>
                                <option value="Sinhala">Sinhala</option>
                                <option value="Tamil">Tamil</option>
                            </select>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Select a File</Form.Label>
                            <input
                            disabled={this.state.isUploadLoading===true ? "disabled" : ""}
                            className="form-control"
                            type="file"
                            accept="application/pdf"
                            onChange={(e) => this.uploadDataChange(e.target.files[0],"file")}
                            />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        {
                            this.state.isUploadLoading===false?
                            <>
                                <Button size="sm" variant="secondary" onClick={() => this.setModalShow(false)}>Close</Button>
                                <Button size="sm" className="primary-button" onClick={() => this.UploadFile()}>Upload</Button>
                            </>
                            :
                            <div className={"uploading-stat"}>
                                <RefreshCw className="spinner" size="15" /> Uploading
                            </div>
                        }
                    </Modal.Footer>
                </Modal>


                <Modal backdrop="static" animation={false} size="xl" id="pdfviewerModal" show={this.state.showPdfViewerModal} onHide={() => this.setPdfViewerModalShow(false)}>
                    <Modal.Header>
                        <Modal.Title>{this.state.selectedObj.sheetName}</Modal.Title>
                        <ButtonGroup aria-label="Basic example" style={{marginBottom:"0px"}} className="float-right">
                            {
                                this.state.viewerLangList.map((item, i) =>{
                                    return (
                                        <Button size="sm" onClick={() => this.changeViewerLanguage(item)} variant={this.state.viewerSelLang===item.language?"primary":"secondary"} key={i} value={item}>{item.language}</Button>
                                    )
                                })
                            }
                        </ButtonGroup>
                    
                    </Modal.Header>
                    <Modal.Body>
                    
                        <iframe id="viewerpdf" src={this.state.viewerPath} style={{display:"block",width:"100%",background:"#efefef", minHeight:"100%"}} title="title"></iframe>
                    </Modal.Body>
                    <Modal.Footer style={{padding:"2px"}}>
                        <Button size="sm" variant="secondary" onClick={() => this.setPdfViewerModalShow(false)}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}

export default FactSheets;
