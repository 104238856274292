import React from 'react';
import Chart from "react-apexcharts";

//import { numberWithCommas } from '../../_services/common.service';

export default function DynamicPieChart(props) {
    var options = {
        chart: {
            animations: { enabled: false },
            type:"pie",
            background: '#fff',
            toolbar: {
                show: false,
            },
            events: {
                mounted: (chartContext, config) => {
                    //console.log(chartContext);
                    setTimeout(() => {
                        if(props.expandindex!==undefined){
                            chartContext.toggleDataPointSelection(props.expandindex);
                        }
                    }, 500);
                }
            }            
        },
        states: {
            hover: {
                filter: {
                    type: 'none',
                }
            },
        },
        tooltip: { enabled: false },
        labels:props.data.categories,
        // responsive: [{
        //     breakpoint: 480,
        //     options: {
        //         chart: {
        //         width: 200
        //         },
        //         legend: {
        //             position: 'right'
        //         }
        //     }
        // }],
        fill: {
           
        },
        stroke: {
            width: 1,
        },
        legend: {
            show: (props.isshowlegend!==undefined?props.isshowlegend:true),
            position: 'right',
            horizontalAlign: 'top',
            fontSize: '12px',
            fontFamily: "'Roboto', sans-serif",
            fontWeight: 600,
            offsetX: -30,
            offsetY: -15,
            markers: {
                width: 11,
                height: 11,
                strokeWidth: 0,
                strokeColor: '#fff',
                fillColors: undefined,
                radius: 12
            },
            itemMargin: {
                horizontal: 5,
                vertical: -3.2
            },
            /* formatter: (label, opts) => {
                return label + ": n=" + opts.w.globals.series[opts.seriesIndex]
            }, */
            onItemClick: {
                toggleDataSeries: false
            },
            onItemHover: {
                highlightDataSeries: false
            },
          },
          dataLabels: {
            /* formatter: (val, opts) => {
                //console.log(opts.w.globals);
                //return opts.w.globals.labels[opts.seriesIndex] + ": " + opts.w.globals.series[opts.seriesIndex]
                return val;
            }, */
            style: {
                fontSize: (props.height?'10px':'11px'),
                fontFamily: "'Roboto', sans-serif",
                colors: undefined,
                fontWeight: 'bold',
            },
            background: {
                enabled: true,
                foreColor: "#283747",
                padding: 3,
                borderRadius: 2,
                borderWidth: 1,
                borderColor: '#fff',
                opacity: 0.4,
                dropShadow: {
                  enabled: false,
                  top: 1,
                  left: 1,
                  blur: 1,
                  color: '#fff',
                  opacity: 0.45
                }
            },
            dropShadow: {
                enabled: false,
                top: 1,
                left: 1,
                blur: 1,
                color: '#000',
                opacity: 0.45
            }
          },
          plotOptions: {
            pie: {
                startAngle: (props.startangle?props.startangle:10),
                offsetX: (props.isxchange || props.height?2:8),
                offsetY: (props.height?38:30),
                customScale: 1,
                donut: {
                    size: '35%',
                },
                dataLabels: {
                    offset: (props.height?46:52),
                    minAngleToShowLabel: 0,
                }
            }
          },
         //colors: ['#2a52be','#de6fa1','#da4040','#486090', '#00e699', '#a035ea','#35ead4', '#FF6600', '#ebc55e', '#409caf'],
         colors: (props.data.colors?props.data.colors:['#2a52be','#de6fa1','#da4040','#486090', '#00e699', '#a035ea','#35ead4', '#FF6600', '#ebc55e', '#409caf']),
    };
    var series = props.data.series;
    //console.log(props.data);
    return (
        <>
            <Chart className="mchart-view" options={options} series={series} type="donut" height={props.height?props.height:"auto"} />
        </>
    )


}
