import React from 'react';
import Chart from "react-apexcharts";

export default function DynamicLineChart(props) {
    
    var options = {
        chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false
        },
        toolbar: {
            show: false,
        },
        animations: { enabled: false },
        fontFamily: "'Roboto', sans-serif",
      },
      tooltip: { enabled: false },
      yaxis:{
        title:{
            text:props.y,
            offsetX:3,
            style:{
                fontWeight: 600,
                fontSize:'13px',
                fontFamily: "'Roboto', sans-serif",
                color:"#111D5E"
            }
        },
        labels: {
          formatter: function(val) {
            if(val!==undefined && val!==null && !isNaN(val)){
                return val.toFixed(0);
            }
            else{
                return val;
            }
          },
          style:{
              fontWeight: 600,
              fontSize:'12px',
              fontFamily: "'Roboto', sans-serif",
              color:"#111D5E",
          }
        }
    },
      dataLabels: {
        enabled: props.isdatalable?props.isdatalable:false,
        offsetY: -4,
        textAnchor: 'end',
        offsetX: 5,
        style: {
          fontSize: '10px',
          fontFamily: "'Roboto', sans-serif",
          fontWeight: 'bold',
          colors: ["#1A5276"]
        },
        background: {
          enabled: false,
        },
      },
      markers:{size:props.ismarkers?3:0},
      stroke: {
        curve: 'straight',
        width: (props.linestroke?props.linestroke:4)
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        }, 
        xaxis: {
            lines: {
                show: true
            }
        },  
        yaxis: {
            lines: {
                show: true
            }
        },
        
      },
      xaxis: {
        categories: props.data.categories,
        title:{
          text:props.x,
          offsetY:-20,
          style:{
            fontWeight: 600,
            fontSize:'13px',
            fontFamily: "'Roboto', sans-serif",
            color:"#111D5E"
          }
        },
        labels: {
          rotate: -35,
          rotateAlways: true,
          style:{
              fontWeight: 600,
              fontSize: props.xfontsize!==undefined?props.xfontsize:'12px',
              fontFamily: "'Roboto', sans-serif",
              color:"#111D5E"
          }
        }
      },
      legend: {
        show: (props.isshowlegend!==undefined?props.isshowlegend:true),
        position: 'bottom',
        horizontalAlign: 'left',
        floating: true,
        offsetY:props.legendOffsetY ? props.legendOffsetY : 8,
        fontSize: '13px',
        fontFamily: "'Roboto', sans-serif",
        fontWeight: 600,
      },
      //colors: ['#2a52be','#de6fa1','#da4040','#486090', '#00e699', '#a035ea','#35ead4', '#FF6600', '#ebc55e', '#409caf'],
      colors: props.colors,
    };
    var series = props.data.series;
      //console.log(props)
    return (
        <>
            <Chart className="mchart-view" options={options} series={series} type="line" height={props.height?props.height:250} />
        </>
    )


}
